import React, { useState } from 'react'
import './study-details.css'
import banner from './img/study-page-banner.png'
import studyProcess from './img/study-page-process.png'
import addPeopleIcon from './img/add-people-icon.png'
import checkIcon from './img/check-icon.png'
import noteIcon from './img/note-icon.png'
import benefitsPict from './img/benefits-user.png'
import examplesGirl from './img/examples-girl.png'
import examplesLightbulb from './img/examples-lightbulb.png'
import examplesBooks from './img/examples-stack-of-books.png'
import examplesTrophy from './img/examples-trophy.png'
import faqJumping from './img/faq-jumping.png'
import { ExampleCard } from './examples-card'
import { Accordion } from 'components/accordion'
import { accordionStudyText } from './accordion-text'
import { ApplyStudyForm } from './apply-form'
export const StudyDetails = () => {
  const [activeItem, setActiveItem] = useState(1)
  return (
    <div className="study-page">
      <div className="banner">
        <div className="container">
          <div className="banner-con">
            <div className="banner-text">
              <h1 className="">Skill up now. Pay later</h1>
              <p className="fs-2 pe-5 fw-bold">
                Use the Income-based Financing (IBF) offered to Launch Academy students by
                OverSubscribe to get equipped for a new career with Launch Academy and make
                affordable payments later, only when you’re working.
              </p>
            </div>
            <div className="col-12 col-lg-6 mt-5 mb-4 text-center img-con">
              <img className="img-fluid" src={banner} />
            </div>
          </div>
          <hr className="hr" />
          <div className="container">
            <ApplyStudyForm className="apply-form-top" />
          </div>
        </div>
      </div>
      <div className="container">
        <div className="three-steps-con">
          <div className="left-side-steps">
            <img className="img-fluid" src={studyProcess} />
          </div>
          <div className="right-side-steps">
            <h2>A simple 3-step process</h2>
            <div className="row pt-4 pb-4 fs-2">
              <div className="col-12 col-lg-2">
                <div className="btn btn-success rounded-circle">
                  <img className="ms--5" src={addPeopleIcon} />
                </div>
              </div>
              <div className="col-12 col-lg-10">
                <div className="row fw-bold">Enter your email to start your application</div>
                <div className="row">
                  If you’ve already been accepted to Launch Academy’s Boston #37 cohort program,
                  enter your email address above to start the IBF application form.
                </div>
              </div>
            </div>
            <div className="row pb-4 fs-2">
              <div className="col-12 col-lg-2">
                <div className="btn btn-success rounded-circle">
                  <img className="ms--5" src={checkIcon} />
                </div>
              </div>
              <div className="col-12 col-lg-10">
                <div className="row fw-bold">Discover your eligibility</div>
                <div className="row">
                  You’ll find out if you’ve qualified for IBF for your tuition in 2 weeks or less.
                </div>
              </div>
            </div>
            <div className="row fs-2">
              <div className="col-12 col-lg-2">
                <div className="btn btn-success rounded-circle">
                  <img className="ms--5" src={noteIcon} />
                </div>
              </div>
              <div className="col-12 col-lg-10">
                <div className="row fw-bold">Complete financing forms if approved!</div>
                <div className="row">
                  If approved, return to this site using the link provided in the approval emails we
                  send you to complete the IBF financing forms.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr className="hr" />
      <div className="benefits-con py-5">
        <div className="container">
          <div className="row">
            <a id="benefits" name="benefits"></a>
            <div className="col-12 col-lg-6">
              <div className="row pb-5">
                <div className="fs-1 fw-bold">Benefits of income-based financing (IBF)</div>
              </div>
              <div className="row fs-2 pb-5">
                <div className="col-12 col-lg-6">
                  <div className="fw-bold">Get trained now</div>
                  Have your program at Launch Academy fully paid up-front by us. You pay later on.
                </div>
                <div className="col-12 col-lg-6">
                  <div className="fw-bold">Your Future &gt; Your Past</div>
                  We focus on your future potential. No co-signer needed and all credit histories
                  are welcome
                </div>
              </div>
              <div className="row fs-2 pb-5">
                <div className="col-12 col-lg-6">
                  <div className="fw-bold">Pay only when employed</div>
                  Only make payments when you find a job and start earning $45,000 per year
                </div>
                <div className="col-12 col-lg-6">
                  <div className="fw-bold">No lingering debt</div>
                  All your obligations end after 48 payments or after 8 years, no matter how much
                  you pay back.
                </div>
              </div>
              <div className="row fs-2">
                <div className="col-12 col-lg-6">
                  <div className="fw-bold">Payments are capped</div>
                  Never pay more than your payment cap
                </div>
              </div>
            </div>
            <div className="col-6 px-5 pt-4">
              <img className="img-fluid" src={benefitsPict} />
            </div>
          </div>
        </div>
      </div>
      <hr className="hr" />
      <div className="examples-con py-5">
        <div className="container">
          <div className="row text-center">
            <div className="fs-1 fw-bold pb-4">
              Here are some examples of how income-based
              <br />
              financing might work for Launch Academy
            </div>
          </div>
          <div className="row background-light">
            <div className="col-12 col-lg-6 fs-2 header-con">
              <div className="fs-1 fw-slight-bold">Your income-based financing terms</div>
              <ul>
                <li>48 total payments</li>
                <li>All obligations end after 8 years</li>
                <li>Pay 9.5% of earned income</li>
                <li>Only pay when annualized income is greater than $45,000</li>
                <li>Maximum payment cap of 1.5x of financed amount</li>
              </ul>
            </div>
            <div className="col-12 col-lg-6 px-5">
              <div className="row px-5">
                <img className="img-fluid" src={examplesGirl} />
              </div>
            </div>
          </div>
          <div className="row background-light pt-4">
            <div className="col-12">
              <div className="row cards-con">
                <ExampleCard
                  iconSrc={examplesLightbulb}
                  title="Scenario 1"
                  text="
After you graduate, you find a job making $60,000 per year after a couple months of recruiting. You pay nothing for the two months while you’re not earning any income. Then after you start working, your monthly payment will be the product of $5,000 (your monthly gross salary) * 9.5% (your income share percentage), or $475. Assuming no changes to your income, your obligations will end after you make 48 regular payments."
                />
                <ExampleCard
                  iconSrc={examplesBooks}
                  title="Scenario 2"
                  text="
If, instead, your job pays $84,000 per year, your monthly payment will be $665. At that rate, you will only have to make 37 payments before hitting the payment cap, after which you owe nothing.
"
                />
                <ExampleCard
                  iconSrc={examplesTrophy}
                  title="Scenario 3"
                  text="
If you find a job that pays $72,000 in the first year and then get yearly raises of 5% ($75,600 in the 2nd year, $79,380 in the 3rd, etc). Your monthly payment will be $570 in the first year, and will likewise increase by 5% each subsequent year along with your salary ($598.50 in the 2nd year, $628.43 in the 3rd, etc). In this scenario you will hit your payment cap after 40 payments.
"
                />
              </div>
            </div>
          </div>
          <div className="row background-light px-5 py-4">
            <div className="fs-6">
              *Scenarios assume simplified income assumptions without interruptions. Your actual
              payments will vary according to your specific situation and monthly earnings.
            </div>
          </div>
        </div>
      </div>
      <hr className="hr" />
      <div className="why-use py-5">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-6">
              <div className="row">
                <div className="fs-1 fw-bold">Why Use OverSubscribe</div>
                <p className="fs-2">
                  Our income-based financing empowers you to get the training you're looking for to
                  skill up now and then pay later after you graduate and start working. Contact us
                  if you have any questions!
                </p>
              </div>
              <div className="row">
                <div className="col-12 pb-5 pt-3">
                  <a
                    className="btn btn-primary rounded-pill px-5"
                    href="mailto:info@oversubscribe.co"
                  >
                    Contact Us
                  </a>
                </div>
              </div>
              <div className="row px-5">
                <img className="img-fluid px-5" src={faqJumping} />
              </div>
            </div>
            <div className="col-12 col-lg-6 px-3">
              {accordionStudyText.map(elem => (
                <Accordion
                  key={elem.title}
                  isOpen={elem.id === activeItem}
                  title={elem.title}
                  className="studyAccordion"
                  onClick={id => setActiveItem(id)}
                  id={elem.id}
                >
                  {elem.text}
                </Accordion>
              ))}
            </div>
          </div>
        </div>
      </div>
      <hr className="hr" />
      <div className="container">
        <ApplyStudyForm className="apply-form-bottom" />
      </div>
    </div>
  )
}
