import React from 'react'
import T from 'prop-types'

export const Money = ({ currency, amount, locales, ...props }) => {
  let precision = props.precision
  const formatter = new Intl.NumberFormat(locales, {
    currency,
    style: 'currency',
    minimumFractionDigits: precision,
  })

  return <span {...props}>{formatter.format(parseFloat((+amount || 0).toFixed(precision)))}</span>
}

Money.propTypes = {
  amount: T.oneOfType([T.number, T.string]),
  currency: T.string.isRequired,
  locales: T.string.isRequired,
  precision: T.number,
}

Money.defaultProps = {
  currency: 'USD',
  amount: 0,
  locales: 'en-US', // window.navigator.languages ???
  precision: 2,
}
