import { all, call, select } from 'redux-saga/effects'
import { attachSideEffect } from '../utils'
import { INVESTOR, investor } from '.'
import { callWithAuth } from 'store/auth/saga'
import * as api from 'api'

export default function*() {
  yield all([
    attachSideEffect(INVESTOR.GET_INVESTOR, onGetInvestor),
    attachSideEffect(INVESTOR.UPDATE_INVESTOR, onUpdateInvestor),
    attachSideEffect(INVESTOR.GET_ACTIVE_INVESTORS_AMOUNT, onGetActiveInvestorsAmount),
  ])
}

function* onGetInvestor() {
  const result = yield* callWithAuth(api.investor.getInvestor)
  return result
}

function* onUpdateInvestor(data) {
  const obj = yield select(investor.investor)
  const reformatData = {
    ...data,
    exemptFromWithholding: true,
  }
  const result =
    obj && obj.uuid
      ? yield* callWithAuth(api.investor.updateInvestor, reformatData)
      : yield* callWithAuth(api.investor.createInvestor, reformatData)
  return result
}

function* onGetActiveInvestorsAmount() {
  const result = yield call(api.investor.getActiveInvestorsAmount)
  return (result && result.totalInvestors) || 0
}
