import './styles.css'
import 'pages/landing/hero-banner.css'
import React, { useState, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import bgImage from 'img/main-banner1.png'
import { ContentEditor } from 'components/content-editor'
import { Form, HasChanges, SubmitButton } from 'ui/form'
import { ImageInput } from 'ui/form/image'
import {
  uploadImage,
  updateLandingHeroContent,
  getLandingHeroContent,
  contents,
} from 'store/contents'
import { Waitress } from 'components/waitress'

export const PureAdminHeroBanner = ({
  uploadImage,
  onSubmit,

  getLandingHeroContent,
  landingHeroContent,
}) => {
  const { backgroundImageSrc, backgroundImageId } = landingHeroContent || {}
  const [src, setSrc] = useState(bgImage)
  const handleSubmit = useCallback(data => onSubmit({ backgroundImageSrc: src, ...data }), [
    onSubmit,
    src,
  ])
  useEffect(() => {
    setSrc(backgroundImageSrc)
  }, [backgroundImageSrc])

  return (
    <Waitress fn={[getLandingHeroContent]}>
      <div className="hero-banner">
        <img src={src} className="main-banner-img" alt="" />
        <div className="main-banner-text container">
          <Form onSubmit={handleSubmit}>
            <ImageInput
              defaultValue={backgroundImageId || null}
              className="hero-banner-image-control"
              name="backgroundImageId"
              onUpload={uploadImage}
              onSrcChange={setSrc}
            >
              <span className="btn btn-primary">Change Background Image</span>
            </ImageInput>

            <ContentEditor
              className="col-12 col-md-9 col-lg-7"
              contentKey="landing/hero"
              defaultContent={DEFAULT_CONTENT}
            />

            <HasChanges>
              <SubmitButton>Save</SubmitButton>
            </HasChanges>
          </Form>
        </div>
      </div>
    </Waitress>
  )
}

PureAdminHeroBanner.propTypes = {
  getLandingHeroContent: PropTypes.func.isRequired,
  landingHeroContent: PropTypes.shape({
    backgroundImageId: PropTypes.string,
  }),
  onSubmit: PropTypes.func.isRequired,
  uploadImage: PropTypes.func.isRequired,
}

export const AdminHeroBanner = connect(
  createStructuredSelector({
    landingHeroContent: contents.landingHeroContent,
  }),
  {
    uploadImage,
    getLandingHeroContent,
    onSubmit: updateLandingHeroContent,
  },
)(PureAdminHeroBanner)

const DEFAULT_CONTENT = {
  blocks: [
    'Students often lack the capital they need in the early stages of their careers',
    'You can invest now to help them reach their potential and share in their success.',
  ].map(text => ({ type: 'paragraph', data: { text } })),
}
