import { WAIT_FOR_ACTION, ERROR_ACTION } from 'redux-wait-for-action'
import { takeLatest, put, take } from 'redux-saga/effects'

/**
 * @param {String} baseName
 * @returns {CompoundActionType}
 */
export const compoundActionType = baseName => ({
  START: baseName,
  SUCCESS: baseName + '.success',
  FAILURE: baseName + '.failure',
})

export const compoundErrorActionCreator = ({ START, SUCCESS, FAILURE }) => payload => ({
  type: START,
  payload,
  [WAIT_FOR_ACTION]: SUCCESS,
  [ERROR_ACTION]: FAILURE,
})

/**
 * @param {CompoundActionType} param1
 * @param {function(payload:any):any} fn
 */
export const attachSideEffect = (compoundActionType, fn, sideEffect = takeLatest) => {
  if (
    typeof compoundActionType !== 'object' ||
    ['START', 'SUCCESS', 'FAILURE'].some(
      prop =>
        !compoundActionType.hasOwnProperty(prop) || typeof compoundActionType[prop] !== 'string',
    )
  ) {
    throw new TypeError(
      `attachSideEffect(): unexpected type of action ${String(
        compoundActionType,
      )}. Must be an object {START,SUCCESS,FAILURE}`,
    )
  }
  const { START, SUCCESS, FAILURE } = compoundActionType || {}

  return sideEffect(START, asyncSideEffect(fn, { SUCCESS, FAILURE }))
}

/**
 * @param {function(payload:any):any} fn
 * @param {CompoundActionType} param1
 */
export const asyncSideEffect = (fn, { SUCCESS, FAILURE }) =>
  function*({ payload = {} } = {}, ...args) {
    try {
      const result = yield* fn(payload, ...args)
      yield put({ type: SUCCESS, payload: result })

      // make sure that a callback runs *after* a store change caused by SUCCESS action
      yield take(SUCCESS)

      return result
    } catch (err) {
      if (err instanceof Error) {
        // eslint-disable-next-line no-console
        console.error(err.stack)
      }
      const error = err && (err.error || err.message)
      const errors = (err && errors) || null
      yield put({
        type: FAILURE,
        error: { error, errors }, //required for ERROR_ACTION (redux-wait-for-action)
        payload: { error, errors },
      })
      yield take(FAILURE)
    }
  }

/**
 * @typedef {Object} CompoundActionType
 * @prop {String} START
 * @prop {String} SUCCESS
 * @prop {String} FAILURE
 */

/** @typedef {import('./index').Action} Action */
