import React from 'react'
import { Money } from 'ui/money'
import { Tooltip } from 'ui/tooltip'

export const OfferInvestmentGoal = ({
  totalInvestmentAmount,
  termsISAAmount,
  investorNumber,
  maximumGoal,
}) => {
  const isGoalMet = totalInvestmentAmount >= termsISAAmount
  const raisedText = isGoalMet ? 'raised of the maximum goal of' : 'raised of the minimum goal of'
  return (
    <div className="col-12 col-lg-8 offer-top-text-row">
      <div className="offer-top-text">
        <span className="total-sum">
          <Money amount={totalInvestmentAmount} precision={0} />
        </span>
        &nbsp;{raisedText}&nbsp;
        <Money amount={isGoalMet ? maximumGoal : termsISAAmount} precision={0} />
        {pluralConstructor(investorNumber)}
        <Tooltip tooltip="This fundraising round will close and investor funds will be invested in the company if and when either a) the maximum investment amount is reached or b) the close date of the fundraising round is reached and the minimum investment amount has been reached. Please review the SEC Form C linked below for more detail" />
      </div>
    </div>
  )
}

const pluralConstructor = investorNumber => {
  switch (investorNumber) {
    case 0:
      return
    case 1:
      return (
        <span className="investor-counter">
          &nbsp;by <b>{investorNumber}</b> investor
        </span>
      )
    default:
      return (
        <span className="investor-counter">
          &nbsp;by <b>{investorNumber}</b> investors
        </span>
      )
  }
}
