import React from 'react'
import T from 'prop-types'
import { cn } from 'utils'
import { offerDetailsRoute, adminOfferDetailsRoute } from 'const/routes'
import { Link } from 'ui/link'
import { ToggleSaveButton } from 'components/toggle-save-button'
import { Golfer } from 'const/types'
import { Progress } from 'ui/progress'
import { useHistory } from 'react-router-dom'

export const Article = ({
  size,
  offering: {
    studentImage,
    name,
    region,
    uuid,
    totalInvestmentAmount,
    termsISAAmount,
    school,
    subscriptionCount,
    cohort,
    lastName,
    offeringName,
    minAmount,
    offeringBenefit,
  } = {},
}) => {
  const { location } = useHistory()
  return (
    <div className={`tg-card-${size}`}>
      <Link
        to={
          location.pathname.includes('admin')
            ? adminOfferDetailsRoute({ uuid })
            : offerDetailsRoute({ uuid })
        }
        className={`tg-card-${size}-wrap`}
      >
        <div className={`tg-card-${size}-img`}>
          <img
            src={studentImage}
            width={250}
            height={250}
            alt=""
            srcSet=""
            className="img-responsive"
          />
        </div>
        <Progress value={totalInvestmentAmount} max={termsISAAmount} minAmount={minAmount} />
      </Link>
      <div className={`tg-card-${size}-info`}>
        <span className="golfer-name">{offeringName ? offeringName : `${name} Creator LLC`}</span>
        {offeringBenefit && <span className="tg-card-male">{offeringBenefit}</span>}
        <div className="tg-card-data clearfix">
          <span className="tg-card-male">{region}</span>
          <span className="tg-card-based">{school}</span>
          <span className="tg-card-level">{cohort}</span>
          {subscriptionCount && <span className="tg-card-based">{subscriptionCount / 1000}k</span>}
        </div>
      </div>
      <ToggleSaveButton uuid={uuid} className={cn(size === 'big' && 'no-bg')} />
    </div>
  )
}

Article.propTypes = {
  offering: Golfer.isRequired,
  size: T.oneOf(['small', 'big']),
}
Article.defaultProps = {
  size: 'small',
}
