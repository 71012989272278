import './styles.css'
import React from 'react'
import PropTypes from 'prop-types'

import IconClose from 'icons/clear.svg'
import { IconButton } from 'ui/button'
import { cn } from 'utils'

const HIDE_INTERVAL = 1000000
export class Banner extends React.PureComponent {
  static propTypes = {
    message: PropTypes.node,
    onClose: PropTypes.func,
    type: PropTypes.oneOf(['success', 'failure']),
  }

  timeout = null

  hide = () => {
    this.props.onClose && this.props.onClose()
  }
  componentDidMount() {
    this.resetHideTimeout()
  }
  componentDidUpdate() {
    this.resetHideTimeout()
  }
  resetHideTimeout() {
    if (this.timeout) {
      clearTimeout(this.timeout)
      this.timeout = null
    }
    this.timeout = setTimeout(this.hide, HIDE_INTERVAL)
  }

  render() {
    const { type, message } = this.props

    return (
      <div id="notification-container">
        <section className={cn('banner', type, !message && 'banner-hidden')}>
          <div className="message">{message}</div>
          <IconButton className="button-close" onClick={this.hide}>
            <IconClose />
          </IconButton>
        </section>
      </div>
    )
  }
}

export const Failure = props => <Banner type="failure" title="Error" {...props} />
export const Success = props => <Banner type="success" title="Success" {...props} />

export default {
  Failure,
  Success,
}
