import React from 'react'
import { cn } from 'utils'
import { Form, SubmitButton } from 'ui/form'
export const ApplyStudyForm = ({ className }) => {
  return (
    <div className={cn('apply-form-con', className)}>
      <Form onSubmit={() => console.log('form')} className="apply-form">
        <div className="fields">
          <label className="apply-field-label">Email Address</label>
          <input className="apply-field" name="email" placeholder="Email Address..." />
        </div>
        <div className="apply-button">
          <SubmitButton>Apply now</SubmitButton>
        </div>
        <p className="apply-text">
          Please use the same email address you used on your Launch Academy application
        </p>
      </Form>
    </div>
  )
}
