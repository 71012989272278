import './styles.css'
import React from 'react'
import { ContentViewer } from 'components/content-viewer'

export const TermsOfUse = () => {
  return (
    <div className="container static-page terms-of-use">
      <ContentViewer contentKey="terms-of-use" defaultContent={DEFAULT} />
    </div>
  )
}

export const DEFAULT = {
  blocks: [],
}
