import './styles.css'
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { SignedOut } from 'components/signed'
import { getUserAccount } from 'store/user'
import { HeroBanner } from './HeroBanner'
import { InvestingInfoBlock } from './InvestingInfoBlock'
import { SignUpBlock } from './SignUpBlock'
import { Search } from './Search'
import { ArticlesList } from './ArticlesList'
import { Link } from 'ui/link'
import { routes } from 'const/routes'
import { Helmet } from 'react-helmet'
import { ContentViewer } from 'components/content-viewer'
import { defaultRiskText } from 'pages/admin/landing'
import { ClosedArticlesList } from './ClosedArticlesList'
export const LandingPage = connect(
  null,
  {
    getUserAccount,
  },
)(
  class LandingPage extends React.PureComponent {
    static propTypes = {
      getUserAccount: PropTypes.func.isRequired,
    }

    componentDidMount() {
      this.props.getUserAccount()
    }
    render() {
      return (
        <>
          <div className="home">
            <Helmet>
              <title>OverSubscribe</title>
            </Helmet>
            <HeroBanner />

            <div className="container">
              <div className="risk-info">
                <ContentViewer contentKey="landing/risk-info" defaultContent={defaultRiskText[0]} />
              </div>
              {/* <ArticlesList title="Current OverSubscribe Opportunities" /> */}
              {/* <ClosedArticlesList /> */}
              {/* <Search /> */}

              <InvestingInfoBlock />
            </div>
            <SignedOut>
              <SignUpBlock />
            </SignedOut>
          </div>
        </>
      )
    }
  },
)
