import { compoundActionType, compoundErrorActionCreator } from '../utils'

import * as wire from './selectors'

export { wire }

export const WIRE_INSTRUCTIONS = {
  GET_WIRE_INSTRUCTIONS: compoundActionType('wire-instructions.get-wire-instructions'),
}

export const getWireInstructions = compoundErrorActionCreator(
  WIRE_INSTRUCTIONS.GET_WIRE_INSTRUCTIONS,
)

export const initialState = {
  pending: false,
  error: null,
  byId: null,
}

export default function(state = initialState, { type, payload }) {
  switch (type) {
    case WIRE_INSTRUCTIONS.GET_WIRE_INSTRUCTIONS.START:
      return {
        ...state,
        pending: true,
        error: null,
      }
    case WIRE_INSTRUCTIONS.GET_WIRE_INSTRUCTIONS.SUCCESS:
      if (!payload) return { ...state, pending: false }
      return {
        ...state,
        pending: false,
        byId: {
          ...state.byId,
          ...payload,
        },
      }
    case WIRE_INSTRUCTIONS.GET_WIRE_INSTRUCTIONS.FAILURE:
      return {
        ...state,
        pending: false,
        error: payload.error,
      }
    default:
      return state
  }
}
