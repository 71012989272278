import React from 'react'
import { createStructuredSelector } from 'reselect'
import T from 'prop-types'
import { connect } from 'react-redux'

import { Article } from './Article'
import { getEndingSoonList, offers } from 'store/offers'
import { Waitress } from 'components/waitress'
import { Golfer } from 'const/types'

export const ArticlesListComponent = ({
  getEndingSoonList,
  title,
  items: [topItem, ...items] = [],
}) => (
  <Waitress fn={[getEndingSoonList]}>
    <div className="tg-list">
      <div className="row">
        <div className="col-12">
          <h6 className="mb-20">{title}</h6>
        </div>
        <div className="col-12 col-lg-6 mb-40">
          {topItem && <Article size="big" offering={topItem} />}
        </div>
        <div className="col-lg-1 visible-lg">
          <div className="separator-vertical" />
        </div>
        <div className="col-12 col-lg-5">
          {items && items.map(item => <Article offering={item} key={item.uuid} />)}
        </div>
      </div>
    </div>
  </Waitress>
)

ArticlesListComponent.propTypes = {
  getEndingSoonList: T.func.isRequired,
  items: T.arrayOf(Golfer),
  title: T.string,
}

export const ArticlesList = connect(
  createStructuredSelector({
    items: offers.closingSoonList,
  }),
  { getEndingSoonList },
)(ArticlesListComponent)
