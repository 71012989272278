import React from 'react'
import PropTypes from 'prop-types'
import { BaseField } from './base-field'

export class RadioInputGroup extends BaseField {
  static propTypes = {
    ...BaseField.propTypes,
    disabled: PropTypes.bool,
    name: PropTypes.string.isRequired,
    onValidate: PropTypes.func.isRequired,
    options: PropTypes.objectOf(PropTypes.node),
    validation: PropTypes.func,
  }
  static defaultProps = {}

  /** @type {HTMLInputElement} */
  element = null

  setElement = element => {
    this.element = element
    if (!element) return
    const value = this.props.defaultValue || null
    // let the parent form knows about this control
    this.broadcastUpdates({ value })
  }

  handleChange = event => {
    const { value, checked } = event.target

    if (!checked) return

    if (this.props.onChange) {
      event.normalValue = this.getValue()
      this.props.onChange(event)
    }
    // let the parent form knows about changes
    this.broadcastUpdates({ value })
    // start validation on change
    this.validate()
  }

  /**
   * @override
   * @returns {boolean}
   */
  isValid() {
    if (!this.element) {
      return false
    }
    return this.props.required ? this.getValue() : true
  }

  /**
   * @override
   */
  setValue(value = null) {
    if (!this.element) return
    const radio = this.element.querySelector(`input[value="${value}"]`)
    if (radio) {
      radio.checked = true
      this.broadcastUpdates({ value })
    }
  }

  /**
   * @override
   */
  getValue() {
    if (!this.element) return undefined
    const radio = this.element.querySelector(`input[name=${this.props.name}]:checked`)
    const value = radio && radio.value
    return value === '' ? null : value
  }

  // componentDidUpdate(prevProps, prevState) {
  //   super.componentDidUpdate(prevProps, prevState)

  //   if (prevProps.validation !== this.props.validation) {
  //     const value = this.getValue()
  //     this.resetCustomValidity({ value })
  //     this.broadcastUpdates({ value })
  //     this.validate()
  //   }
  // }

  render() {
    const {
      disabled,
      validation,
      onValidate,
      validationMessages,
      onReset,
      defaultValue,
      options,
      className,
      name,
      required,
      ...props
    } = this.props

    return (
      <div ref={this.setElement} id={props.id} className={className}>
        {options &&
        Object.entries(options).map(([value, label]) => (
          <label key={value}>
            <input
              {...props}
              type="radio"
              defaultChecked={value === defaultValue}
              name={name}
              value={value}
              onChange={this.handleChange}
              disabled={disabled}
            />
            <span className="option-label" />
            {label}
          </label>
        ))}
      </div>
    )
  }
}
