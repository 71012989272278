import React from 'react'
import T from 'prop-types'

import { Consumer } from './context'
import { Button } from 'ui/button'

export const SubmitButton = props => (
  <Consumer>
    {({ invalid, pending }) => (
      <Button {...props} type="submit" disabled={props.disabled || invalid || pending} />
    )}
  </Consumer>
)
SubmitButton.propTypes = {
  disabled: T.bool,
}
