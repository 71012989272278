import { all, call } from 'redux-saga/effects'

import * as api from 'api'
import { attachSideEffect } from '../utils'
import { REGISTER } from '.'

// const CLIENT_ID = process.env.CLIENT_ID
// const CLIENT_SECRET = process.env.CLIENT_SECRET

export default function*() {
  yield all([attachSideEffect(REGISTER.CREATE_USER, createUser)])
}

function* createUser(data) {
  const result = yield call(api.register.createUser, data)
  return result
}
