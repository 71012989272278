import React, { useState, useEffect } from 'react'
import T from 'prop-types'
import { Throbber } from 'ui/throbber'
import { ValidationMessage } from 'ui/validation-message'

export const Waitress = ({ fn, children }) => {
  const [error, setError] = useState(null)
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    Promise.all(fn.map(f => f()))
      .catch((...args) => {
        const { error } = args[0]
        setError(error)
      })
      .finally(() => setLoaded(true))
    /* eslint-disable-next-line */
  }, [])

  return error ? (
    <>
      <ValidationMessage error>{error}</ValidationMessage>
      {children}
    </>
  ) : loaded ? (
    children
  ) : (
    <Throbber />
  )
}

Waitress.propTypes = {
  fn: T.arrayOf(T.func).isRequired,
}
