/**
 * React Component.
 * Stops focus/blur event propagation when a focus is moving between children elements
 */
import React from 'react'
import T from 'prop-types'

export class FocusGroup extends React.PureComponent {
  static propTypes = {
    onBlur: T.func,
    onFocus: T.func,
    tag: T.string,
  }

  static defaultProps = {
    tag: 'div',
  }

  focused = false

  onFocus = e => {
    if (this.focused) {
      e && e.stopPropagation && e.stopPropagation()
    } else {
      this.focused = true
      this.props.onFocus && this.props.onFocus(e)
    }
  }

  onBlur = e => {
    if (!this.focused) {
      return
    }
    if (!e.relatedTarget || !isSameOrChildElement(this.element, e.relatedTarget)) {
      this.focused = false
      this.props.onBlur && this.props.onBlur(e)
    } else {
      e && e.stopPropagation && e.stopPropagation()
    }
  }

  listenElement = element => {
    this.element = element
    if (element) {
      element.addEventListener('focus', this.onFocus, true)
      element.addEventListener('blur', this.onBlur, true)
      this.focused = isSameOrChildElement(element, document.activeElement)
    } else {
      this.focused = false
    }
  }

  componentWillUnmount() {
    if (this.element) {
      this.element.removeEventListener('focus', this.onFocus)
      this.element.removeEventListener('blur', this.onBlur)
    }
    this.focused = false
  }

  render() {
    const { onFocus, onBlur, tag, ...props } = this.props
    return React.createElement(tag, {
      ...props,
      ref: this.listenElement,
    })
  }
}

export function isSameOrChildElement(parent, child) {
  return parent && child && (parent === child || parent.contains(child))
}
