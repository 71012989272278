import './account-menu.css'
import React from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { user } from 'store/user'
import { logout } from 'store/auth'

import { routes } from 'const/routes'
import { MenuLink, MenuButton } from 'ui/menu'
import { Avatar } from 'ui/avatar'
import { DropdownToggle } from 'ui/dropdown-toggle'
import { SignedIn } from 'components/signed'
import {
  INVESTOR,
  ISSUER,
  ISSUER_ACCEPTED_RESERVED,
  ISSUER_ACCEPTED_REVIEW,
  ISSUER_PENDING_RESERVED,
  ISSUER_PENDING_REVIEW,
  ISSUER_REJECTED_RESERVED,
  ISSUER_REJECTED_REVIEW,
  ATHLETE_STATUS_ISSUER,
  ATHLETE_STATUS_SUCCESSFUL_OFFERING,
  ATHLETE_STATUS_UNSUCCESSFUL_OFFERING,
} from 'const/types'

export const AccountMenu = connect(
  // createStructuredSelector({
  //   fullName: user.fullName,
  //   avatar: user.avatar,
  // }),
  state => ({
    fullName: user.fullName(state),
    avatar: user.avatar(state),
    contact: state.registerIssuer.contact,
  }),
  {
    logout,
  },
)(({ fullName, avatar, logout, contact }) => {
  const pendingSt = [
    ISSUER_PENDING_RESERVED,
    ISSUER_PENDING_REVIEW,
    ISSUER_REJECTED_RESERVED,
    ISSUER_REJECTED_REVIEW,
  ]
  const acceptedSt = [
    ATHLETE_STATUS_ISSUER,
    ATHLETE_STATUS_SUCCESSFUL_OFFERING,
    ATHLETE_STATUS_UNSUCCESSFUL_OFFERING,
  ]
  const label = (
    <>
      <Avatar src={avatar} />
      <span className="on-desktop">{fullName}</span>
    </>
  )
  let issuerMenuLink = {
    label: 'Settings',
    path: routes.issuerSettings,
  }
  if (contact && contact.athlete_status) {
    switch (contact.athlete_status) {
      case ISSUER_ACCEPTED_RESERVED:
        issuerMenuLink.path = routes.issuerFourthStep
        break
      case ISSUER_ACCEPTED_REVIEW:
      case ATHLETE_STATUS_ISSUER:
      case ATHLETE_STATUS_SUCCESSFUL_OFFERING:
      case ATHLETE_STATUS_UNSUCCESSFUL_OFFERING:
        issuerMenuLink = {
          label: 'Settings',
          path: routes.issuerSettings,
        }
        break
    }
  }
  return (
    <DropdownToggle label={label} icon={null} className="account-menu">
      <hr />
      <SignedIn userRole={INVESTOR}>
        <MenuLink to={routes.myInvestments}>My Investments</MenuLink>
        <MenuLink to={routes.myInvestmentSaved}>Saved</MenuLink>
        <MenuLink to={routes.notifications}>Notifications</MenuLink>
        <MenuLink to={routes.settings}>Settings</MenuLink>
        <hr />
      </SignedIn>
      <SignedIn userRole={ISSUER}>
        {contact && pendingSt.includes(contact.athlete_status) ? (
          <MenuLink to={issuerMenuLink.path}>{issuerMenuLink.label}</MenuLink>
        ) : (
          ''
        )}
        {(1 || (contact && contact.athlete_status === ATHLETE_STATUS_ISSUER)) && (
          <MenuLink to={routes.issuerQuestionAnswer}>My Q&amp;A</MenuLink>
        )}
        {contact && contact.athlete_status === ATHLETE_STATUS_ISSUER && (
          <MenuLink to={routes.issuerInvestments}>Investments</MenuLink>
        )}
        {contact && acceptedSt.concat([ISSUER_ACCEPTED_REVIEW]).includes(contact.athlete_status) ? (
          <MenuLink to={issuerMenuLink.path}>{issuerMenuLink.label}</MenuLink>
        ) : (
          ''
        )}
        <hr />
      </SignedIn>
      <MenuButton onClick={logout}>Logout</MenuButton>
    </DropdownToggle>
  )
})
