import './dropdown-toggle.css'
import React from 'react'
import T from 'prop-types'

import { FocusGroup } from 'ui/focus-group'
import { Button } from 'ui/button'
import IconDown from 'icons/down.svg'
import { cn, isIOS } from 'utils'

const KEYS = {
  CLOSE: ['Esc', 'Escape'],
  OPEN: ['Down', 'ArrowDown', ' '],
}

export class DropdownToggle extends React.PureComponent {
  static propTypes = {
    'aria-haspopup': T.any,
    disabled: T.bool,
    hideIcon: T.bool,
    icon: T.node,
    label: T.node,
    onKeyUp: T.func,
    onOpen: T.func,
  }

  static defaultProps = {
    'aria-haspopup': true,
    hideIcon: false,
    icon: <IconDown className="dropdown-icon" />,
  }

  state = {
    open: false,
  }

  button = React.createRef()
  focusGroup = React.createRef()

  windowClick = e => {
    if (this.focusGroup && this.focusGroup.current.element.contains(e.target)) {
      return
    }
    this.close()
  }

  open = () => {
    !this.state.open &&
      this.setState({ open: true }, () => {
        this.props.onOpen && this.props.onOpen()
      })
  }

  close = () => {
    this.state.open &&
      this.setState({ open: false }, () => {
        this.focus()
      })
  }

  toggle = () => {
    this.state.open ? this.close() : this.open()
  }

  /**
   * @param {KeyboardEvent} event
   */
  onButtonKeyUp = event => {
    if (this.state.open && KEYS.CLOSE.includes(event.key)) {
      event.preventDefault()
      event.stopPropagation()
      this.close()
    } else if (!this.state.open && KEYS.OPEN.includes(event.key)) {
      event.preventDefault()
      event.stopPropagation()
      this.open()
    } else {
      this.props.onKeyUp && this.props.onKeyUp(event)
    }
  }

  componentDidMount() {
    document.addEventListener('click', this.windowClick, false)
    if (isIOS()) {
      document.addEventListener('touchstart', this.windowClick, false)
    }
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.windowClick, false)
    if (isIOS()) {
      document.removeEventListener('touchstart', this.windowClick, false)
    }
  }

  focus() {
    this.button.current && this.button.current.focus()
  }

  render() {
    const { children, label, className, disabled, hideIcon, icon } = this.props

    return (
      <FocusGroup
        onKeyUp={this.onButtonKeyUp}
        className={cn(this.state.open && 'open', 'dropdown-toggle', className)}
        onBlur={this.close}
        ref={this.focusGroup}
      >
        <Button
          disabled={disabled}
          className="dropdown-button"
          onClick={this.toggle}
          ref={this.button}
          aria-haspopup={this.props['aria-haspopup']}
          aria-expanded={this.state.open ? true : undefined}
        >
          <span>{label}</span>
          {!hideIcon && icon}
        </Button>

        <div className="dropdown-content">{children}</div>
      </FocusGroup>
    )
  }
}
