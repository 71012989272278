import React from 'react'
import T from 'prop-types'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { RouteProps } from 'const/types'
import { Banner } from 'ui/banner'
import { clearAlerts, alerts } from 'store/alerts'

class RouterBanner extends React.PureComponent {
  static propTypes = {
    ...RouteProps,
    ...Banner.propTypes,
    clearAlerts: T.func.isRequired,
  }

  componentDidUpdate({ location: { pathname } = {} } = {}) {
    if (this.props.location.pathname !== pathname) {
      this.props.clearAlerts()
    }
  }

  render() {
    const { alertMessage = {} } = this.props
    return <Banner {...alertMessage} onClose={this.props.clearAlerts} />
  }
}

export const GlobalAlert = withRouter(
  connect(
    createStructuredSelector({ alertMessage: alerts.alertMessage }),
    { clearAlerts },
  )(RouterBanner),
)
