// import { createSelector } from 'reselect'

/** @param {State} state */
export const pending = state => state.states.pending

/** @param {State} state */
export const error = state => state.states.error

/** @param {State} state */
export const stateOptions = state => state.states.byCode
