import { compoundActionType, compoundErrorActionCreator } from '../utils'
import * as selectors from './selectors'

export { selectors }

export const ACTIONS = {
  GET_BANK_INFO: compoundActionType('banks.get-info'),
}

/** @returns {Action} */
export const getBankInfo = compoundErrorActionCreator(ACTIONS.GET_BANK_INFO)

/**
 * @type {Banks}
 */
export const initialState = {
  fee: 0,
  pending: false,
  error: null,
  byRoutingNumber: {},
}

/**
 * @param {Banks} state
 * @param {Action} action
 * @returns {Banks}
 */
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case ACTIONS.GET_BANK_INFO.START:
      return {
        ...state,
        pending: true,
        error: null,
      }

    case ACTIONS.GET_BANK_INFO.SUCCESS:
      return {
        ...state,
        pending: false,
        byRoutingNumber: {
          ...state.byRoutingNumber,
          [payload.routing_number]: payload,
        },
      }

    case ACTIONS.GET_BANK_INFO.FAILURE:
      return {
        ...state,
        pending: false,
        error: payload.error || null,
      }
    default:
      return state
  }
}

/**
 * @typedef {Object} Banks
 * @prop {Object.<BankAccount>} byRoutingNumber
 * @prop {Boolean} pending
 * @prop {String|null} error
 * @prop {Object.<String,String>} errors
 */

/**
 * @typedef {Object} BankAccount
 * @prop {String} account_name
 * @prop {String} account_address
 * @prop {String} name
 * @prop {String} phone_number
 * @prop {String} routing_number
 * @prop {String} account_number
 */
/** @typedef {import('../.').Action} Action */
