import { all, call, select, put } from 'redux-saga/effects'
import { attachSideEffect } from '../utils'
import { callWithAuth } from 'store/auth/saga'

import * as api from 'api'
import { USER, user } from '.'
import { AUTH } from '../auth'
import { REGISTER_ISSUER } from '../register-issuer'
import { buildListParams } from 'utils/list'

export default function*() {
  yield all([
    attachSideEffect(USER.GET_ACCOUNT, onGetUserAccount),
    attachSideEffect(USER.PATCH_ACCOUNT, onPatchUserAccount),
    attachSideEffect(USER.GET_ADDRESS, onGetUserAddress),
    attachSideEffect(USER.UPDATE_ADDRESS, onUpdateUserAddress),
    attachSideEffect(USER.GET_ACH_SETTINGS, onGetUserAchSettings),
    attachSideEffect(USER.UPDATE_ACH_SETTINGS, onUpdateUserAchSettings),
    attachSideEffect(USER.SIGN_AGREEMENT, onSignUpAgreement),
    attachSideEffect(USER.UPDATE_PROFILE_IMAGE, onUpdateProfileImage),
    attachSideEffect(USER.UPDATE_QUESTION_ANSWERS, onUpdateQuestionAnswers),
    attachSideEffect(USER.UPDATE_PASSWORD, onUpdatePassword),
    attachSideEffect(USER.GET_CREDIT_CARD_SETTINGS, onGetUserCreditCardSettings),
    attachSideEffect(USER.UPDATE_CREDIT_CARD_SETTINGS, onUpdateUserCreditCardSettings),
    attachSideEffect(USER.GET_PLAID_LINK, onGetPlaidLink),
    attachSideEffect(USER.GET_PLAID_SUCCESS, onGetPlaidSuccess),
    attachSideEffect(USER.GET_STRIPE_LINK, onGetStripeLink),
    attachSideEffect(USER.GET_STRIPE_SUCCESS, onGetStripeSuccess),
    attachSideEffect(USER.GET_USER_DOCUMENTS, onGetUserDocuments),
    attachSideEffect(USER.UPLOAD_DOCUMENT, onUploadDocument),
    attachSideEffect(USER.DELETE_DOCUMENT, onDeleteDocument),
  ])
}

function* onGetUserAccount() {
  const result = yield* callWithAuth(api.user.getAccount)
  if (!result) return null
  const { phone, ...user } = result
  // eslint-disable-next-line import/namespace
  if (user && user.email) {
    // eslint-disable-next-line import/namespace
    const contact = yield call(api.hubspot.getContact, user.email)
    yield put({ type: REGISTER_ISSUER.GET_CONTACT.SUCCESS, payload: contact })
  }
  return {
    ...user,
    phone: (phone && phone.phone) || null,
  }
}
function* onPatchUserAccount(data) {
  const result = yield* callWithAuth(api.user.patchAccount, data)
  if (!result) return null
  const { phone, ...user } = result
  return { ...user, phone: (phone && phone.phone) || null }
}

function* onGetUserAddress() {
  const result = yield* callWithAuth(api.user.getAddress)
  return result
}
function* onUpdateUserAddress(data) {
  const uuid = yield select(user.addressUuid)
  const result = yield* uuid
    ? callWithAuth(api.user.patchAddress, data)
    : callWithAuth(api.user.postAddress, data)
  return result
}

function* onGetUserAchSettings() {
  const result = yield* callWithAuth(api.user.getAchSettings)
  return result
}
function* onUpdateUserAchSettings(data) {
  const uuid = yield select(user.achSettingsUuid)
  const result = yield* uuid
    ? callWithAuth(api.user.patchAchSettings, { ...data, uuid })
    : callWithAuth(api.user.postAchSettings, data)
  return result
}
function* onGetUserCreditCardSettings() {
  const result = yield* callWithAuth(api.user.getCreditCardSettings)
  return result
}
function* onUpdateUserCreditCardSettings(data) {
  const { uuid } = yield select(user.creditCardSettings)
  const result = uuid
    ? yield* callWithAuth(api.user.patchCreditCardSettings(uuid), { ...data, uuid })
    : yield* callWithAuth(api.user.postCreditCardSettings, data)
  return result
}

function* onGetPlaidLink() {
  const result = yield* callWithAuth(api.user.getPlaidLink)
  return result
}
function* onGetPlaidSuccess() {
  const result = yield* callWithAuth(api.user.getPlaidSuccess)
  return result
}

function* onGetStripeLink() {
  const result = yield* callWithAuth(api.user.getStripeLink)
  return result
}
function* onGetStripeSuccess() {
  const result = yield* callWithAuth(api.user.getStripeSuccess)
  return result
}

function* onGetUserDocuments(data) {
  const result = yield* callWithAuth(api.user.getUserDocuments, buildListParams(data));
  return result
}

function* onUploadDocument(document) {
  const result = yield* callWithAuth(api.user.uploadDocument, {document})
  return result
}

function* onDeleteDocument(uuid) {
  yield* callWithAuth(api.user.deleteDocument, uuid)
  return { uuid }
}

function* onSignUpAgreement() {
  const result = yield* callWithAuth(api.user.patchAccount, { isAgreementSigned: true })
  return result
}

function* onUpdateProfileImage(image) {
  const result = yield* callWithAuth(api.image.upload, { image })
  return result
}

function* onUpdateQuestionAnswers(data) {
  const result = yield* callWithAuth(api.qa.answerQuestion(data.uuid), { answer: data.answer })
  return result
}

function* onUpdatePassword({ password, confirmationToken }) {
  if (!password) throw { error: 'onResetPassword: missing password' }
  if (!confirmationToken) throw { error: 'onResetPassword: missing token' }
  const result = yield call(api.user.postUpdatePassword, { password, confirmationToken })
  yield put({ type: AUTH.REFRESH_TOKEN.SUCCESS, payload: result })
  yield put({ type: USER.GET_ACCOUNT.START })
  return result
}
