import React, { useCallback } from 'react'
import { withRouter } from 'react-router-dom'

import { Field } from 'ui/field'
import { fulfillUrlWithParam, getParamFromUrl } from 'utils/url'
import { SEARCH_PARAM } from 'const/list'

export const SearchComponent = withRouter(
  ({ name, placeholder, history, location: { search }, searchParam }) => {
    const searchValueFromUrl = getParamFromUrl(search, searchParam, '')

    const handleInputChange = useCallback(
      e => {
        const value = e.target.value.trim()
        if (value !== searchValueFromUrl) {
          history.push(fulfillUrlWithParam(search, searchParam, value))
        }
      },
      [history, search, searchParam, searchValueFromUrl],
    )

    return (
      <Field
        type="search"
        label={placeholder}
        name={name}
        onChange={handleInputChange}
        defaultValue={searchValueFromUrl}
      />
    )
  },
)

SearchComponent.defaultProps = {
  searchParam: SEARCH_PARAM,
}
