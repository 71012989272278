export const firstElementSibling = element =>
  element && element.parentNode && element.parentNode.firstElementChild

export const lastElementSibling = element =>
  element && element.parentNode && element.parentNode.lastElementChild

export const nextElementSibling = element =>
  element &&
  (element.nextElementSibling || (element.parentNode && element.parentNode.firstElementChild))

export const previousElementSibling = element =>
  element &&
  (element.previousElementSibling || (element.parentNode && element.parentNode.lastElementChild))

export const focusElement = target => {
  target && target.focus && target.focus()
}

export const blurEventTarget = event => event.target && event.target.blur && event.target.blur()
