import './toggle-save-button.css'
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { cn } from 'utils'
import { saved, toggleSaved } from 'store/saved'
import { IconButton } from 'ui/button'
import IconSaveBg from 'icons/saveBg.svg'
import { routes } from 'const/routes'
import { user } from 'store/user'
import { SignedIn } from '../signed'
import { INVESTOR } from 'const/types'

const PureToggleSaveButton = ({ className, isSaved, toggleSaved, goToSignUp, isSignedIn }) => (
  <SignedIn userRole={INVESTOR}>
    <IconButton
      className={cn('toggle-save-button', isSaved && 'saved', className)}
      onClick={isSignedIn ? toggleSaved : goToSignUp}
    >
      <IconSaveBg />
    </IconButton>
  </SignedIn>
)
PureToggleSaveButton.propTypes = {
  goToSignUp: PropTypes.func.isRequired,
  isSaved: PropTypes.bool,
  isSignedIn: PropTypes.bool.isRequired,
  toggleSaved: PropTypes.func.isRequired,
}

export const ToggleSaveButton = withRouter(
  connect(
    (state, { uuid }) => ({
      isSignedIn: !!user.account(state),
      isSaved: saved.isIdSaved(state, { uuid }),
    }),
    (dispatch, { uuid, history }) => ({
      toggleSaved: () => dispatch(toggleSaved(uuid)),
      goToSignUp: () => history.push(routes.signUp),
    }),
  )(PureToggleSaveButton),
)
