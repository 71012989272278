import { createSelector } from 'reselect'

export const map = state => state.notifications.byId || null

export const list = createSelector(
  map,
  map => (map && Object.values(map).sort(sorting)) || null,
)

const sorting = (a, b) => {
  // if (a.isRead === b.isRead) {
  return new Date(b.updatedAt).valueOf() - new Date(a.updatedAt).valueOf()
  // }
  // return a.isRead ? 1 : -1
}
