import './header.css'
import React from 'react'

import { routes } from 'const/routes'
import { SignedOut, SignedIn } from 'components/signed'
import { INVESTOR, ISSUER, SUPER_ADMIN } from 'const/types'
import { AccountMenu } from 'components/account-menu'
import { NavLink, Link } from 'ui/link'
import { cn } from 'utils'
import logo from 'img/oversubscribe-logo.png'
import logo_white from 'img/oversubscribe-logo-white.png'
import { HeaderNavbarMenu } from 'components/header-navbar-menu'

export const HeaderNav = ({ className }) => {
  return (
    <nav className={cn('header-nav', className)}>
      <NavLink className="header-nav-item" exact theme="nav" to={routes.landing}>
        Home
      </NavLink>
      <NavLink className="header-nav-item" theme="nav" to={routes.learnMore}>
        Educational Materials
      </NavLink>
      <NavLink className="header-nav-item" theme="nav" to={routes.about}>
        About Us
      </NavLink>
    </nav>
  )
}

export const Header = () => (
  <>
    <SignedOut>
      <header className="header main-header">
        <Link to={routes.landing} className="logo">
          <img src={logo} className="img-fluid" alt="" srcSet="" />
        </Link>
        <div className="menu-con">
          <HeaderNavbarMenu />
          <nav>
            <NavLink className="login-btn" theme="nav" to={routes.login}>
              Login
            </NavLink>

            {/* <NavLink theme="nav" className="log-as-golfer" to={routes.issuerLogin}>
            Apply as a student
          </NavLink> */}
          </nav>
        </div>
      </header>
    </SignedOut>
    <SignedIn userRole={[ISSUER, INVESTOR]}>
      <header className="header main-header">
        <Link to={routes.landing} className="logo">
          <img src={logo} className="img-fluid" alt="" srcSet="" />
        </Link>
        <div className="menu-con">
          <HeaderNavbarMenu />
          <AccountMenu />
        </div>
      </header>
    </SignedIn>
    <SignedIn userRole={SUPER_ADMIN}>
      <header className="header main-header admin">
        <Link to={routes.landing} className="logo">
          <img src={logo_white} className="img-fluid" alt="" srcSet="" />
        </Link>
        <div className="admin-menu">
          <NavLink to={routes.adminLanding}>Landing</NavLink>
          <NavLink to={routes.adminIssuersList}>Issuers</NavLink>
          <NavLink to={routes.adminInvestorsList}>Investors</NavLink>
          <NavLink to={routes.adminUsersList}>Admin Users</NavLink>
          <NavLink to={routes.adminEmailsList}>Emails</NavLink>
          <NavLink to={routes.adminAbout}>About</NavLink>
          <NavLink to={routes.adminLearnMore}>Learn More</NavLink>
          <NavLink to={routes.adminPrivacyPolicy}>Privacy Policy</NavLink>
          <NavLink to={routes.adminTermsOfUse}>Terms of Use</NavLink>
        </div>

        <AccountMenu />
      </header>
    </SignedIn>
  </>
)
