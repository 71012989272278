import './header.css'
import React, { useEffect } from 'react'
import { withRouter } from 'react-router-dom'

import { RouteProps } from 'const/types'

export const PureContent = ({ location: { pathname, state, hash }, children }) => {
  useEffect(() => {
    if (hash) {
      const name = hash.replace(/^#/, '')
      const element = document.getElementById(name) || document.querySelector(`*[name="${name}"]`)
      if (element) {
        window.scrollTo({ top: element.offsetTop, behavior: 'smooth' })
        return
      }
    }
    const noScroll = state && state.noScroll
    if (!noScroll) {
      try {
        window.scrollTo({ top: 0, behavior: 'smooth' })
      } catch (e) {
        //
      }
    }
  }, [hash, pathname, state])

  return <div className="content">{children}</div>
}

PureContent.propTypes = {
  location: RouteProps.location,
}

export const Content = withRouter(PureContent)
