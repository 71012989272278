import React from 'react'

import { Link } from 'ui/link'
import { offerDetailsRoute, adminOfferDetailsRoute } from 'const/routes'
import { Golfer } from 'const/types'
import { ToggleSaveButton } from 'components/toggle-save-button'
import { useHistory } from 'react-router-dom'
export const SearchCard = ({
  offering: {
    uuid,
    name,
    region,
    studentImage,
    totalInvestmentAmount,
    termsISAAmount,
    school,
    subscriptionCount,
    cohort,
  },
}) => {
  const { location } = useHistory()

  return (
    <div className="col-12 col-lg-6 col-xl-4 mb-40">
      <div className="tg-search-result-card">
        <Link
          to={
            location.pathname.includes('admin')
              ? adminOfferDetailsRoute({ uuid })
              : offerDetailsRoute({ uuid })
          }
          className="tg-search-result-card-img"
        >
          <img
            src={studentImage}
            width={250}
            height={250}
            alt=""
            srcSet=""
            className="img-responsive search"
          />
          <span className="icon-empty-top" />
        </Link>
        {/*<span className="tg-search-result-card-img">*/}
        {/*  <img src={studentImage} alt="" srcSet="" className="img-responsive" />*/}
        {/*  <span className="icon-empty-top" />*/}
        {/*</span>*/}

        {/* <Progress value={totalInvestmentAmount} max={termsISAAmount} className="mb-20" /> */}

        <div className="tg-search-card--info search-title">
          <span className="golfer-name">{name} Creator Inc.</span>
          <div className="tg-card-data clearfix">
            <span className="tg-card-male">{region}</span>
            <span className="tg-card-based">{school}</span>
            {subscriptionCount && (
              <span className="tg-card-based">{subscriptionCount / 1000}k</span>
            )}
            <span className="tg-card-level">{cohort}</span>
          </div>
        </div>

        <ToggleSaveButton uuid={uuid} />
      </div>
    </div>
  )
}

SearchCard.propTypes = {
  offering: Golfer.isRequired,
}
