import React from 'react'
import T from 'prop-types'
import { toHumanDateTime } from 'utils'

const textGenerator = ({ name, text, date }) => {
  return (
    <>
      <b>{name}:&nbsp;</b>
      {text}&nbsp;
      <br />
      <small>{toHumanDateTime(date)}</small>
    </>
  )
}

textGenerator.propTypes = {
  date: T.string,
  name: T.string,
  text: T.string,
}
//TODO: smth is definately wrong with these attrs
// e.g. Shouldn't createdAt & fullName be a prop of question/answer?
export const QuestionAnswerItem = ({ question, answer, createdAt, fullName }) => (
  <div className="col-12 col-md-6">
    <div className="offer-q-a-item">
      <div className="offer-q-a-question">
        {(question && textGenerator({ name: fullName, text: question.body, date: createdAt })) ||
          false}
      </div>
      <div className="offer-q-a-answer">
        {(answer &&
          textGenerator({ name: 'Issuer', text: answer.body, date: answer.updatedAt || null })) ||
          false}
      </div>
    </div>
  </div>
)

QuestionAnswerItem.propTypes = {
  answer: T.shape({
    body: T.string,
    fullName: T.string,
    updatedAt: T.string,
  }),
  createdAt: T.string,
  fullName: T.string.isRequired,
  question: T.shape({
    body: T.string.isRequired,
  }).isRequired,
}
