import './progress.css'
import React from 'react'
import PropTypes from 'prop-types'

import { cn } from 'utils'
import { Money } from 'ui/money'

export const EnhancedProgressBar = ({ value: startedValue, max, finalGoal, className, raised }) => {
  const value = startedValue > max ? max : startedValue
  const isReachFinalGoal = value >= finalGoal
  const finalGoalWidth = 70
  const maxAmountWidth = Math.min(Math.round(((value - finalGoal) / (max - finalGoal)) * 30), 100)
  const progress = isReachFinalGoal
    ? finalGoalWidth
    : Math.min(Math.round((value / finalGoal) * finalGoalWidth), 100)
  const progressLabel = Math.round((startedValue / finalGoal) * 100)
  return (
    <div className={cn('progress', className)}>
      <div
        className={`progress-value ${isReachFinalGoal && 'reached'}`}
        style={{
          width: `${progress > 100 ? 100 : progress}%`,
        }}
      />
      {isReachFinalGoal && (
        <div
          className="progress-value-reached"
          style={{
            width: `${maxAmountWidth}%`,
          }}
        />
      )}
      <div
        className="goal-point-value"
        style={{
          left: `${finalGoalWidth}%`,
        }}
      />
      <div
        style={{
          left: `${finalGoalWidth}%`,
        }}
        className="goal-point-label"
      >
        <span className="min-amount-label">100%</span>
        <span className="min-amount-value">
          <Money amount={finalGoal} precision={0} /> min goal
        </span>
      </div>
      <div
        className="goal-point-value blue"
        style={{
          left: `100%`,
        }}
      />
      <div
        style={{
          left: `100%`,
        }}
        className="goal-point-label blue"
      >
        <span className="min-amount-value">
          <Money amount={max} precision={0} /> max goal
        </span>
      </div>
      {raised && (
        <span className={isReachFinalGoal ? 'raised raised100' : 'raised'}>
          {progressLabel}% raised
        </span>
      )}
    </div>
  )
}
EnhancedProgressBar.propTypes = {
  max: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
  finalGoal: PropTypes.number,
  raised: PropTypes.bool,
}
EnhancedProgressBar.defaultProps = {
  value: 0,
  max: 1,
  finalGoal: 200000,
  raised: false,
}
