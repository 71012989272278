import { call } from 'redux-saga/effects'
import { ACTIONS } from '.'
import { attachSideEffect } from '../utils'
import * as api from 'api'

export default function*() {
  yield attachSideEffect(ACTIONS.RECOVER_PASSWORD, onRecoverPassword)
}

/** @param {User} payload */
function* onRecoverPassword({ email }) {
  if (!email) throw { error: 'onRecoverPassword: missing email' }
  const result = yield call(api.user.postRecoveryPassword, { email })
  if (result) {
    return email
  }
}
