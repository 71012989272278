import { createSelector } from 'reselect'
import { WIRE, PAYMENT_METHODS, CREDIT_CARD } from 'const/types'

export const map = state => state.investments.byId

export const all = state =>
  (state.investments.byId && Object.values(state.investments.byId)) || null

const listIds = state => state.investments.listIds || null

export const total = state => state.investments.total
export const investmentsList = createSelector(
  map,
  listIds,
  (byId, listIds) => (listIds && byId && listIds.map(id => byId[id]).filter(Boolean)) || null,
)

export const mapByOfferingId = createSelector(
  all,
  all =>
    (all &&
      Object.fromEntries(
        all.map(investment => [investment.offeringUuid || investment.offering.uuid, investment]),
      )) ||
    null,
)

export const draftByOfferingId = (state, { uuid } = {}) => {
  const arr =
    uuid &&
    state.investments.byId &&
    Object.values(state.investments.byId)
      .filter(
        investment =>
          (investment.offeringUuid || investment.offering.uuid) === uuid && isDraft(investment),
      )
      .reduce((a, b) => (new Date(a.createdAt) > new Date(b.createdAt) ? a : b), [])
  if (Array.isArray(arr)) {
    return null
  }
  return arr || null
}

export const isDraftByOfferingId = createSelector(
  draftByOfferingId,
  investment => isDraft(investment),
)

export const hasPaymentMethodByOfferingId = createSelector(
  draftByOfferingId,
  investment =>
    investment && investment.paymentMethod && PAYMENT_METHODS.includes(investment.paymentMethod),
)

export const isWireByOfferingId = createSelector(
  draftByOfferingId,
  investment => isWire(investment),
)

export const isCreditCardByOfferingId = createSelector(
  draftByOfferingId,
  investment => isCreditCard(investment),
)

export const commitedOfferingIds = createSelector(
  all,
  all => {
    if (!all) return null
    const ids = all.map(investment => investment.offeringUuid || investment.offering.uuid)
    return ids.length ? ids : null
  },
)

export const agreement = state => state.investments.agreement

const DRAFT = 'draft'
const isDraft = investment => investment && investment.status === DRAFT
const isWire = investment => investment && investment.paymentMethod === WIRE
const isCreditCard = investment => investment && investment.paymentMethod === CREDIT_CARD
