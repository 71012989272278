import './label.css'
import React from 'react'
import T from 'prop-types'
import { cn } from 'utils'
import { Tooltip } from 'ui/tooltip'

export const LabelText = ({ className, label = false, tooltip, ...props }) => (
  <span {...props} className={cn('label-text', className)}>
    {label} {tooltip ? <Tooltip tooltip={tooltip} /> : null}
  </span>
)

LabelText.propTypes = {
  label: T.node,
  tooltip: T.node,
}

export const Label = ({ name, label, children, className, tooltip, ...props }) => (
  <label {...props} htmlFor={name} className={cn('label', className)}>
    {children}
    <LabelText label={label} tooltip={tooltip} />
  </label>
)

Label.propTypes = {
  label: T.node,
  name: T.string.isRequired,
  tooltip: T.node,
}
