import { createSelector } from 'reselect'

export const rawInvestor = state => state.investor

export const investor = createSelector(
  rawInvestor,
  ({ investor }) => investor || null,
)

export const investmentLimit = createSelector(
  investor,
  investor => (investor && investor.investmentLimit) || 0,
)

export const isInvestorCreated = createSelector(
  investor,
  investor => !!investor && (investor.annualIncome > 0 || investor.netWorth > 0),
)

export const totalActiveInvestors = createSelector(
  rawInvestor,
  ({ activeInvestorsAmount }) => activeInvestorsAmount || null,
)
