import pathToRegexp from 'path-to-regexp'

/** @enum */
export const routes = {
  landing: '/',

  // public
  offerDetails: '/offers/:uuid',

  offerDetailsOuckah: '/ouckah',
  offerDetailsOuckahLlc: '/ouckahLLC',
  offerDetailsGritSquad: '/GritSquad',
  offerDetailsNootropicsExpert: '/NootropicsExpert',
  offerDetailsStreetCoater: '/streettacoeater',
  offerDetailsTeamHold: '/teamhold',

  offerDetailsQuestion: '/offers/:uuid/question',
  about: '/about',
  learnMore: '/learn-more',
  privacyPolicy: '/privacy-policy',
  termsOfUse: '/terms-of-use',
  whyWork: '/why-work',
  studyDetails: '/study-details',

  // user
  login: '/login',

  // sign up
  signUp: '/signup',
  signUpAgreement: '/signup/agreement',
  signUpGoogle: '/signup/complete/google',
  signUpFacebook: '/signup/complete/facebook',

  // role = ADMIN + SUPERADMIN
  admin: '/admin',
  adminLanding: '/admin/landing',
  adminAbout: '/admin/about',
  adminLearnMore: '/admin/learn-more',
  adminTermsOfUse: '/admin/terms-of-use',
  adminPrivacyPolicy: '/admin/privacy-policy',
  adminIssuersList: '/admin/issuers-list',
  adminIssuerDetails: '/admin/issuers/:uuid',
  adminIssuerDetailsProfile: '/admin/issuers/:uuid/profile',
  adminIssuerDetailsInvestments: '/admin/issuers/:uuid/investments',
  adminIssuerDetailsDividends: '/admin/issuers/:uuid/dividends',
  adminInvestorsList: '/admin/investors',
  adminInvestorDetails: '/admin/investors/:uuid',
  adminUsersList: '/admin/admin-users',
  adminEmailsList: '/admin/emails',
  adminOfferDetails: '/admin/offers/:uuid',
  adminOfferCreate: '/admin/offer/create',
  adminOfferConfigure: '/admin/configure/:uuid',
  // role = INVESTOR
  investor: '/investor',
  settings: '/investor/settings',
  settingsAccount: '/investor/settings/account',
  settingsProfile: '/investor/settings/profile',
  settingsPayment: '/investor/settings/payment',
  settingsDocuments: '/investor/settings/documents',
  favorites: '/investor/favorites',
  notifications: '/investor/notifications',
  // investments: '/investor/investments',
  addInvestment: '/investor/invest/:uuid',
  addInvestmentStep0: '/investor/invest/:uuid/0',
  addInvestmentStep1: '/investor/invest/:uuid/1',
  addInvestmentStep2: '/investor/invest/:uuid/2',
  addInvestmentStep3: '/investor/invest/:uuid/3',
  addInvestmentStep3Ach: '/investor/invest/:uuid/3/ach',
  addInvestmentStep3Wire: '/investor/invest/:uuid/3/wire',
  addInvestmentStep3CreditCard: '/investor/invest/:uuid/3/credit-card',
  addInvestmentStep4: '/investor/invest/:uuid/4',
  myInvestments: '/investor/my-investments',
  myInvestmentCommitted: '/investor/my-investments/committed',
  myInvestmentInvested: '/investor/my-investments/invested',
  myInvestmentSaved: '/investor/my-investments/saved',
  myInvestmentWireInstruction: '/investor/my-investments/committed/:uuid/wire',
  thankYouPage: '/investor/invest/:uuid/thank-you',

  // role = ISSUER
  issuer: '/issuer',
  issuerLogin: '/issuer-login',
  issuerLoginStep1: '/issuer-login/1',
  issuerLoginStep2: '/issuer-login/2',
  issuerLoginStep3: '/issuer-login/3',
  issuerLoginStep4: '/issuer-login/4',
  issuerLoginConfirm: '/issuer-login/confirm',
  issuerQuestionAnswer: '/issuer/question-answer',
  issuerInvestments: '/issuer/investments',
  issuerSettings: '/issuer/settings',
  issuerSettingsAgreements: '/issuer/settings/agreements',
  issuerSettingsAccount: '/issuer/settings/account',
  issuerSettingsCompanyInfo: '/issuer/settings/company-info',
  issuerSettingsOffering: '/issuer/settings/offering',
  issuerDocumentById: 'hellosign/signed-document/',
  issuerPendingApproval: '/issuer/pending-approval',
  issuerFourthStep: '/issuer/fourth-step',

  passwordRecovery: '/password-recovery',
  passwordRecoveryCongrats: '/password-recovery/congrats',
  passwordReset: '/password-reset',
  //material-change
  materialChangeConfirm: '/material-change/:uuid/confirm',
  materialChangeCancel: '/material-change/:uuid/cancel',
}

export const offerDetailsRoute = pathToRegexp.compile(routes.offerDetails)
export const offerDetailsOuckahRoute = pathToRegexp.compile(routes.offerDetailsOuckah)
export const offerDetailsOuckahLlcRoute = pathToRegexp.compile(routes.offerDetailsOuckahLlc)
export const offerDetailsGritSquadRoute = pathToRegexp.compile(routes.offerDetailsGritSquad)
export const offerDetailsNootropicsExpertRoute = pathToRegexp.compile(
  routes.offerDetailsNootropicsExpert,
)
export const offerDetailsTeamHoldRoute = pathToRegexp.compile(routes.offerDetailsTeamHold)

export const adminOfferDetailsRoute = pathToRegexp.compile(routes.adminOfferDetails)
export const adminOfferConfigureRoute = pathToRegexp.compile(routes.adminOfferConfigure)
export const adminOfferCreateRoute = pathToRegexp.compile(routes.adminOfferCreate)
export const materialChangeConfirmRoute = pathToRegexp.compile(routes.materialChangeConfirm)
export const materialChangeCancelRoute = pathToRegexp.compile(routes.materialChangeCancel)
export const offerDetailsQuestionRoute = pathToRegexp.compile(routes.offerDetailsQuestion)
export const addInvestmentRoute = pathToRegexp.compile(routes.addInvestment)
export const addInvestmentStep0Route = pathToRegexp.compile(routes.addInvestmentStep0)
export const addInvestmentStep1Route = pathToRegexp.compile(routes.addInvestmentStep1)
export const addInvestmentStep2Route = pathToRegexp.compile(routes.addInvestmentStep2)
export const addInvestmentStep3Route = pathToRegexp.compile(routes.addInvestmentStep3)
export const addInvestmentStep3AchRoute = pathToRegexp.compile(routes.addInvestmentStep3Ach)
export const addInvestmentStep3WireRoute = pathToRegexp.compile(routes.addInvestmentStep3Wire)
export const addInvestmentStep3CreditCardRoute = pathToRegexp.compile(
  routes.addInvestmentStep3CreditCard,
)
export const addInvestmentStep4Route = pathToRegexp.compile(routes.addInvestmentStep4)
export const thankYouPageRoute = pathToRegexp.compile(routes.thankYouPage)
export const myInvestmentWireInstructionRoute = pathToRegexp.compile(
  routes.myInvestmentWireInstruction,
)

export const adminIssuerDetailsRoute = pathToRegexp.compile(routes.adminIssuerDetails)
export const adminIssuerDetailsProfileRoute = pathToRegexp.compile(routes.adminIssuerDetailsProfile)
export const adminIssuerDetailsInvestmentsRoute = pathToRegexp.compile(
  routes.adminIssuerDetailsInvestments,
)
export const adminIssuerDetailsDividentsRoute = pathToRegexp.compile(
  routes.adminIssuerDetailsDividends,
)

export const adminInvestorDetailsRoute = pathToRegexp.compile(routes.adminInvestorDetails)

export const issuerLoginStep1Route = pathToRegexp.compile(routes.issuerLoginStep1)
export const issuerLoginStep2Route = pathToRegexp.compile(routes.issuerLoginStep2)
export const issuerLoginStep3Route = pathToRegexp.compile(routes.issuerLoginStep2)
export const issuerLoginStep4Route = pathToRegexp.compile(routes.issuerLoginStep4)
export const issuerLoginConfirmRoute = pathToRegexp.compile(routes.issuerLoginConfirm)
