import React from 'react'
import PropTypes from 'prop-types'
import CleaveInput from 'cleave.js/react'
import { BaseField } from './base-field'

export class Input extends BaseField {
  static propTypes = {
    ...BaseField.propTypes,
    ...CleaveInput.propTypes,
    autoComplete: PropTypes.string,
    disabled: PropTypes.bool,
    name: PropTypes.string.isRequired,
    onBlur: PropTypes.func,
    onValidate: PropTypes.func.isRequired,
    validation: PropTypes.func,
  }
  static defaultProps = {
    type: 'text',
    autoComplete: 'off',
  }

  /** @type {HTMLInputElement} */
  element = null

  setElement = element => {
    // console.log('setElement', this.props.name)
    this.element = element
  }

  cleaveInstance = null
  setCleaveInstance = instance => {
    // console.log('setCleaveInstance', this.props.name)
    this.cleaveInstance = instance
    this.setValue(this.getDefaultValue())
    this.broadcastUpdates()
  }

  onBlur = e => {
    this.validate()
    this.props.onBlur && this.props.onBlur(e)
  }

  onChange = event => {
    const { rawValue, value } = event.target
    // let the parent form knows about changes
    if (this.getNormalizedValue(value) === null) {
      this.broadcastUpdates({ value: null })
    } else {
      this.broadcastUpdates({ value: rawValue })
    }
    // after the first blur event / when user tried to submit
    this.state.touched && this.validate()
  }

  reset() {
    this.setState({ touched: false }, () => {
      this.setValue(this.getDefaultValue())
    })
  }

  setValue(value) {
    if (!this.cleaveInstance) return
    const normalValue = this.getNormalizedValue(value)
    if (normalValue === null || normalValue === undefined) {
      this.element.value = ''
    } else {
      this.cleaveInstance.setRawValue(normalValue)
    }
    this.state.touched && this.broadcastUpdates({ value: normalValue })
  }

  /**
   * @override
   */
  getValue() {
    return this.getNormalizedValue(
      this.cleaveInstance ? this.cleaveInstance.getRawValue() : undefined,
    )
  }

  getNormalizedValue(value) {
    if (value === undefined || value === null) {
      return null
    }
    if (typeof value === 'string' && String(value).trim() === '') {
      return null
    }

    if (this.props.options.numeral) {
      return typeof value === 'number' ? value : parseFloat(value.replace(/[^0-9.]/g, ''), 10)
    }

    return value
  }

  getDefaultValue(defaultValue = this.props.defaultValue) {
    return this.getNormalizedValue(defaultValue)
  }

  getValidationMessage(value = this.getValue()) {
    if (!this.willValidate()) return null

    const { required, validation } = this.props
    if (value === null) {
      return required ? 'The field is required' : null
    }
    return (validation && validation(value)) || null
  }

  broadcastUpdates({
    value = this.getValue(),
    enable = this.willValidate(),
    validationMessage = this.getValidationMessage(value),
    defaultValue = this.getDefaultValue(),
  } = {}) {
    // console.log('broadcast', { value, enable, validationMessage, defaultValue, valid: !validationMessage })
    return super.broadcastUpdates({
      value: this.getNormalizedValue(value),
      valid: !validationMessage,
      enable,
      validationMessage,
      defaultValue: this.getNormalizedValue(defaultValue),
    })
  }

  validate() {
    this.state.touched
      ? this.broadcastValidation({ message: this.getValidationMessage() })
      : this.setState({ touched: true }, () =>
          this.broadcastValidation({ message: this.getValidationMessage() }),
        )
  }

  componentDidUpdate(prev) {
    const { defaultValue } = this.props
    if (prev && prev.defaultValue !== this.props.defaultValue) {
      this.setValue(defaultValue)
    }
  }

  render() {
    const { disabled, validation, onValidate, defaultValue, value, ...props } = this.props
    const { pending } = this.context
    return (
      <CleaveInput
        {...props}
        htmlRef={this.setElement}
        onInit={this.setCleaveInstance}
        id={props.id || props.name}
        onBlur={this.onBlur}
        onChange={this.onChange}
        disabled={pending || disabled}
        // value={defaultValue} // yes, that is correct :(
      />
    )
  }
}
