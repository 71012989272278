import './field.css'
import './select.css'
import React from 'react'
import T from 'prop-types'
import { Select as SelectInput } from '../form'
import { ValidationMessage } from '../validation-message'
import { cn } from 'utils'
import IconDown from 'icons/down.svg'

import { Label } from './Label'
export class Select extends React.PureComponent {
  static propTypes = {
    ...Label.propTypes,
    hideValidationMessage: T.bool,
    selectClassName: T.string,
  }
  static defaultProps = {
    hideValidationMessage: false,
    selectClassName: 'input',
  }
  state = {
    touched: false,
    validationMessage: null,
  }
  select = React.createRef()

  onValidate = ({ message, valid, name }) => {
    this.setState({ validationMessage: valid ? null : message, touched: true }, () => {
      this.props.onValidate && this.props.onValidate({ message, valid, name })
    })
  }
  set value(value) {
    if (this.select.current) this.select.current.value = value
  }
  get value() {
    return this.select.current ? this.select.current.value : undefined
  }

  render() {
    const {
      label,
      hideValidationMessage,
      className,
      selectClassName,
      children,
      tooltip,
      ...props
    } = this.props
    const { validationMessage, touched } = this.state
    const showValidity = !this.props.hideValidationMessage && !!validationMessage
    return (
      <Label
        label={label}
        name={props.name}
        tooltip={tooltip}
        className={cn('field box-field select select-container', className)}
      >
        {children}
        <SelectInput
          {...props}
          className={cn(selectClassName, touched && 'touched')}
          onValidate={this.onValidate}
          ref={this.select}
        />
        <span className="input-addon">
          <IconDown className="select-icon" />
        </span>
        {showValidity && (
          <ValidationMessage className="message" error>
            {validationMessage}
          </ValidationMessage>
        )}
      </Label>
    )
  }
}
