import React from 'react'
import T from 'prop-types'
import { FormContext } from './context'

export class BaseField extends React.PureComponent {
  static propTypes = {
    defaultValue: T.any,
    name: T.string,
    // disabled: T.bool,
    onValidate: T.func,
    required: T.bool,
    // readOnly: T.bool,
    validation: T.func,
  }
  static defaultProps = {
    // disabled: false,
    // readOnly: false,
  }

  static contextType = FormContext

  state = {
    touched: false,
  }

  recentState = {
    value: undefined,
    valid: undefined,
    enable: undefined,
    validationMessage: undefined,
    defaultValue: undefined,
  }

  componentDidUpdate(prevProps) {
    if (prevProps && this.props.defaultValue !== prevProps.defaultValue) {
      this.setValue(this.props.defaultValue)
    } else {
      this.broadcastUpdates()
    }
  }

  componentWillUnmount() {
    this.broadcastUpdates({ enable: false })
  }

  /**
   * @param {*} [value]
   * @returns {String}
   */
  checkCustomValidity(value = this.getValue()) {
    const { validation } = this.props
    return (
      (validation && validation(value, { ...this.context.values, [this.props.name]: value })) || ''
    )
  }

  /**
   * Returns true when field must be included into form validation
   * @param {Object} [props]
   * @param {Boolean} props.disabled
   * @param {Boolean} props.readOnly
   * @param {String} props.name
   * @param {Boolean}
   */
  willValidate({ disabled, name } = this.props) {
    return !!name && !disabled
  }

  /**
   * @abstract
   */
  setValue(value) {
    this.broadcastUpdates({ value })
  }

  /**
   * @abstract
   */
  getValue() {
    return this.context.values[this.props.name]
  }

  /**
   * Trigger native validation
   * Sets <state.touched> = true
   */
  validate() {
    this.state.touched
      ? this.broadcastValidation()
      : this.setState({ touched: true }, () => {
          this.broadcastValidation()
        })
  }

  /**
   * @abstract
   * @param {*} value
   * @returns {boolean}
   */
  isValid(value = this.getValue()) {
    if (!this.willValidate()) return true
    if (this.props.required) {
      return value !== null && value !== undefined && value !== ''
    }
    return true
  }

  /**
   * @protected
   * @param {BroadcastUpdatesConfig} config
   */
  broadcastUpdates({
    value = this.getValue(),
    valid = this.isValid(value),
    enable = this.willValidate(),
    validationMessage = this.getValidationMessage(),
    defaultValue = this.props.defaultValue,
  } = {}) {
    if (!this.context.statusListener) {
      throw new Error(`Missing statusListener`)
    }
    if (
      value !== this.recentState.value ||
      valid !== this.recentState.valid ||
      enable !== this.recentState.enable ||
      validationMessage !== this.recentState.validationMessage ||
      defaultValue !== this.recentState.defaultValue
    ) {
      const { name } = this.props
      this.recentState = {
        value: value === '' ? null : value,
        valid,
        enable,
        validationMessage,
        defaultValue,
      }

      this.context.statusListener({ ...this.recentState, name, ref: this })
      this.state.touched &&
        this.broadcastValidation({ message: validationMessage, valid, name, defaultValue })
    }
  }

  /**
   * @protected
   */
  getValidationMessage() {
    if (!this.willValidate() || this.isValid()) return null
    const { required } = this.props
    const value = this.getValue()
    if (value === null) {
      return required ? 'The field is required' : null
    }
    return null
  }

  /**
   * @protected
   * @param {BroadcastValidationConfig} config
   */
  broadcastValidation({ message = null, valid = this.isValid(), name = this.props.name } = {}) {
    const { onValidate } = this.props
    if (!onValidate) return
    onValidate(this.willValidate() ? { message, valid, name, enabled: true } : { enabled: false })
  }

  reset() {
    // console.log('reset:', this.props.name)
    const { defaultValue } = this.props
    const value = defaultValue === undefined ? null : defaultValue
    this.broadcastUpdates({
      value,
      touched: false,
    })
  }
}

/**
 * @typedef {Object} BroadcastUpdatesConfig
 * @prop {*} value - current user input (inc. invalid bullshit)
 * @prop {Boolean} [valid]
 * @prop {String} [name]
 * @prop {Boolean} [enable]
 */

/**
 * @typedef {Object} BroadcastValidationConfig
 * @prop {String} [message=null]
 * @prop {Boolean} [valid]
 * @prop {String} [name]
 */
