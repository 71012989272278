import { compoundActionType, compoundErrorActionCreator } from '../utils'
import {AUTH} from "../auth";

export const REGISTER_ISSUER = {
  HS_CONFIRM: compoundActionType('register-issuer.hellosign-confirm'),
  GET_CONTACT: compoundActionType('register-issuer.get-contact'),
  POST_CONTACT: compoundActionType('register-issuer.post-contact'),
  PATCH_CONTACT:compoundActionType('register-issuer.patch-contact'),
}

/** @returns {Action} */
export const hsConfirm = compoundErrorActionCreator(REGISTER_ISSUER.HS_CONFIRM)
export const getContact = compoundErrorActionCreator(REGISTER_ISSUER.GET_CONTACT)
export const postContact = compoundErrorActionCreator(REGISTER_ISSUER.POST_CONTACT)
export const patchContact = compoundErrorActionCreator(REGISTER_ISSUER.PATCH_CONTACT)

const NO_ERRORS = {}

export const initialState = {
  pending: false,
  error: null,
  errors: NO_ERRORS,
  contact: null
}

/**
 * @param {Auth} state
 * @param {Action} action
 * @returns {Auth}
 */
export default (state = initialState, { type, payload = {} }) => {
  switch (type) {
    case REGISTER_ISSUER.HS_CONFIRM.START:
    case REGISTER_ISSUER.GET_CONTACT.START:
    case REGISTER_ISSUER.POST_CONTACT.START:
      return {
        ...state,
        pending: true,
        error: null,
        errors: NO_ERRORS,
      }
    case REGISTER_ISSUER.HS_CONFIRM.SUCCESS:
      return {
        ...state,
        pending: false,
      }
    case REGISTER_ISSUER.GET_CONTACT.SUCCESS:
    case REGISTER_ISSUER.POST_CONTACT.SUCCESS:
      return {
        ...state,
        pending: false,
        contact: payload
      }
    case REGISTER_ISSUER.HS_CONFIRM.FAILURE:
    case REGISTER_ISSUER.GET_CONTACT.FAILURE:
    case REGISTER_ISSUER.POST_CONTACT.FAILURE:
      return {
        ...state,
        pending: false,
        error: payload.error || null,
        errors: payload.errors || NO_ERRORS,
      }
    case AUTH.LOGOUT.SUCCESS:
      return initialState
    default:
      return state
  }
}
