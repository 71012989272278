import React from 'react'
import download from '../../download/formc.pdf'
import PropTypes from 'prop-types'
import { ContentViewer } from 'components/content-viewer'
export const OfferReturnInvest = ({ uuid, text, id, offeringFormC }) => (
  <div className="offer-inner-block" id={id}>
    <div className="container">
      <h2 className="mb-50">How will supporters make returns on investment?</h2>
      <ContentViewer contentKey={`offer-details/investment-returns${uuid}`} defaultContent={text} />
      <p>
        <a href={offeringFormC ? offeringFormC : download} download className="color-blue">
          {'Read Offering Filing document'}
        </a>
      </p>
    </div>
  </div>
)

OfferReturnInvest.propTypes = {
  uuid: PropTypes.string,
  text: PropTypes.string,
}
