import React from 'react'
import { ContentEditor } from 'components/content-editor'
import { DefaultText } from 'pages/static/learn-more'
import './admin-learn-more.css'
import { useHistory } from 'react-router-dom'

export const AdminLearnMore = () => {
  const { location } = useHistory()

  const filtered = DefaultText.filter(elem =>
    location.hash.includes(elem.header.toLowerCase().replaceAll(' ', '-')),
  )
  const isIncludes = filtered.length ? filtered : [DefaultText[0]]
  const filteredDefault = isIncludes
    .map(elem => {
      const paragraphText = elem.text.map(item => ({ type: 'paragraph', data: { text: item } }))
      const result = [{ type: 'header', data: { text: elem.header, level: 2 } }, ...paragraphText]
      if (elem.list) {
        result.push({ type: 'list', data: { items: elem.list } })
      }
      return result
    })
    .flat()
  const contentKey = location.hash.includes('#') ? location.hash.slice(1) : 'educational-materials'

  return (
    <div className="container static-page learn-more-admin">
      <ContentEditor
        contentKey="learn-more-admin-headerz"
        defaultContent={{ blocks: [{ type: 'list', data: { items: DefaultHeaders } }] }}
        className="admin-headers-con"
      />

      <ContentEditor
        key={contentKey}
        className="admin-learn-more"
        contentKey={`learn-more-admin_${contentKey}`}
        defaultContent={{ blocks: filteredDefault }}
      />
    </div>
  )
}

export const DefaultHeaders = [
  '<a href="" target="_self">Educational Materials</a>',
  '<a href="#how-it-works" target="_self">How It Works</a>',
  '<a href="#how-we-select-creators" target="_self">How We Select Creators</a>',
  '<a href="#our-fees-for-services" target="_self">Our Fees For Services</a>',
  '<a href="#types-of-securities" target="_self">Types of Securities</a>',
  '<a href="#creator-related-revenue" target="_self">Creator-Related Revenue</a>',
  '<a href="#creator-agreement" target="_self">Creator Agreement</a>',
  '<a href="#post-offering-relationship" target="_self">Post Offering Relationship</a>',
  '<a href="#selling-restrictions" target="_self">Selling Restrictions</a>',
  '<a href="#cancellation-policy" target="_self">Cancellation Policy</a>',
  '<a href="#investment-amount-restrictions" target="_self">Investment Amount Restrictions</a>',
  '<a href="#risks" target="_self">Risks</a>',
]
