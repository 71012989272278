import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { withRouter } from 'react-router-dom'

import { search, offers } from 'store/offers'
import { SearchOptions } from './SearchOptions'
import { SearchResultsList } from './SearchResultsList'
import { RouteProps } from 'const/types'
import { getListParams } from 'utils/url'

//TODO: put back Load More
// import { Button } from 'ui/button'

export const PureSearch = ({ doSearch, location }) => {
  const { page, isOpen, region, limit, search } = getListParams(location.search)

  useEffect(() => {
    doSearch({
      limit,
      page,
      isOpen,
      region,
      search,
    })
  }, [doSearch, page, isOpen, region, search, limit])

  return (
    <div className="tg-search">
      <SearchOptions />
      <SearchResultsList />
      {/*{hasMoreItems && (*/}
      {/*  <div className="row justify-content-center mb-40">*/}
      {/*    <Button disabled={isSearching} className="btn" onClick={getMoreSearchResults}>*/}
      {/*      Load more*/}
      {/*    </Button>*/}
      {/*  </div>*/}
      {/*)}*/}
    </div>
  )
}

PureSearch.propTypes = {
  doSearch: PropTypes.func.isRequired,
  location: RouteProps.location,

  //getSearchResults: PropTypes.func.isRequired,
  //hasMoreItems: PropTypes.bool.isRequired,
  //isSearching: PropTypes.bool.isRequired,
}

export const Search = withRouter(
  connect(
    createStructuredSelector({
      hasMoreItems: offers.hasMoreItems,
      isSearching: offers.isSearching,
    }),
    { doSearch: search },
  )(PureSearch),
)
