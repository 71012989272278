import { compoundActionType, compoundErrorActionCreator } from '../utils'
import * as investor from './selectors'
import { AUTH } from 'store/auth'

export { investor }

export const INVESTOR = {
  GET_ACTIVE_INVESTORS_AMOUNT: compoundActionType('investor.get-active-investors-amount'),
  GET_INVESTOR: compoundActionType('investor.get-investor'),
  UPDATE_INVESTOR: compoundActionType('investor.update-investor'),
}

export const getInvestor = compoundErrorActionCreator(INVESTOR.GET_INVESTOR)
export const updateInvestor = compoundErrorActionCreator(INVESTOR.UPDATE_INVESTOR)
export const getActiveInvestorsAmount = compoundErrorActionCreator(
  INVESTOR.GET_ACTIVE_INVESTORS_AMOUNT,
)

export const initialState = {
  investor: null,
  pending: false,
  error: null,
  activeInvestorsAmount: null,
}

export default function(state = initialState, { type, payload }) {
  switch (type) {
    case INVESTOR.GET_INVESTOR.START:
    case INVESTOR.UPDATE_INVESTOR.START:
      return {
        ...state,
        pending: true,
        error: null,
      }
    case INVESTOR.GET_INVESTOR.SUCCESS:
      return {
        ...state,
        investor: { ...payload },
        pending: false,
      }
    case INVESTOR.UPDATE_INVESTOR.SUCCESS:
      return {
        ...state,
        investor: { ...payload },
        pending: false,
      }
    case INVESTOR.GET_INVESTOR.FAILURE:
    case INVESTOR.UPDATE_INVESTOR.FAILURE:
      return {
        ...state,
        pending: false,
        error: payload.error,
      }
    case INVESTOR.GET_ACTIVE_INVESTORS_AMOUNT.SUCCESS:
      return {
        ...state,
        activeInvestorsAmount: payload,
      }
    case AUTH.LOGOUT.SUCCESS:
      return initialState

    default:
      return state
  }
}
