import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Prompt } from 'react-router-dom'

const handleUnload = (message, event) => {
  event.preventDefault()
  event.returnValue = message
}

export const PreventNavigationDialog = ({ message }) => {
  useEffect(() => {
    const onUnload = event => handleUnload(message, event)
    window.addEventListener('beforeunload', onunload)
    return () => {
      window.removeEventListener('beforeunload', onUnload)
    }
  }, [message])
  return <Prompt message={message} />
}

PreventNavigationDialog.propTypes = {
  message: PropTypes.string.isRequired,
}
