import React from 'react'
import T from 'prop-types'
import './tooltip.css'
import { DropdownToggle } from '../dropdown-toggle'

export const Tooltip = ({ tooltip, children, ...props }) => (
  <DropdownToggle {...props} className="tooltip" icon={null}>
    <span className="tooltip-text">{tooltip || children}</span>
  </DropdownToggle>
)

Tooltip.propTypes = {
  tooltip: T.string.isRequired,
}
