import { compoundActionType, compoundErrorActionCreator } from '../utils'

export const REGISTER = {
  CREATE_USER: compoundActionType('register.create-user'),
}

/** @returns {Action} */
export const createUser = compoundErrorActionCreator(REGISTER.CREATE_USER)

const NO_ERRORS = {}

export const initialState = {
  pending: false,
  error: null,
  errors: NO_ERRORS,
}

/**
 * @param {Auth} state
 * @param {Action} action
 * @returns {Auth}
 */
export default (state = initialState, { type, payload = {} }) => {
  switch (type) {
    case REGISTER.CREATE_USER.START:
      return {
        ...state,
        pending: true,
        error: null,
        errors: NO_ERRORS,
      }
    case REGISTER.CREATE_USER.SUCCESS:
      return {
        ...state,
        pending: false,
      }
    case REGISTER.CREATE_USER.FAILURE:
      return {
        ...state,
        pending: false,
        error: payload.error || null,
        errors: payload.errors || NO_ERRORS,
      }

    default:
      return state
  }
}
