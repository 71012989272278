import { logSuccessGroup, logErrorGroup } from './logger'

// eslint-disable-next-line no-undef
const BASE_URL = process.env.API_PATH || '/'

const DEFAULT_REQUEST_CONFIG = {
  headers: {
    Accept: 'application/json',
  },
  credentials: 'omit',
}

const AUTH_ERROR_CODES = [401, 403, 409, 407]
const VALIDATION_ERROR_CODES = [400, 422, 500]
const GENERIC_NETWORK_ERROR = 'Something went wrong'
const GENERIC_VALIDATION_ERROR = 'Unable to Proceed'

export const getEndpointUrl = endpoint => {
  console.assert(!!BASE_URL, 'BASE_URL is ' + BASE_URL) //eslint-disable-line
  return BASE_URL + endpoint
}

export const get = async (endpoint, params = {}, config = {}) => {
  const url = getEndpointUrl(endpoint) + toSearchParamsString(params)
  const fetchConfig = mergeRequestConfigs(DEFAULT_REQUEST_CONFIG, config, { method: 'get' })
  try {
    const result = await doFetch(url, fetchConfig)
    logSuccessResponse({ config: fetchConfig, url, endpoint, params, result })
    return result
  } catch (errorObject) {
    logErrorResponse({ error: errorObject, config: fetchConfig, url, endpoint })
    return Promise.reject(errorObject)
  }
}

export const getBlob = async (endpoint, params = {}, config = {}) => {
  const url = getEndpointUrl(endpoint) + toSearchParamsString(params)
  const fetchConfig = mergeRequestConfigs(DEFAULT_REQUEST_CONFIG, config, { method: 'get' })
  try {
    const result = await fetch(url, fetchConfig)
      .then(res => {
        let fileName = 'agreement.pdf'
        const ContentDisposition = res.headers.get('Content-Disposition')
        if (ContentDisposition) {
          fileName = ContentDisposition.split('filename=')[1].replace(/"/g, '')
        }
        res.blob().then(blob => {
          const url = window.URL.createObjectURL(blob)
          const a = document.createElement('a')
          a.href = url
          a.download = fileName
          document.body.appendChild(a) // we need to append the element to the dom -> otherwise it will not work in firefox
          a.click()
          a.remove()
        })
      })
    logSuccessResponse({ config: fetchConfig, url, endpoint, params, result })
    return result
  } catch (errorObject) {
    logErrorResponse({ error: errorObject, config: fetchConfig, url, endpoint })
    return Promise.reject(errorObject)
  }
}

export const remove = async (endpoint, id, config = {}) => {
  const url = [getEndpointUrl(endpoint), id].join('/')
  const fetchConfig = mergeRequestConfigs(DEFAULT_REQUEST_CONFIG, config, { method: 'delete' })
  try {
    const result = await doFetch(url, fetchConfig)
    logSuccessResponse({ config: fetchConfig, url, endpoint, result })
    return result
  } catch (errorObject) {
    logErrorResponse({ error: errorObject, config: fetchConfig, url, endpoint })
    throw errorObject
  }
}

export const post = async (endpoint, data = {}, config = { headers: {} }) => {
  const url = getEndpointUrl(endpoint)

  const fetchConfig = mergeRequestConfigs(
    DEFAULT_REQUEST_CONFIG,
    {
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
    },
    config,
    {
      body: JSON.stringify(data),
    },
  )
  try {
    const result = await doFetch(url, fetchConfig)
    logSuccessResponse({ config: fetchConfig, url, endpoint, result })
    return result
  } catch (errorObject) {
    logErrorResponse({ error: errorObject, config: fetchConfig, url, endpoint })
    throw errorObject
  }
}

export const postFormData = async (endpoint, data = {}, config = { headers: {} }) => {
  const formData = new FormData()
  Object.entries(data).forEach(([name, value]) => formData.append(name, value))
  const url = getEndpointUrl(endpoint)
  const fetchConfig = mergeRequestConfigs(
    DEFAULT_REQUEST_CONFIG,
    {
      method: 'post',
      cors: true,
    },
    config,
    {
      body: formData,
    },
  )
  try {
    const result = await doFetch(url, fetchConfig)
    logSuccessResponse({ config: fetchConfig, url, endpoint, result })
    return result
  } catch (errorObject) {
    logErrorResponse({ error: errorObject, config: fetchConfig, url, endpoint })
    throw errorObject
  }
}

export const patch = (endpoint, data = {}, config = { headers: {} }) =>
  post(endpoint, data, { ...config, method: 'PATCH' })

function toSearchParamsString(data = {}) {
  if (!Object.entries(data).length) {
    return ''
  }
  const searchParams = new URLSearchParams()
  for (let [name, value] of Object.entries(data)) {
    searchParams.set(name, value)
  }
  const searchParamsString = searchParams.toString()
  return '?' + searchParamsString
}

async function doFetch(url, config) {
  /** @type {Response} */
  let response
  let responseText
  let body = {}
  try {
    response = await fetch(url, config)

    // we need to make sure that response body is not empty before parsing it
    responseText = await response.text()
    if (responseText) {
      body = JSON.parse(responseText)
    }
  } catch (error) {
    return Promise.reject({
      error: `Parsing server response error: ${error}`,
      body: responseText,
      response,
    })
  }

  // ok
  if (response.ok) {
    return Promise.resolve(body)
  }

  // auth
  if (AUTH_ERROR_CODES.includes(response.status)) {
    return Promise.reject({
      error: parseAuthError(body),
      response,
      body,
      unauthorized: true,
    })
  }

  // validation error
  if (VALIDATION_ERROR_CODES.includes(response.status)) {
    return Promise.reject({
      error: parseValidationError(body),
      errors: parseValidationErrors(body),
      response,
      body,
    })
  }

  // network error
  return Promise.reject({
    error: (response && response.statusText) || GENERIC_NETWORK_ERROR,
    errors: parseValidationErrors(body),
    response,
    body,
  })
}

/**
 * @param {{title:String,source:String,detail:String}[]} errors
 * @returns {Object.<String,String>|null}
 */
function parseValidationErrors({ errors } = {}) {
  if (!errors) return null
  if (typeof errors === 'string') {
    try {
      errors = JSON.parse(errors)
    } catch (e) {
      // nothing
    }
  }

  return Object.entries(errors).reduce(
    (result, [name, error]) => ({
      ...result,
      [name]: arrayToString(error),
    }),
    {},
  )
}

function parseValidationError(body = {}) {
  const er = Object.entries(body)
    .filter(([key, value]) => key.includes('Error'))
    .flat()

  if (body && typeof body.error === 'string' && !body.error_description) {
    return body.error
  }
  if (body && typeof body.error_description === 'string') {
    return body.error_description
  }
  if (body && typeof body.message === 'string') {
    return body.message
  }
  if (body && typeof body.detail === 'string') {
    return body.detail
  }
  if (er && typeof er[1] === 'string') {
    return er[1]
  }
  if (body && typeof body.statusDesc === 'string') {
    return body.statusDesc
  }
  try {
    return arrayToString(Object.values(parseValidationErrors(body)))
  } catch (e) {
    return GENERIC_VALIDATION_ERROR
  }
}

function parseAuthError(body = {}) {
  return (body && body.error_description) || (body && body.detail) || GENERIC_VALIDATION_ERROR
}

/**
 * @param  {...RequestInit} configs
 * @returns {RequestInit}
 */
function mergeRequestConfigs(...configs) {
  return configs.reduce(
    (result, config) => ({
      ...result,
      ...config,
      headers: {
        ...(result.headers || {}),
        ...(config.headers || {}),
      },
    }),
    {},
  )
}

/**
 * @param  {RequestInit} config
 * @returns {RequestInit}
 */
export function withBearerAuth(token, config = {}) {
  return mergeRequestConfigs(config, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

function arrayToString(value) {
  return typeof value === 'string'
    ? value
    : Array.isArray(value)
    ? arrayToString(value[0])
    : undefined
}

function logSuccessResponse({ endpoint, url, config = {}, params, result } = {}) {
  try {
    logSuccessGroup(endpoint, {
      Request: [config.method.toUpperCase(), ' "', url, '"'].join(''),
      ...(params
        ? { 'Request Params': params }
        : config.body
        ? { 'Request Body': JSON.stringify(config.body) }
        : {}),
      Response: result,
    })
  } catch (e) {
    // just ignore
  }
}

function logErrorResponse({
  endpoint,
  url,
  config = {},
  error: { error, errors, body, response } = {},
} = {}) {
  try {
    logErrorGroup(endpoint, {
      Request: [config.method.toUpperCase(), ' "', url, '"'].join(''),
      Data: config.body,
      Headers: config.headers,
      Response: `${response.status} ${response.statusText}`,
      'Response Body': body,
      'Error Message': error,
      'Validation Errors': errors,
    })
  } catch (e) {
    // just ignore
  }
}
