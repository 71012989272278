export const SEARCH_PARAM = 'search'
export const LIMIT_PARAM = 'limit'

export const LIMIT_OPTIONS = {
  15: '15',
  25: '25',
  50: '50',
  100: '100',
}
export const DEFAULT_LIMIT = '15'
export const DEFAULT_PAGE = 1
export const OFFSET_PARAM = 'page'

export const ORDER_BY_PARAM = 'sort'
export const DEFAULT_ORDER = 'asc'

export const NO_ITEMS = 'There are no items yet'

export const DEFAULT_TEXT_FOR_REDACTOR =
  '<p>Supporters, in aggregate, will receive <b style="font-size: 18px;">13.50%</b> of all creator revenue for a period of <b style="font-size: 18px;">3</b> years. See below for more details.</p>\n<p>The creator at the center of this company can choose to end their career as creator at any point after the funded time period, even if no revenue has been generated. Your individual share of these annual payouts will be equal to the percentage your investment comprises of the total invested capital in this offering (i.e. the amount invested by all investors at the end of the offering, if the goal is successfully met).</p>\n<p>The supporters of the company will be paid via an annual distribution and will receive a K-1 for tax purposes.</p>\n<p>Team Hold Creator LLC is a Delaware registered business. International investors are responsible for abiding by the tax laws of your country as well as the United States.</p>\n<p>Creator revenue includes:</p>\n<p>- Ad share revenue from hosting platforms (YouTube/Instagram/Facebook/TikTok) hosting content.</p>\n<p>- Brand partnerships that are featured on the creator channels.</p>\n<p>- Affiliate links featured on creator channels. </p>\n<p>- Merchandise sales using creator content/branding/imaging.</p>\n<p>- Donations or tips received in connection to creator activies (eg. Patreon, YouTube tips, etc.)</p>\n<p>- Speaking engagement payments/honorariums related to content made by the creator.</p>'

export const DEFAULT_INVESTMENT_WILL_BE_USED = [
  ['Editor and graphics contractor', '$15,000'],
  ['Live in-person events like NBA Summer League meetup', '$5,000'],
  ['Development of new types of content', '$2,500'],
  ['OverSubscribe’s fee', '$2,442'],
]

export const DEFAULT_PAST_CREATOR_REVENUE = [
  ['2021', 'YouTube ad revenue', '$500'],
  ['2022', 'YouTube ad revenue and affiliate links', '$5000'],
]

export const DEFAULT_ACCOMPLISHMENTS = [
  ['06/2019', 'Started YouTube channel with first video'],
  ['03/2022', 'Over 2,000,000 total views on TikTok'],
]

export const DEFAULT_PERKS_OFFERED = [
  ['Resume Review:', 'An included resume review to those that invest'],
  ['Coffee Chat:', 'An included 30-minute coffee chat with those that invest'],
]
