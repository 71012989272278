import './styles.css'
import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'

import { fulfillUrlWithParam, getParamFromUrl } from 'utils/url'
import { Label } from 'ui/field/Label'
import { cn } from 'utils'
import IconDown from 'icons/down.svg'

export const PureDropdown = withRouter(
  ({
    name,
    searchParam,
    label,
    options,
    tooltip,
    placeholder,
    history: { push },
    location: { search },
    className,
  }) => {
    const perPage = getParamFromUrl(search, searchParam)
    const [pageLimit, setPageLimit] = useState(0)

    useEffect(() => {
      if (perPage) {
        setPageLimit(perPage)
      } else if (options) {
        setPageLimit(Object.entries(options)[0])
      }
    }, [name, options, perPage])

    const renderOption = ([value, label]) => {
      return (
        <option value={value} key={name + '-option-' + value}>
          {label}
        </option>
      )
    }

    const handleLimitChange = event => {
      const { value } = event.target
      const url = fulfillUrlWithParam(search, searchParam, value)
      setPageLimit(value)
      push(url)
    }
    return (
      <Label
        label={label}
        name={name}
        tooltip={tooltip}
        className={cn('field box-field select select-container pure-dropdown', className)}
      >
        {/* eslint-disable-next-line jsx-a11y/no-onchange */}
        <select value={pageLimit} onChange={handleLimitChange}>
          {placeholder && <option value="">{placeholder}</option>}
          {options && Object.entries(options).map(renderOption)}
        </select>
        <span className="input-addon">
          <IconDown className="select-icon" />
        </span>
      </Label>
    )
  },
)
