import React from 'react'
import T from 'prop-types'

import { BaseField } from './base-field'

export class Select extends BaseField {
  static propTypes = {
    ...BaseField.propTypes,
    autoComplete: T.string,
    disabled: T.bool,
    disabledOptions: T.arrayOf(T.string),
    name: T.string.isRequired,
    onBlur: T.func,
    onValidate: T.func.isRequired,
    options: T.object,
    validation: T.func,
  }
  static defaultProps = {
    placeholder: 'Please Select...',
    disabledOptions: [],
  }

  /** @type {HTMLInputElement} */
  element = null

  /** @private */
  setElement = element => {
    this.element = element
    if (!element) return
    // update the DOM element validity
    this.resetCustomValidity()
    // let the parent form knows about this control
    this.broadcastUpdates({ value: this.props.defaultValue || null })
  }

  /** @private */
  onBlur = e => {
    this.validate()
    this.props.onBlur && this.props.onBlur(e)
  }

  /** @private */
  onChange = event => {
    const { value } = event.target
    // update the DOM element validity
    this.resetCustomValidity({ value })
    // let the parent form knows about changes
    this.broadcastUpdates({ value })
    // after the first change event / when user tried to submit
    this.validate()
  }

  getValidationMessage() {
    return this.element
      ? getInputValidationMessage(this.element, this.props.validationMessages)
      : super.getValidationMessage()
  }

  /**
   * @public
   * @override
   */
  validate() {
    this.props.validation && this.resetCustomValidity()
    this.setState({ touched: true }, () => {
      this.broadcastValidation({
        message: getInputValidationMessage(this.element, this.props.validationMessages),
      })
    })
  }
  /**
   * @override
   * @returns {boolean}
   */
  isValid(value) {
    if (!this.element) return false
    if (!this.element.validity.valid) return false
    if (this.checkCustomValidity(value)) {
      return false
    }
    return true
  }

  /** @override */
  setValue(value) {
    if (!this.element) return
    this.element.value = value
    const { valid } = this.element.validity
    const validationMessage = valid
      ? null
      : getInputValidationMessage(this.element, this.props.validationMessages)
    this.broadcastUpdates({ value: value || null, valid, validationMessage })
  }
  set value(value) {
    this.setValue(value)
  }
  /** @override */
  getValue() {
    return this.element ? this.element.value : undefined
  }
  get value() {
    return this.getValue()
  }

  resetCustomValidity({ value = this.getValue() } = {}) {
    if (!this.willValidate()) {
      return
    }
    if (!this.element) {
      throw new Error('this.element is undefined')
    }

    this.props.validation && this.element.setCustomValidity(this.checkCustomValidity(value))
  }
  componentDidUpdate(prevProps, prevState) {
    super.componentDidUpdate(prevProps, prevState)
    if (prevProps && prevProps.validation !== this.props.validation) {
      const value = this.getValue()
      this.resetCustomValidity({ value })
      this.broadcastUpdates({ value })
      this.state.touched && this.validate()
    }
  }

  renderOption([value, label]) {
    const disabled = this.props.disabledOptions.includes(value)
    return (
      <option value={value} key={this.props.name + '-option-' + value} disabled={disabled}>
        {label}
      </option>
    )
  }

  render() {
    const {
      disabled,
      validation,
      onValidate,
      options,
      placeholder,
      readOnly,
      disabledOptions,
      ...props
    } = this.props
    const { pending } = this.context
    return (
      <select
        {...props}
        ref={this.setElement}
        id={props.id || props.name}
        onBlur={this.onBlur}
        onChange={this.onChange}
        readOnly={readOnly}
        disabled={pending || readOnly || disabled}
      >
        <option value="">{placeholder}</option>
        {options && Object.entries(options).map(this.renderOption, this)}
      </select>
    )
  }
}

const VALIDITY_PROPS = [
  'valueMissing',
  'badInput',
  'typeMismatch',
  'patternMismatch',
  'rangeOverflow',
  'rangeUnderflow',
  'stepMismatch',
  'tooLong',
  'tooShort',
]

/**
 * @param {HTMLInputElement} input
 * @param {ValidityStateMessages} overrides
 */
function getInputValidationMessage(input, overrides = {}) {
  if (!input) return undefined
  const { validity } = input
  if (validity.valid) return null
  const invalidOverriddenProp = VALIDITY_PROPS.find(prop => validity[prop] && overrides[prop])
  return invalidOverriddenProp ? overrides[invalidOverriddenProp] : input.validationMessage
}
