import React from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import { Waitress } from 'components/waitress'
import Video from 'components/video'
import { offers, getOffer } from 'store/offers'
import { Link, withRouter } from 'react-router-dom'
import { Golfer } from 'const/types'
import './styles.css'
import ShareLinks from 'components/share-links'
import { offerDetailsRoute, routes } from 'const/routes'
export const PureThankYouPage = ({ golfer, getOffer }) => {
  const { firstName, uuid, name, thankYouPageVideo } = golfer || {}
  return (
    <Waitress fn={[getOffer]}>
      <div className="container thank-you-page">
        <h1 className="thank-header">Thank you for your investment in {name}!</h1>
        <p className="thank-sub">
          Keep track of your investments by clicking the{' '}
          <Link className="link-to-invest" to={routes.myInvestmentCommitted}>
            My Investments
          </Link>{' '}
          like under your profile in the upper-right.
        </p>
        <p className="thank-sub">
          {' '}
          You'll also recieve an e-mail if, and when this offering finishes or if there are any
          material changes.{' '}
        </p>
        <p className="thank-sub">
          Here's special message from {name} made just for investors like you.
        </p>
        <div className="thanks-con">
          <div className="offer-video">
            <Video
              className="video-container"
              videoClass="video"
              url={thankYouPageVideo ? thankYouPageVideo : 'https://vimeo.com/935477093'}
            />
          </div>
          <div className="box thanks-text">
            <p>
              If you'd like to further help {name} by sharing this opportunity with others, click on
              any of the links below:{' '}
            </p>
            <ShareLinks
              className="sharing-links"
              text={`I just invested in ${name}'s career as a creator and now I can share in their success.`}
              path={offerDetailsRoute({ uuid })}
            />
          </div>
        </div>
      </div>
    </Waitress>
  )
}
PureThankYouPage.propTypes = {
  golfer: Golfer,
  getOffer: PropTypes.func.isRequired,
}

export const ThankYouPage = withRouter(
  connect(
    (state, { match }) => {
      const { uuid } = match.params
      return {
        golfer: offers.golfer(state, { uuid }),
      }
    },
    (dispatch, { match }) => ({
      getOffer: () => dispatch(getOffer(match.params.uuid)),
    }),
  )(PureThankYouPage),
)
