import { all, call, select } from 'redux-saga/effects'
import { callWithAuth } from 'store/auth/saga'

import * as api from 'api'
import { attachSideEffect } from 'store/utils'
import { QA } from '.'
import { user } from 'store/user'

export default function*() {
  yield all([
    //TODO: rewrite - get Q&A from it's own endpoint, but not from the user object
    attachSideEffect(QA.GET_QUESTIONS_LIST, onGetQuestionsList),
    attachSideEffect(QA.ASK_QUESTION, onAskQuestion),
    attachSideEffect(QA.ANSWER_QUESTION, onAnswerQuestion),
  ])
}

function* onGetQuestionsList({ uuid, answer }) {
  if (answer) {
    const account = yield select(user.account)
    const messages = account && account.questionAnswer
    const id = account && account.uuid
    return messages
      ? {
          [id]: messages,
        }
      : {}
  }
  const result = yield call(api.qa.getList(uuid))

  return result
    ? {
        [uuid]: result,
      }
    : {}
}

function* onAskQuestion(data) {
  const result = yield* callWithAuth(api.qa.askQuestion(data.uuid), { question: data.question })
  return result && result.messages && { id: [data.uuid], result }
}

function* onAnswerQuestion(data) {
  const result = yield* callWithAuth(api.qa.answerQuestion(data.uuid), { answer: data.answer })
  const account = yield select(user.account)
  const id = account && account.uuid
  return result && result.messages && { id, result }
}
