import { call, all, takeEvery } from 'redux-saga/effects'
import { attachSideEffect } from '../utils'
import { CONTENTS } from '.'
import * as api from 'api'
import { callWithAuth } from 'store/auth/saga'

export default function*() {
  yield all([
    attachSideEffect(CONTENTS.GET_BY_KEY, onGetContent, takeEvery),
    attachSideEffect(CONTENTS.UPDATE_BY_KEY, onUpdateContent, takeEvery),
    attachSideEffect(CONTENTS.UPLOAD_IMAGE, onUploadImage),
  ])
}

function* onGetContent(key) {
  try {
    const result = yield call(api.contents.getByKey(getKeyByPagePath(key)))
    return { ...result, key }
  } catch (e) {
    return { key, body: null }
  }
}

function* onUpdateContent({ key, content }) {
  let result

  try {
    result = yield* callWithAuth(api.contents.updateByKey(getKeyByPagePath(key)), { body: content })
  } catch (e) {
    //
    result = yield* callWithAuth(api.contents.create, {
      key: getKeyByPagePath(key),
      body: content,
    })
  }

  if (!result) throw { error: 'Page content not found' }
  return { ...result, body: result.body, key }
}

function* onUploadImage(image) {
  const result = yield* callWithAuth(api.image.upload, { image })
  return result
}

const getKeyByPagePath = path => path.replace(/^\//, '').replace('/', '--')
