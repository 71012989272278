import { all, takeEvery, put } from 'redux-saga/effects'
import { callWithAuth } from 'store/auth/saga'

import * as api from 'api'
import { attachSideEffect } from 'store/utils'
import { NOTIFICATIONS, getNotificationsList } from '.'
import { AUTH } from 'store/auth'

export default function*() {
  yield all([
    takeEvery(AUTH.LOGIN.SUCCESS, onLogin),
    attachSideEffect(NOTIFICATIONS.GET_LIST, onGetNotificationsList),
    attachSideEffect(NOTIFICATIONS.MARK_READ, onMarkNotificationRead),
    attachSideEffect(NOTIFICATIONS.MARK_ALL_READ, onMarkAllNotificationsRead),
  ])
}

function* onLogin() {
  yield put(getNotificationsList())
}

function* onGetNotificationsList() {
  const result = yield* callWithAuth(api.notifications.getList)
  return (result && Object.fromEntries(result.items.map(item => [item.uuid, item]))) || null
}

function* onMarkNotificationRead(uuid) {
  const result = yield* callWithAuth(api.notifications.markRead(uuid), { isRead: true })
  return result
}
function* onMarkAllNotificationsRead() {
  yield* callWithAuth(api.notifications.markAllRead)
  return {}
}
