import React from 'react'
import { TextInput } from '../form'
import { BaseField } from './base-field'
import { cn } from 'utils'

export class Field extends BaseField {
  getClassName(className) {
    return cn(super.getClassName(), className, 'floating-label')
  }
  renderField({ placeholder, ...props }) {
    return <TextInput {...props} placeholder=" " />
  }
}
