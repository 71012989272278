import React from 'react'
import PropTypes from 'prop-types'

import { FormContext, Consumer } from './context'
import { Form } from './form'
import { TextInput } from './text-input'
import { SubmitButton } from './submit-button'
import { RadioInputGroup } from './radio-input-group'
import { Select } from './select'
import { PreventNavigationDialog } from 'ui/prevent-navigation-dialog'

export {
  FormContext,
  Form,
  TextInput,
  Consumer as FormControl,
  SubmitButton,
  Select,
  RadioInputGroup,
}

export const Valid = ({ children }) => (
  <Consumer>{({ invalid }) => (invalid ? false : children)}</Consumer>
)

export const Invalid = ({ children }) => (
  <Consumer>{({ invalid }) => (invalid ? children : false)}</Consumer>
)

export const Filled = ({ name, children }) => (
  <Consumer>{({ values }) => (name && values && values[name] ? children : false)}</Consumer>
)
Filled.propTypes = {
  name: PropTypes.string.isRequired,
}

export const FieldValue = ({ name }) => (
  <Consumer>{({ values }) => (values[name] === undefined ? false : values[name])}</Consumer>
)
FieldValue.propTypes = {
  name: PropTypes.string.isRequired,
}

export const FieldValueIs = ({ name, value, children }) => (
  <Consumer>{({ values }) => (values[name] === value ? children : false)}</Consumer>
)
FieldValueIs.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.any,
}

export const HasChanges = ({ children, show }) => (
  <Consumer>
    {({ defaultValues, values }) => {
      const defaultValuesEntries = Object.entries(defaultValues)
      const defaultValuesLength = defaultValuesEntries.length
      const valuesKeysLength = Object.keys(values).length
      return show ||
        valuesKeysLength !== defaultValuesLength ||
        defaultValuesEntries.some(([name, value]) => values[name] !== value)
        ? children
        : false
    }}
  </Consumer>
)
HasChanges.propTypes = {
  show: PropTypes.bool,
}

export const Prompt = ({ message }) => (
  <HasChanges>
    <PreventNavigationDialog message={message} />
  </HasChanges>
)
Prompt.propTypes = {
  message: PropTypes.string,
}
Prompt.defaultProps = {
  message: 'You have unsaved changes that will be lost. Are you sure you want to leave this page?',
}
