import styles from './field.css'
import './currency.css'
import React from 'react'
import T from 'prop-types'
import { Input } from '../form/formatted-input'
import { ValidationMessage } from '../validation-message'
import { cn } from 'utils'
import { Label } from './Label'

const CLEAVE_OPTIONS = {
  numeral: true,
  numeralThousandsGroupStyle: 'thousand',
  numeralPositiveOnly: true,
  numeralDecimalScale: 2,
  stripLeadingZeroes: true,
}

export class CurrencyField extends React.PureComponent {
  static propTypes = {
    ...Label.propTypes,
    hideValidationMessage: T.bool,
  }
  static defaultProps = {
    hideValidationMessage: false,
  }
  state = {
    touched: false,
    validationMessage: null,
    enabled: true,
  }
  validate = value => {
    if (value === undefined || value === null || isNaN(value)) return
    const { min, max } = this.props
    if (min !== undefined && min !== null && value < min) {
      return `Must be greater than or equals to ${min}`
    }
    if (max !== undefined && max !== null && value > max) {
      return `Must be lesser or equals to ${max}`
    }
  }
  onValidate = ({ message, touched = true, enabled }) => {
    this.setState({ validationMessage: message, touched, enabled })
  }
  render() {
    const {
      label,
      hideValidationMessage,
      className,
      type,
      tooltip,
      min,
      max,
      ...props
    } = this.props
    const { validationMessage, touched, enabled } = this.state
    const showValidity = !hideValidationMessage && !!validationMessage && enabled
    return (
      <Label
        label={label}
        name={props.name}
        tooltip={tooltip}
        className={cn('field box-field currency', className)}
      >
        <div className="wrap-currency">
          <Input
            {...props}
            className={cn('input', touched && 'touched')}
            validation={this.validate}
            onValidate={this.onValidate}
            options={CLEAVE_OPTIONS}
          />
          <span className="currency-label">$</span>
        </div>
        {showValidity && (
          <ValidationMessage className={styles.message} error>
            {validationMessage}
          </ValidationMessage>
        )}
      </Label>
    )
  }
}
