import React from 'react'
import { RadioInputGroup } from '../form'
import { BaseField } from './base-field'
import './radioinputgroup.css'
import { cn } from 'utils'
import { ValidationMessage } from '../validation-message'

export class RadioButtonGroup extends BaseField {
  render() {
    const {
      label,
      hideValidationMessage,
      className,
      children,
      horizontal,
      vertical,
      ...props
    } = this.props
    const { validationMessage, enabled } = this.state
    const showValidity = !hideValidationMessage && !!validationMessage && enabled
    return (
      <fieldset
        name={props.name}
        className={cn(
          'radio-button-group',
          horizontal && 'horizontal',
          this.getClassName(className),
        )}
      >
        <legend>{label}</legend>
        <RadioInputGroup
          onValidate={this.onValidate}
          {...props}
          ref={this.element}
          className={cn('input', this.state.touched && 'touched')}
          onReset={this.handleReset}
        />

        {children}
        {showValidity && <ValidationMessage error>{validationMessage}</ValidationMessage>}
      </fieldset>
    )
  }
}

RadioButtonGroup.propTypes = {}
