import './styles.css'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { ContentViewer } from 'components/content-viewer'
import { DefaultHeaders } from 'pages/admin/static/admin-learn-more'

export const LearnMore = () => {
  const { location } = useHistory()

  const filtered = DefaultText.filter(elem =>
    location.hash.includes(elem.header.toLowerCase().replaceAll(' ', '-')),
  )
  const isIncludes = filtered.length ? filtered : [DefaultText[0]]
  const filteredDefault = isIncludes
    .map(elem => {
      const paragraphText = elem.text.map(item => ({ type: 'paragraph', data: { text: item } }))
      const result = [{ type: 'header', data: { text: elem.header, level: 2 } }, ...paragraphText]
      if (elem.list) {
        result.push({ type: 'list', data: { items: elem.list } })
      }
      return result
    })
    .flat()
  const contentKey = location.hash.includes('#') ? location.hash.slice(1) : 'educational-materials'
  return (
    <div className="container static-page learn-more">
      <div className="learn-more-content fade-in-con">
        <ContentViewer
          defaultContent={{ blocks: filteredDefault }}
          contentKey={`learn-more-admin_${contentKey}`}
          key={contentKey}
        />
      </div>
      <ContentViewer
        className="content-list"
        defaultContent={{ blocks: [{ type: 'list', data: { items: DefaultHeaders } }] }}
        contentKey="learn-more-admin-headerz"
      />
    </div>
  )
}

export const DefaultText = [
  {
    header: 'Educational Materials',
    text: [
      'At OverSubscribe, we want to democratize the creator economy. With the recent explosive growth in popularity of online content, the potential for new and aspiring creators has never been greater. But like many small businesses, creators often need capital and investors in order to devote the time and resources to establish and grow their online presence.',
      'In the past, the opportunity to invest in these businesses was severely limited, and often the benefits only went to a select few.',
      `Equity crowdfunding is a relatively new concept which was ratified as federal law through the
JOBS Act passed in 2012. Title III of the law enables everyday people to invest in businesses
that are looking to grow, like the creators listed on OverSubscribe. Now you can help support
your favorite creators and share in their success as they grow.`,
      `As part of Regulation Crowdfunding, which resulted from these laws, we are obligated to
provide you with educational materials to help you make your decision on whether or not to
invest in the creators listed on our platform. We encourage you to read through all the provided
materials below and consider whether these investments are appropriate for you.`,
    ],
  },
  {
    header: 'How It Works',
    text: [
      `Each creator who is looking to raise funds on OverSubscribe has created a corporate entity (ie.
  “Issuer”) to help manage and administer their content creation career and revenue. That issuer
  can then list an offering on our site with details on how much they are looking to raise, how their
  creator business operates, the expenses they have, and the forms of revenue they expect. In
  return for your investment in such an offering, each issuer pledges to pay to investors a portion
  of all creator-related revenue generated by their online content. The portion of revenue to be
  paid out and the amount of time it will be paid for will be set by each issuer, in consultation with
  OverSubscribe, as permitted under regulation.`,
      `Becoming an investor is easy and straightforward. First, create an account after reading all the
        relevant disclosures and requirements. Then, browse the available offerings by different
        creators and find one you like. When you are ready to make an investment, read through the
        available information the creator provides and click the "Invest" button. You will be prompted to
        determine your investment limits using our calculator and you will be able to choose the amount
        you wish to invest. After you enter your payment information and sign the investor agreement,
        you are now a crowdfunding investor! Your funds are committed to the investment until the
        investment process ends and are held in escrow, and you will receive an e-mail with your
        investment details. Generally, investors will have the ability to cancel their investment up to 48
        hours before an offering close. More details on cancelling an investment can be found below. If
        the offering does not reach its target amount or if the offering is cancelled by the Issuer, you will
        receive your committed funds back from escrow. Otherwise, once an offering you have invested
        in ends and the target amount is reached, the committed funds will be released from escrow to
        the Issuer and you will receive another e-mail confirming your investment.`,
    ],
  },
  {
    header: 'How We Select Creators',
    text: [
      `At OverSubscribe, we believe everyone should have the opportunity to reach their full potential
regardless of their socioeconomic background. Thus, we are open to accepting on our platform
creators of all kinds, after passing background checks and verification of their channels.`,
      `Each creator accepted on our platform also use OverSubscribe’s advisory services around
        investment structure, terms, and documentation. We work with each creator to help determine
        appropriate payout terms that balance the interests of both investors and creators.`,
    ],
  },
  {
    header: 'Our Fees For Services',
    text: [
      `There is no extra charge for investors making investments on OverSubscribe beyond your
investment amount.`,
      `For creators listing an offering on our platform, fees are only paid to OverSubscribe if an issuer
meets its minimum goal for its offering and are only paid at the conclusion of the successful
offering. These transaction fees cover the cost of managing the offering itself and the cost for
our offering-related services, which are provided regardless of whether or not the offering
reaches its goal and whether or not any fees are paid to us. OverSubscribe's fees will also be
laid out in a detailed manner in each Issuer's Form C filing which can be found on the Issuer's
offering page.`,
    ],
  },
  {
    header: 'Types of Securities',
    text: [
      `Each Issuer listed on OverSubscribe will be offering securities in the form of preferred equity
shares which entitles you to a portion of all future creator-related revenue as described both
below and in each Issuer's offering documents.`,
      `These shares do not come with any voting rights or any form of company control as may be
typical to other types of equity.`,
    ],
  },
  {
    header: 'Creator-Related Revenue',
    text: [
      `Each Issuer will agree to include all created-related revenue that will flow through the company.
        The sources of revenue will be clearly laid out in the Issuer documents, but generally this will
        include:`,
    ],
    list: [
      `Platform revenue from platforms (YouTube/Instagram/Facebook/TikTok) hosting content`,
      `Brand partnerships that are featured on the creator channels`,
      `Merchandise sales using creator content/branding/imaging`,
      `Subscription content revenue`,
      `Revenue from sales made via affiliate links`,
    ],
  },
  {
    header: 'Creator Agreement',
    text: [
      `Each creator will sign a Creator Agreement (“CA”) which describes the relationship between
        creators, investors, and the issuer as well as the rights and obligations of each. A summary of
        the terms of the CA will be available to investors.`,
    ],
  },
  {
    header: 'Post Offering Relationship',
    text: [
      `As a service to each Issuer and their investors, representatives of OverSubscribe, Inc ("PEI"),
an affiliated company, will be a designated board member of each Issuer together with the
creator. PEI's designated board members will have a fiduciary duty to the corporate entity tied to
the Issuer, which includes honoring any obligations to investors and ensuring compliance of
each party to the Creator Agreement. PEI will NOT be reimbursed or paid for its service on the
board.`,
      `Separately, OverSubscribe, Inc. may enter into a services agreement after a deal closes, at the
discretion of the Issuer, wherein certain administrative functions will be provided to the Issuer in
return for a fee.`,
      `OverSubscribe Portal, LLC will NOT have a post-offering relationship with any Issuer listed on
its platform.`,
    ],
  },
  {
    header: 'Selling Restrictions',
    text: [
      `Federal regulations prevent investors from reselling securities purchased on crowdfunding
  platforms for the first 12 months after the close of an offering except under certain conditions,
  unless the shares are transferred: i) to the company that issued the securities. ii) to an
  accredited investor. iii) in connection with your death, divorce, or other similar circumstance, to a
  trust controlled by you or a trust created for the benefit of a family member. iv) as part of a later
  offering registered with the SEC. v) to a member of your family (child, stepchild, grandchild,
  parent, stepparent, grandparent, spouse or spousal equivalent, sibling, mother-in-law, father-in-
  law, son-in-law, daughter-in-law, brother-in-law, or sister-in-law, including adoptive relationships).`,
      `There may be additional state and foreign laws that govern the transfer of these securities as
  well. You may wish to consult a financial advisor to find out more on selling restrictions.`,
      `After the 12 month restricted period, you may find it difficult to sell these securities. Investors
  should expect to hold onto these investments indefinitely and with the real risk of total loss. You
  should only invest an amount you can afford to lose.`,
    ],
  },
  {
    header: 'Cancellation Policy',
    text: [
      `Investors will have the right to cancel any investment commitments up to 48 hours before a deal
  is scheduled to close. After that time, investments will be final and investors will not be able to
  cancel their commitment.`,
      `In the case where there is a material change to the terms of a deal offered on OverSubscribe,
  investors will be notified of the change and will have 5 business days to reconfirm their
  commitment. Failure to reconfirm a commitment in such an instance will result in the investment
  being cancelled.`,
      `Issuers have the ability to cancel investments before an offering close for any reason. Also, If
  OverSubscribe learns of potential fraud surrounding an Issuer or offering, the offering will be
  canceled as well. In all cases of investment cancellations, however, OverSubscribe will return
  any committed funds and explain the reason behind the cancellation.`,
    ],
  },
  {
    header: 'Investment Amount Restrictions',
    text: [
      `Per federal rules and regulations, investors can only invest up to a certain amount every 12
        months across all equity crowdfunding portals, including OverSubscribe. OverSubscribe
        provides an online calculator that helps each investor, at the time of investment, determine their
        limit and how much they can still invest under that limit.`,
      `Limits are calculated using a combination of an investor’s net worth and annual income. 
        If either an investor’s income or net worth is below $124,000, they may invest up to 5% of the 
        lesser of their income and net worth up to a cap of $2,500. If both an investor’s income and net
         worth is above $124,000, they may invest up to 10% of the lesser of their income and net worth 
         up to a cap of $124,000.`,
    ],
  },
  {
    header: 'Risks',
    text: [
      `Securities that depend on the popularity and monetization of future online content creation are
inherently risky.`,
      `Future performance of online content revenue generation is not easily predictable. Many, if not
most, creators will not earn substantial amounts of money from their online content for years, if
at all. Thus, many investments will not have significant returns for some time, if at all.`,
      `Creators will have wide latitude in managing their careers after the budgeted period after a
successful offering close. They may choose to cease, or pause, their online content creation for
any number of reasons. Generally, however, any revenue generated from past content during
the revenue share period will continue to be eligible for sharing with investors.`,
      `While OverSubscribe conducts standard background checks on each creator at the heart of
each Issuer and has a contractual Master Creator Agreement with the individual creator, we are
not liable for any potential future fraud committed by the creator.`,
      `Securities do not come with any form of control or promise of repayment.`,
      `Although investors are receiving preferred stock through the offering, this class of equity will not
have the voting nor control powers typical of equity investments.`,
      `The preferred equity received by investors is not a debt instrument. Thus, there is no obligation
for the Issuer to repay any fixed amount to an investor. Instead the creator will enter an
agreement to pay out a portion of future creator-related revenue to investors through the Issuer,
the exact amount of which will be unknown at deal close.`,
      `There are limits on disclosure requirements.`,
      `Companies issuing securities through OverSubscribe are required to disclose certain
information to investors described in the SEC regulation §227.201 which can be viewed using
the following link. This required information will be displayed on each Issuer's page either on the
page itself or through a downloadable document called a Form C. Investors can and should
read through the provided disclosures to help them decide whether or not to invest. You may
also use the Q/A section on each Issuer page to ask questions, receive answers from Issuers,
and view questions from other investors. Investors are not otherwise entitled to other forms of
Issuer disclosures.`,
      `After a deal close, Issuers are required to file reports at least annually which they will make
available to investors. Depending on the amount raised, there may be differing levels of review
of financials disclosed by the Issuer as well. There are certain circumstances where Issuers
may cease filing these annual reports which are explained fully in the following link. If an Issuer
elects to cease filing annual reports due to any of the circumstances above, an investor may not
always have the latest financial information about the Issuer.`,
      `There may be changes in general economic conditions.`,
      `Many companies, including the ones listed on OverSubscribe, may perform adversely due to
changes in general economic and/or market conditions. Because revenue from online content
creation is often tied to advertising, the money they earn may decrease under certain conditions
despite their activities. Brand partnerships, another common source of creator revenue, may
also fluctuate depending on market conditions.`,
      `Past performance is not indicative of future results.`,
      `As with all investments, past performance by a creator is not an indication of future results. This
is the case for both quality or frequency of content and the amount of revenue generated.`,
      `Issuers may need more capital in the future.`,
      `Many new companies, including the ones listed on OverSubscribe, often need additional capital
in the future in order to operate. While there will be an opportunity for such companies to raise
more money through re-listing on OverSubscribe, there is no assurance that we will accept
such an offering, that it will come with beneficial terms, or that it will be successful.`,
      `There may be regulatory changes in the future.`,
      `Many new companies, including the ones listed on OverSubscribe, often need additional capital
in the future in order to operate. While there will be an opportunity for such companies to raise
more money through re-listing on OverSubscribe, there is no assurance that we will accept
such an offering, that it will come with beneficial terms, or that it will be successful.`,
      `There may be tax consequences to investing in securities.`,
      `There may be many tax consequences for investors who hold securities of crowdfunded
companies. At the same time, there is no assurance that any money returned to investors will
be tax-efficient. Most investors in the companies listed on OverSubscribe can expect to receive
any potential returns as corporate dividends and will receive a 1099-DIV shortly after the end of
each year, but it is possible that returns may take other forms. Investors should consult their
own advisors to determine the relevant tax consequences of investing in these companies.`,
    ],
  },
]
