import './throbber.css'
import React from 'react'
import { cn } from 'utils'

export const Throbber = ({ className, ...props }) => (
  <div {...props} className={cn('throbber', className)}>
    <div />
    <div />
    <div />
  </div>
)
