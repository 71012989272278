import { compoundActionType, compoundErrorActionCreator } from '../utils'
import * as saved from './selectors'
import { AUTH } from 'store/auth'
import { USER } from 'store/user'

export { saved }

export const SAVED = {
  GET_OFFERING_LIST: compoundActionType('saved.get-list'),
  DELETE_OFFERING: compoundActionType('saved.delete-offering'),
  ADD_OFFERING: compoundActionType('saved.add-offering'),
  TOGGLE_OFFERING: compoundActionType('saved.toggle-offering'),
}

export const toggleSaved = compoundErrorActionCreator(SAVED.TOGGLE_OFFERING)

export const getSavedList = compoundErrorActionCreator(SAVED.GET_OFFERING_LIST)

/** @type {Countries} */
export const initialState = {
  byId: {},
  total: null,
  pending: false,
  error: null,
}

export default function(state = initialState, { type, payload }) {
  switch (type) {
    case USER.GET_ACCOUNT.SUCCESS:
      return {
        ...state,
        byId:
          payload && payload.marks
            ? Object.fromEntries(payload.marks.map(mark => [mark.uuidGolfer, mark]))
            : {},
      }

    case SAVED.GET_OFFERING_LIST.START:
    case SAVED.DELETE_OFFERING.START:
    case SAVED.ADD_OFFERING.START:
    case SAVED.TOGGLE_OFFERING.START:
      return {
        ...state,
        pending: true,
        error: null,
      }
    case SAVED.GET_OFFERING_LIST.SUCCESS:
      return {
        ...state,
        pending: false,
        byId: payload,
      }
    case SAVED.DELETE_OFFERING.SUCCESS:
      return {
        ...state,
        pending: false,
        byId: excludeProp(state.byId, payload),
      }
    case SAVED.ADD_OFFERING.SUCCESS:
      return {
        ...state,
        pending: false,
        byId: payload.uuidGolfer ? { ...state.byId, [payload.uuidGolfer]: payload } : state.byId,
      }
    case SAVED.TOGGLE_OFFERING.SUCCESS:
      return {
        ...state,
        pending: false,
      }
    case SAVED.GET_OFFERING_LIST.FAILURE:
    case SAVED.DELETE_OFFERING.FAILURE:
    case SAVED.ADD_OFFERING.FAILURE:
    case SAVED.TOGGLE_OFFERING.FAILURE:
      return {
        ...state,
        pending: false,
        error: payload.error,
      }
    case AUTH.LOGOUT.SUCCESS:
      return initialState

    default:
      return state
  }
}

const excludeProp = (obj, x) => {
  const { [x]: old, ...result } = obj
  return result
}
