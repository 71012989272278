import React from 'react'
import T from 'prop-types'
import { PureDropdown } from '../pure-dropdown/PureDropdown'

export const FilterDropdown = ({ name, searchParam, label, options, tooltip, placeholder }) => (
  <PureDropdown
    name={name}
    searchParam={searchParam}
    label={label}
    options={options}
    tooltip={tooltip}
    placeholder={placeholder}
  />
)

FilterDropdown.propTypes = {
  label: T.node,
  name: T.string,
  options: T.objectOf(T.string),
  placeholder: T.string,
  searchParam: T.string,
  tooltip: T.node,
}
