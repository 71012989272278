import { call, select, all, debounce } from 'redux-saga/effects'
import { OFFERS } from '.'
import * as api from 'api'
import { attachSideEffect } from '../utils'
import { offers } from 'store/offers'

import { callWithAuth } from 'store/auth/saga'
import { buildListParams } from 'utils/list'

export default function*() {
  yield all([
    attachSideEffect(OFFERS.GET_LIST, onGetList),
    attachSideEffect(OFFERS.GET_CLOSED_BY_TIME_LIST, onGetClosedList),
    attachSideEffect(OFFERS.ADMIN_GET_LIST, onAdminGetList),
    attachSideEffect(OFFERS.GET_SAVED_LIST, onGetSavedList),
    attachSideEffect(OFFERS.GET_OFFER, onGetOffer),
    attachSideEffect(OFFERS.GET_INVESTED_LIST, onGetInvestedList),
    attachSideEffect(OFFERS.SEARCH, onSearch, (...args) => debounce(300, ...args)),
    attachSideEffect(OFFERS.SEARCH_MORE, onSearch),
    attachSideEffect(OFFERS.ADMIN_UPDATE_OFFER, onAdminUpdateOffer),
    attachSideEffect(OFFERS.ADMIN_CREATE_OFFER, onAdminCreateOffer),
  ])
}

// eslint-disable-next-line no-undef
const imagePaths = require
  .context('img/mock_data/', false, /\.*$/)
  .keys()
  .map(name => name.replace('./', '/assets/img/mock_data/'))

function* onAdminGetList({ orderBy = 'firstName', search, ...params }) {
  const results = yield* callWithAuth(api.admin.offers.getList, {
    ...buildListParams({ ...params, orderBy }),
    ...(search && { 'search[fullName]': search }),
  })
  const total =
    (results &&
      ((results.pagination && results.pagination.totalItems) ||
        (results.items && results.items.length))) ||
    0
  const byId = yield call(fullFillItems, results.items)
  return { items: byId, total }
}

function* onGetList({ orderBy = 'firstName', ...params }) {
  const results = yield call(api.offers.get, buildListParams({ ...params, orderBy }))
  const total =
    (results &&
      ((results.pagination && results.pagination.totalItems) ||
        (results.items && results.items.length))) ||
    0
  const byId = yield call(fullFillItems, results.items)
  return { items: byId, total }
}

function* onGetClosedList({ orderBy = 'firstName', ...params }) {
  const results = yield call(api.offers.get, buildListParams({ ...params, orderBy }))
  const total =
    (results &&
      ((results.pagination && results.pagination.totalItems) ||
        (results.items && results.items.length))) ||
    0
  const byClosedId = yield call(fullFillItems, results.items)
  return { items: byClosedId, total }
}

function* onGetOffer(uuid) {
  const result = yield call(api.offers.getOffer, uuid)
  const byId = yield call(fullFillItems, [result])
  return byId
}

function* onAdminUpdateOffer({ uuid, data }) {
  const result = yield* callWithAuth(api.admin.offers.updateOffer(uuid), data)
  return result
}

function* onAdminCreateOffer({ data }) {
  const result = yield* callWithAuth(api.admin.offers.createOffer, data)
  return result
}

function* onGetSavedList() {
  const ids = yield select(offers.missingSavedIds)
  if (!ids || !ids.length) return
  const results = yield call(api.offers.get, getFilterIn(ids))
  const items = yield call(fullFillItems, results.items)
  return { items }
}
function* onGetInvestedList() {
  const ids = yield select(offers.missingInvestedIds)
  if (!ids || !ids.length) return
  const results = yield call(api.offers.get, getFilterIn(ids))
  const items = yield call(fullFillItems, results.items)
  return { items }
}

function* onSearch({ limit = 18, search, orderBy = 'firstName', ...params } = {}) {
  const results = yield call(api.offers.get, {
    ...buildListParams({ ...params, orderBy, limit }),
    ...(search && { 'search[fullName]': search }),
  })
  const total =
    (results &&
      ((results.pagination && results.pagination.totalItems) ||
        (results.items && results.items.length))) ||
    0
  const byId = yield call(fullFillItems, results.items)
  return { items: byId, total }
}

async function fullFillItems(items) {
  const combinedItems = items.map((item, index) => {
    const fixedImgs = {
      //finra
      '6e117071-1727-41b6-8b64-ba5d08334e8e': imagePaths[0],
      'a99ff060-b7f7-4f1b-825c-b76f05b8416e': imagePaths[1],
      '8177ca98-3410-4970-ba5b-de2062f11899': imagePaths[2],
      '01b7d527-6789-4e14-81c4-af4e7437cee3': imagePaths[3],
      '829fb56d-7519-4e74-b94b-9dee509ac8a9': imagePaths[4],
      '5eb9dd7f-f308-411c-8c45-d26efe7be444': imagePaths[5],
      'b77503d7-80bb-4d46-9ead-3aedbe0372fc': imagePaths[6],
      '861eb3bc-05d2-42c7-a795-2ce93aaf3a75': imagePaths[7],
      //

      '6314f3c0-6aec-4c01-9266-bc6024f6efeb': imagePaths[0],
      '1142db5a-97e8-4416-a3f6-09576f94122c': imagePaths[0],
      'c04e0ac6-7c9a-424f-b435-0309ff588d61': imagePaths[1],
      'a8de6708-64f5-48f5-9eaf-ecdbd540b29f': imagePaths[1],
      '6cd3bf0b-73aa-4962-9c4f-97992a183d28': imagePaths[2],
      '9a999511-66c2-4f82-9c2c-ef0d056a65e5': imagePaths[2],
      'd785d21b-7a1a-4f00-a46c-0abcdb5d1b34': imagePaths[3],
      '3414d6a1-b2ab-4476-abaa-ba3b7288c017': imagePaths[3],
    }
    const result = {
      gender: toCapitalCase(item.gender),
      image: fixedImgs[item.uuid] || imagePaths[index % imagePaths.length],
      ...item,
    }
    return [item.uuid, result]
  })
  return Object.fromEntries(combinedItems)
}

function toCapitalCase(str) {
  return str && str.replace(/^\w/, str.charAt(0).toUpperCase())
}

function getFilterIn(ids, name = 'uuid') {
  return {
    [`filter[${name}][operator]`]: 'in',
    ...Object.fromEntries(ids.map((id, index) => [`filter[${name}][value][${index}]`, id])),
  }
}
