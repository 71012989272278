import { createSelector } from 'reselect'

/** @param {State} state */
export const pending = state => state.countries.pending

/** @param {State} state */
export const error = state => state.countries.error

/** @param {State} state */
export const countriesMap = state => state.countries.byCode || null

/** @type {function(State):Country[]} */
export const countries = createSelector(
  countriesMap,
  countriesMap => (countriesMap && Object.values(countriesMap).sort(byCountryName)) || null,
)

/** @type {function(State):Option[]} */
export const countryOptions = createSelector(
  countries,
  /** @param {Country[]} countries */
  countries =>
    (countries && countries.map(({ name, code }) => ({ label: name, value: '' + code }))) || null,
)

/** @type {function(State):Option[]} */
export const countryCodeOptions = createSelector(
  countries,
  /** @param {Country[]} countries */
  countries =>
    (countries &&
      countries.reduce((result, { name, code }) => ({ ...result, [code]: name }), {})) ||
    null,
)

function byCountryName(a, b) {
  return a.name > b.name ? 1 : a.name < b.name ? -1 : 0
}

/** @typedef {{value:String,label:String}} Option */
/** @typedef {import('../index').State} State */
/** @typedef {import('store/auth/selectors').Country} Country */
