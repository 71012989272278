import React, { useCallback, useState } from 'react'
import { withRouter, Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { materialChangeStatus } from 'store/investments'
import { materialChangeConfirmRoute, routes } from 'const/routes'
import { useHistory } from 'react-router-dom'
import { MaterialConfirmDialog } from './material-confirm-dialog'
import { SignedIn } from 'components/signed'
import { INVESTOR } from 'const/types'
import { pathWithFutureRedirect } from 'utils/url'

export const PureMaterialChangeConfirm = props => {
  const { materialChangeConfirm, match } = props || {}
  const [isOpen, setIsOpen] = useState(true)
  const history = useHistory()

  const materialChangeHandle = useCallback(async () => {
    try {
      await materialChangeConfirm()
      setIsOpen(false)
    } catch (e) {
      throw e
    }
  }, [])

  const handleCancel = () => {
    history.push(routes.landing)
  }
  return (
    <SignedIn
      userRole={INVESTOR}
      redirectTo={pathWithFutureRedirect(
        routes.login,
        materialChangeConfirmRoute({ uuid: match.params.uuid }),
      )}
    >
      <div className="container static-page">
        <div className="container thank-you-page">
          <h1 className="thank-header">Thank you for your investment!</h1>
          <p className="thank-sub">
            Keep track of your investments by clicking the{' '}
            <Link className="link-to-invest" to={routes.myInvestmentCommitted}>
              My Investments
            </Link>{' '}
            like under your profile in the upper-right.
          </p>
        </div>
        {isOpen && (
          <MaterialConfirmDialog
            changedTitle="confirm"
            onClose={handleCancel}
            onSubmit={materialChangeHandle}
          />
        )}
      </div>
    </SignedIn>
  )
}

export const MaterialChangeConfirm = withRouter(
  connect(
    (uselessProps, props) => ({ uuid: props.match.uuid }),

    (dispatch, { match }) => ({
      materialChangeConfirm: () =>
        dispatch(materialChangeStatus({ uuid: match.params.uuid, status: 'confirm' })),
    }),
  )(PureMaterialChangeConfirm),
)
