import React from 'react'
import { createStructuredSelector } from 'reselect'
import T from 'prop-types'
import { connect } from 'react-redux'
import './closed-list.css'
import { Article } from './Article'
import { getClosedList, offers } from 'store/offers'
import { Waitress } from 'components/waitress'
import { Golfer } from 'const/types'

export const ClosedArticlesListComponent = ({ getClosedList, title, items }) => {
  return (
    <Waitress fn={[getClosedList]}>
      <div className="tg-list">
        <div className="row">
          <div className="col-12">
            <h6 className="mb-20">Successfully OverSubscribed Creators</h6>
          </div>
          <div className="closed-items-list">
            {items && items.map(item => <Article offering={item} key={item.uuid} />)}
          </div>
        </div>
      </div>
    </Waitress>
  )
}

ClosedArticlesListComponent.propTypes = {
  getClosedList: T.func.isRequired,
  items: T.arrayOf(Golfer),
  title: T.string,
}

export const ClosedArticlesList = connect(
  createStructuredSelector({
    items: offers.closedList,
  }),
  { getClosedList },
)(ClosedArticlesListComponent)
