import { call } from 'redux-saga/effects'
import * as actions from '.'
import { attachSideEffect } from '../utils'

export default function*() {
  yield attachSideEffect(actions.GET_LIST, getStatesList)
}

function* getStatesList() {
  const stateOptions = yield call(importStates)
  return stateOptions
}

function importStates() {
  return import('const/states').then(({ states }) => states)
}
