import './avatar.css'
import React from 'react'
import PropTypes from 'prop-types'
import { cn } from 'utils'

export const Avatar = ({ className, src, ...props }) => (
  <div className={cn('avatar', className)}>
    {src ? <img {...props} alt="avatar" src={src} /> : <div />}
  </div>
)
Avatar.propTypes = {
  src: PropTypes.string,
}
