import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { SearchCard } from './SearchCard'
import { Golfer } from 'const/types'
import { NO_ITEMS } from 'const/list'
import { offers } from 'store/offers'

const PureSearchResultsList = ({ items }) =>
  items && items.length ? (
    <div className="tg-search-result">
      <div className="row">
        {items.map(offering => (
          <SearchCard key={offering.uuid} offering={offering} />
        ))}
      </div>
    </div>
  ) : (
    <div className="text-center mb-20 bold">{NO_ITEMS}</div>
  )

PureSearchResultsList.propTypes = {
  items: PropTypes.arrayOf(Golfer).isRequired,
}

export const SearchResultsList = connect(
  createStructuredSelector({
    items: offers.searchResults,
  }),
)(PureSearchResultsList)
