import './content-editor.css'
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import EditorJS from '@editorjs/editorjs'
import Header from '@editorjs/header'
import List from '@editorjs/list'
import Table from '@editorjs/table'
import Hyperlink from 'editorjs-hyperlink'
import { ContentBody } from 'const/types'
import { Waitress } from 'components/waitress'
import { contents, getContentByKey, updateContentByKey } from 'store/contents'
import { Button } from 'ui/button'
import DragDrop from 'editorjs-drag-drop'
import debounce from 'debounce'
import { PERSISTANCE_INTERVAL } from 'utils/storage'

class PureContentEditor extends React.Component {
  static propTypes = {
    content: ContentBody,
    onSave: PropTypes.func,
    cols: PropTypes.number,
    rows: PropTypes.number,
  }

  state = {
    modified: false,
  }

  element = React.createRef()

  handleChange = () => {
    this.setState({ modified: true })
  }

  handleSave = async () => {
    const { onSave } = this.props
    const content = await this.editor.save()
    onSave && (await onSave(content))
    this.setState({ modified: false })
  }

  async componentDidMount() {
    const filteredList = this.props.content.blocks.filter(elem => elem.type === 'list')

    const mapped =
      filteredList.length &&
      filteredList[0].data.items.map(elem => {
        if (elem.includes(`href="${window.location.hash}"`)) {
          const str = 'aria-current=page'
          return elem.slice(0, 2) + ' ' + str + ' ' + elem.slice(2)
        }
        return elem
      })

    if (mapped.length && this.props.content.blocks.length === 1) {
      filteredList[0].data.items = mapped
      this.props.content.blocks = filteredList
      await this.init(this.props.content)
    } else {
      await this.init(this.props.content)
    }

    this.element.current.addEventListener('input', this.handleChange)
  }

  shouldComponentUpdate({ content }, { modified }) {
    return modified !== this.state.modified || content !== this.props.content
  }

  componentDidUpdate({ content }) {
    if (content !== this.props.content) {
      this.reset()
    }
  }

  componentWillUnmount() {
    this.element.current && this.element.current.removeEventListener('input', this.handleChange)
  }

  isChanged = debounce(async (api, content) => {
    const key = 'blocks'
    const item = await api.saver.save()
    if (
      key in item &&
      key in content &&
      JSON.stringify(item.blocks) !== JSON.stringify(content.blocks)
    ) {
      this.handleChange()
    }
  }, PERSISTANCE_INTERVAL)

  async init(content) {
    this.editor = new EditorJS({
      holder: this.element.current,
      placeholder: 'Put your text here',
      data: content,
      onChange: api => this.isChanged(api, content),

      tools: {
        list: {
          class: List,
          inlineToolbar: true,
          config: {
            defaultStyle: 'unordered',
          },
        },
        table: {
          class: Table,
          inlineToolbar: true,
          withHeadings: false,
          config: {
            rows: this.props.rows || 3,
            cols: this.props.cols || 3,
          },
        },
        header: {
          class: Header,
          inlineToolbar: ['marker'],
          config: {
            placeholder: 'Header',
          },
          shortcut: 'CMD+SHIFT+H',
        },

        checklist: {
          class: Checklist,
          inlineToolbar: true,
        },

        quote: {
          class: Quote,
          inlineToolbar: true,
          config: {
            quotePlaceholder: 'Enter a quote',
            captionPlaceholder: "Quote's author",
          },
          shortcut: 'CMD+SHIFT+O',
        },
        warning: Warning,
        marker: {
          class: Marker,
          shortcut: 'CMD+SHIFT+M',
        },
        code: {
          class: CodeTool,
          shortcut: 'CMD+SHIFT+C',
        },
        delimiter: Delimiter,
        inlineCode: {
          class: InlineCode,
          shortcut: 'CMD+SHIFT+C',
        },
        embed: Embed,
        hyperlink: {
          class: Hyperlink,
          config: {
            shortcut: 'CMD+L',
            target: '_blank',
            rel: 'nofollow',
            availableTargets: ['_blank', '_self'],
            availableRels: ['author', 'noreferrer'],
            validate: false,
          },
        },
      },
      i18n: {
        toolNames: {
          Hyperlink: 'Link',
        },
        tools: {
          hyperlink: {
            Save: 'Salvar',
            'Select target': 'Seleziona destinazione',
            'Select rel': 'Wählen rel',
          },
        },
      },
    })
    await this.editor.isReady

    new DragDrop(this.editor)
  }

  async reset() {
    await this.destroy()
    await this.init(this.props.content)
  }

  async destroy() {
    if (!this.editor) return

    await this.editor.isReady
    try {
      await this.editor.destroy()
    } catch (e) {
      //
    }
    this.editor = null
    return
  }

  getToLink(e) {
    e.preventDefault()
    if (e.target.tagName === 'A' && e.target.parentElement.className.includes('cdx-list__item')) {
      window.open(e.target.href, '_self')
      const listOfItems = Array.from(e.target.parentElement.parentElement.children)
      listOfItems.forEach(elem => elem.firstChild.removeAttribute('aria-current'))
      e.target.setAttribute('aria-current', 'page')
      return true
    }
  }

  render() {
    const { className } = this.props
    return (
      <div className="content-editor">
        <div className={className} ref={this.element} onClick={this.getToLink}>
          {this.props.children && this.props.children}
        </div>
        {this.state.modified && (
          <Button primary className="content-editor-save" onClick={this.handleSave}>
            Save
          </Button>
        )}
      </div>
    )
  }
}

export const ContentEditor = connect(
  createStructuredSelector({
    content: contents.contentByKey,
  }),
  (dispatch, { contentKey }) => ({
    getContent: () => dispatch(getContentByKey(contentKey)),
    onSave: content => dispatch(updateContentByKey({ key: contentKey, content })),
  }),
)(
  ({
    getContent,
    content,
    defaultContent,
    contentKey,
    dispatch,
    onChange,
    className,
    children,
    ...props
  }) => (
    <Waitress fn={[getContent]}>
      <PureContentEditor
        {...props}
        content={content || defaultContent}
        className={className}
        onChange={onChange}
      >
        {children}
      </PureContentEditor>
    </Waitress>
  ),
)

ContentEditor.propTypes = {
  contentKey: PropTypes.string.isRequired,
  defaultContent: ContentBody,
  cols: PropTypes.number,
  rows: PropTypes.number,
}
