import React from 'react'
import { ContentEditor } from 'components/content-editor'
import { InvestingInfoItem } from 'pages/landing/InvestingInfoBlock'

export const AdminInvestingInfoBlock = () => (
  <div className="why-search">
    <h6 className="text-center mb-40">
      <ContentEditor contentKey="landing/investing-info-0" defaultContent={DEFAULT_CONTENT[0]} />
    </h6>
    <div className="row">
      <InvestingInfoItem>
        <ContentEditor contentKey="landing/investing-info-1" defaultContent={DEFAULT_CONTENT[1]} />
      </InvestingInfoItem>
      <InvestingInfoItem>
        <ContentEditor contentKey="landing/investing-info-2" defaultContent={DEFAULT_CONTENT[2]} />
      </InvestingInfoItem>
      <InvestingInfoItem>
        <ContentEditor contentKey="landing/investing-info-3" defaultContent={DEFAULT_CONTENT[3]} />
      </InvestingInfoItem>
    </div>
  </div>
)
const DEFAULT_CONTENT = [
  'Why invest with OverSubscribe?',
  'Get in early as a seed investor in this emerging asset class and make a real difference in the career of an up-and-coming student.',
  'Share in a piece of the future revenue and potential upside of the individual student at the heart of each company.',
  'Know that you’re investing on the only investment crowdfunding platform for opportunities like this that is fully compliant with federal securities laws.',
].map(text => ({ blocks: [{ type: 'paragraph', data: { text } }] }))
