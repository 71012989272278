import { call, all } from 'redux-saga/effects'
import { COUNTRIES } from '.'
// import * as selectors from './selectors'
// import * as api from 'api'
import { attachSideEffect } from '../utils'

export default function*() {
  yield all([attachSideEffect(COUNTRIES.GET_LIST, onGetCountriesList)])
}

function* onGetCountriesList() {
  // const existingCountries = yield select(selectors.countriesMap)
  // if (Object.values(existingCountries).length) {
  //   return existingCountries
  // }

  /** @type {Country[]} */
  // const list = yield call(api.countries.get)
  /** @type {Object.<string,Country>} */
  // const result = list && list.reduce((result, item) => ({ ...result, [item.id]: item }), {})
  const result = yield call(importCountries)
  return result
}

/**
 * @typedef {Object} Country
 * @property {string} code
 * @property {string} name
 * @property {string} phoneCode
 */

function importCountries() {
  return import('const/countries').then(({ countries }) => {
    return countries.reduce((result, item) => ({ ...result, [item.code]: item }), {})
  })
}
