import { compoundActionType, compoundErrorActionCreator } from '../utils'
import * as notifications from './selectors'
import { AUTH } from 'store/auth'

export { notifications }

export const NOTIFICATIONS = {
  GET_LIST: compoundActionType('notifications.get-list'),
  MARK_READ: compoundActionType('notifications.mark-read'),
  MARK_ALL_READ: compoundActionType('notifications.mark-all-read'),
}

export const getNotificationsList = compoundErrorActionCreator(NOTIFICATIONS.GET_LIST)
export const markNotificationRead = compoundErrorActionCreator(NOTIFICATIONS.MARK_READ)
export const markAllNotificationsRead = compoundErrorActionCreator(NOTIFICATIONS.MARK_ALL_READ)

export const initialState = {
  pending: false,
  error: null,
  byId: null,
}

export default function(state = initialState, { type, payload }) {
  switch (type) {
    case NOTIFICATIONS.GET_LIST.START:
      return {
        ...state,
        pending: true,
        error: null,
      }
    case NOTIFICATIONS.GET_LIST.SUCCESS:
      return {
        ...state,
        pending: false,
        byId: { ...state.byId, ...payload },
      }
    case NOTIFICATIONS.GET_LIST.FAILURE:
      return {
        ...state,
        pending: false,
        error: payload.error,
      }
    case NOTIFICATIONS.MARK_READ.SUCCESS:
      return {
        ...state,
        pending: false,
        byId:
          payload && payload.uuid
            ? {
                ...state.byId,
                [payload.uuid]: {
                  ...state.byId[payload.uuid],
                  ...payload,
                },
              }
            : state.byId,
      }
    case NOTIFICATIONS.MARK_ALL_READ.SUCCESS:
      return {
        ...state,
        pending: false,
        byId: Object.fromEntries(
          Object.values(state.byId).map(item => [item.uuid, { ...item, isRead: true }]),
        ),
      }

    case AUTH.LOGOUT.SUCCESS:
      return initialState
    default:
      return state
  }
}
