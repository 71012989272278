import React from 'react'

import { Link } from 'ui/link'
import { routes } from 'const/routes'

export const Footer = () => (
  <footer className="footer">
    <div className="container">
      <div className="row">
        <div className="col-12">
          <div className="logo-footer" />
          <nav className="footer-menu">
            <ul>
              <li>
                <Link to={routes.about}>About</Link>
              </li>
              <li>
                <Link to={routes.learnMore}>Learn more</Link>
              </li>
              <li>
                <Link to={routes.termsOfUse}>Terms of Use</Link>
              </li>
              <li>
                <Link to={routes.privacyPolicy}>Privacy Policy</Link>
              </li>
            </ul>
          </nav>
          <p className="mb-20">
            Contact us at <a href="mailto:info@oversubscribe.co">info@oversubscribe.co</a>
          </p>
          <div className="separator-inline" />
          <p className="small">OverSubscribe &copy; {new Date().getFullYear()}</p>
        </div>
      </div>
    </div>
  </footer>
)
