import React from 'react'
import T from 'prop-types'
import { BaseField } from './base-field'

export class TextInput extends BaseField {
  static propTypes = {
    ...BaseField.propTypes,
    autoComplete: T.string,
    disabled: T.bool,
    name: T.string.isRequired,
    onBlur: T.func,
    onValidate: T.func.isRequired,
    type: T.oneOf(['text', 'number', 'email', 'date', 'tel', 'search', 'password', 'url']),
    validation: T.func,
    validationMessages: T.shape({
      valueMissing: T.string,
      badInput: T.string,
      typeMismatch: T.string,
      patternMismatch: T.string,
      rangeOverflow: T.string,
      rangeUnderflow: T.string,
      stepMismatch: T.string,
      tooLong: T.string,
      tooShort: T.string,
    }),
  }
  static defaultProps = {
    type: 'text',
    autoComplete: 'off',
    validationMessages: {},
  }

  /** @type {HTMLInputElement} */
  element = null

  setElement = element => {
    this.element = element
    if (!element) return
    // update the DOM element validity
    this.resetCustomValidity()
    // let the parent form knows about this control
    this.broadcastUpdates({ value: this.props.defaultValue || null })
  }

  onBlur = e => {
    this.validate()
    this.props.onBlur && this.props.onBlur(e)
  }

  onInput = event => {
    const { value } = event.target
    // update the DOM element validity
    this.resetCustomValidity({ value })
    // let the parent form knows about changes
    this.broadcastUpdates({ value })
    // after the first blur event / when user tried to submit
    this.state.touched && this.validate()
  }

  /** @override */
  getValidationMessage() {
    return this.element
      ? getInputValidationMessage(this.element, this.props.validationMessages)
      : super.getValidationMessage()
  }

  /**
   * @public
   * @override
   */
  validate() {
    this.props.validation && this.resetCustomValidity()
    this.setState({ touched: true }, () => {
      this.broadcastValidation({
        message: getInputValidationMessage(this.element, this.props.validationMessages),
      })
    })
  }

  /**
   * @override
   * @returns {boolean}
   */
  isValid(value) {
    if (!this.element) {
      return false
    }
    if (!this.element.validity.valid) {
      return false
    }
    if (this.checkCustomValidity(value)) {
      return false
    }
    return true
  }

  /**
   * @override
   */
  setValue(value) {
    if (!this.element) return
    this.element.value = value
    const { valid } = this.element.validity
    const validationMessage = valid
      ? null
      : getInputValidationMessage(this.element, this.props.validationMessages)
    this.broadcastUpdates({ value: value || null, valid, validationMessage })
  }
  /**
   * @override
   */
  getValue() {
    if (!this.element) return undefined
    const value = (this.element.value || '').trim()
    if (this.props.type === 'number') {
      const number = parseFloat(value)
      return Number.isNaN(number) ? undefined : number
    }
    return value
  }

  resetCustomValidity({ value = this.getValue() } = {}) {
    if (!this.willValidate()) {
      return
    }
    if (!this.element) {
      throw new Error('this.element is undefined')
    }

    this.props.validation && this.element.setCustomValidity(this.checkCustomValidity(value))
  }

  componentDidUpdate(prevProps, prevState) {
    super.componentDidUpdate(prevProps, prevState)

    if (prevProps.validation !== this.props.validation) {
      const value = this.getValue()
      this.resetCustomValidity({ value })
      this.broadcastUpdates({ value })
      this.state.touched && this.validate()
    }
  }

  render() {
    const { disabled, validation, onValidate, validationMessages, ...props } = this.props
    const { pending } = this.context
    return (
      <input
        {...props}
        ref={this.setElement}
        id={props.id || props.name}
        onBlur={this.onBlur}
        onInput={this.onInput}
        disabled={pending || disabled}
      />
    )
  }
}

const VALIDITY_PROPS = [
  'valueMissing',
  'badInput',
  'typeMismatch',
  'patternMismatch',
  'rangeOverflow',
  'rangeUnderflow',
  'stepMismatch',
  'tooLong',
  'tooShort',
]

/**
 * @param {HTMLInputElement} input
 * @param {ValidityStateMessages} overrides
 */
function getInputValidationMessage(input, overrides = {}) {
  if (!input) return undefined
  const { validity } = input
  if (validity.valid) return null
  const invalidOverriddenProp = VALIDITY_PROPS.find(prop => validity[prop] && overrides[prop])
  return invalidOverriddenProp ? overrides[invalidOverriddenProp] : input.validationMessage
}

/**
 * @typedef {Object} ValidityStateMessages
 * @prop {String} [badInput]
 * @prop {String} [patternMismatch]
 * @prop {String} [rangeOverflow]
 * @prop {String} [rangeUnderflow]
 * @prop {String} [stepMismatch]
 * @prop {String} [tooLong]
 * @prop {String} [tooShort]
 * @prop {String} [typeMismatch]
 * @prop {String} [valueMissing]
 */
