/* eslint-disable react/no-danger */
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { contents, getContentByKey } from 'store/contents'
import { Waitress } from 'components/waitress'
import { ContentBody } from 'const/types'
import { useHistory } from 'react-router-dom'

const Heading = ({ text, level }) =>
  level === 1 ? (
    <h1 dangerouslySetInnerHTML={{ __html: text }} />
  ) : level === 2 ? (
    <h2 dangerouslySetInnerHTML={{ __html: text }} />
  ) : level === 3 ? (
    <h3 dangerouslySetInnerHTML={{ __html: text }} />
  ) : level === 4 ? (
    <h4 dangerouslySetInnerHTML={{ __html: text }} />
  ) : level === 5 ? (
    <h5 dangerouslySetInnerHTML={{ __html: text }} />
  ) : level === 6 ? (
    <h6 dangerouslySetInnerHTML={{ __html: text }} />
  ) : (
    false
  )
Heading.propTypes = {
  level: PropTypes.oneOf([1, 2, 3, 4, 5, 6]),
  text: PropTypes.string,
}

const Paragraph = ({ text, className }) => (
  <p className={className} dangerouslySetInnerHTML={{ __html: text }} />
)
Paragraph.propTypes = {
  text: PropTypes.string.isRequired,
  className: PropTypes.string,
}

const Table = ({ content, className }) => {
  return (
    <>
      {content.map((elem, index) => {
        return (
          <tr key={index} className={className}>
            {elem.map((childElem, index) => (
              <td key={childElem + index}>
                <p dangerouslySetInnerHTML={{ __html: childElem }} />
              </td>
            ))}
          </tr>
        )
      })}
    </>
  )
}

const List = ({ items, className }) => {
  const {
    location: { hash },
  } = useHistory()
  const [hashItem, setHashItem] = useState('')
  useEffect(() => {
    if (items.length) {
      items.forEach(item => {
        if (item.includes(`href="${hash}"`)) {
          setHashItem(item)
        }
      })
    }
  }, [items, hash])
  return (
    <ul className={className}>
      {items.map((elem, index) => {
        return (
          <li
            {...(hashItem === elem ? { 'aria-current': 'page' } : {})}
            key={index}
            dangerouslySetInnerHTML={{ __html: elem }}
          />
        )
      })}
    </ul>
  )
}
const Components = {
  paragraph: Paragraph,
  header: Heading,
  table: Table,
  list: List,
}

export const PureContentViewer = React.forwardRef(
  ({ getContent, content, defaultContent, className, ...props }, ref) => {
    const blocks = (content || defaultContent || { blocks: [] }).blocks
    return (
      <Waitress fn={[getContent]}>
        {blocks &&
          blocks
            .filter(elem => elem.data.text !== '&nbsp;')
            .map(({ type, data }, index) => {
              const Component = Components[type]
              // eslint-disable-next-line react/no-array-index-key
              return Component ? (
                <Component
                  {...props}
                  ref={ref}
                  key={type + index}
                  {...data}
                  className={className}
                />
              ) : (
                false
              )
            })}
      </Waitress>
    )
  },
)

PureContentViewer.propTypes = {
  content: ContentBody,
  defaultContent: ContentBody,
  getContent: PropTypes.func.isRequired,
  className: PropTypes.string,
}

export const ContentViewer = connect(
  createStructuredSelector({
    content: contents.contentByKey,
  }),
  (dispatch, { contentKey }) => ({
    getContent: () => dispatch(getContentByKey(contentKey)),
  }),
)(PureContentViewer)

ContentViewer.propTypes = {
  contentKey: PropTypes.string.isRequired,
}
