import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'

import { Link } from 'ui/link'
import { Form, SubmitButton } from 'ui/form'
import { Field } from 'ui/field'
import { routes } from 'const/routes'
import { login } from 'store/auth'
import { getUserAccount } from 'store/user'
import { getContact } from 'store/register-issuer'
import { InvestingInfoBlock } from 'pages/landing/InvestingInfoBlock'
import {
  RouteProps,
  ISSUER_PENDING_RESERVED,
  ISSUER_PENDING_REVIEW,
  ISSUER_REJECTED_RESERVED,
  ISSUER_REJECTED_REVIEW,
  ISSUER_ACCEPTED_RESERVED,
} from 'const/types'

import { FB_URL, GOOGLE_URL } from 'const/auth-links'
import { withLocationState } from 'utils/url'

const LoginPageComponent = ({ login, location }) => {
  const postfix =
    location.state && location.state.redirect ? `?redirect_to=${location.state.redirect}` : ''
  return (
    <div className="container">
      <div className="auth-block">
        <h2 className="text-center">Welcome back!</h2>
        <div className="auth-form">
          <Form onSubmit={login} className="form single-col mb-40">
            <Field type="email" name="email" label="Email" required autoComplete="email username" />

            <Field
              type="password"
              name="password"
              label="Password"
              required
              autoComplete="password"
            />

            <SubmitButton className="btn w-100">Login</SubmitButton>
          </Form>

          <p className="text-center text-uppercase mb-40">or</p>

          {/* <a href={GOOGLE_URL + postfix} className="btn w-100 mb-20">
            <span className="btn-google" /> Login with Google
          </a> */}
          {/* <a href={FB_URL + postfix} className="btn w-100 mb-60">
            <span className="btn-facebook" /> Login with Facebook
          </a> */}

          <p className="text-center mb-60">
            Don’t have an account?{' '}
            <Link to={withLocationState(routes.signUp, location)} className="bold color-blue">
              Sign up
            </Link>
          </p>
          <p className="text-center mb-60">
            Forgot password? <br /> <br />
            <Link to={routes.passwordRecovery} className="bold color-blue">
              Password recovery page
            </Link>
          </p>
        </div>
      </div>
      <InvestingInfoBlock />
    </div>
  )
}

LoginPageComponent.propTypes = {
  location: RouteProps.location,
  login: PropTypes.func.isRequired,
}

export const LoginPage = withRouter(
  connect(
    null,
    (dispatch, props) => ({
      login: async data => {
        data.visitorId = "53e1a115e7e1e1b2e7a47ae91e49c423"
        await dispatch(login(data))
        await dispatch(getUserAccount())
        const contact = await dispatch(getContact())
        let redirectPath =
          (props.location && props.location.state && props.location.state.redirect) ||
          routes.landing
        const pendingSt = [
          ISSUER_PENDING_RESERVED,
          ISSUER_PENDING_REVIEW,
          ISSUER_REJECTED_RESERVED,
          ISSUER_REJECTED_REVIEW,
        ]
        if (contact && pendingSt.includes(contact.athlete_status)) {
          redirectPath = routes.issuerPendingApproval
        } else if (contact && contact.athlete_status === ISSUER_ACCEPTED_RESERVED) {
          redirectPath = routes.issuerFourthStep
        }
        props.history.push(redirectPath)
      },
    }),
  )(LoginPageComponent),
)
