import { compoundActionType, compoundErrorActionCreator } from '../utils'
import { REGISTER } from '../register'
import { timestampAfter } from 'utils'

export const AUTH = {
  LOGIN: compoundActionType('auth.login'),
  LOGOUT: compoundActionType('auth.logout'),
  REFRESH_TOKEN: compoundActionType('auth.refresh-tokens'),
}

export const login = compoundErrorActionCreator(AUTH.LOGIN)

export const logout = compoundErrorActionCreator(AUTH.LOGOUT)

export const setTokens = payload => ({ type: AUTH.REFRESH_TOKEN.SUCCESS, payload })

/**
 * @typedef {object} Auth
 * @prop {boolean} pending
 * @prop {string|null} error
 * @prop {Object.<String>} errors
 * @prop {User|null} user
 * @prop {string|null} accessToken
 * @prop {string|null} refreshToken
 * @prop {number|null} expiresAt
 * @prop {boolean} refreshing
 */
/** @type {Auth} */
export const initialState = {
  pending: false,
  error: null,
  accessToken: null,
  refreshToken: null,
  expiresAt: null,
}

/**
 * @param {Auth} state
 * @param {Action} action
 * @returns {Auth}
 */
export default (state = initialState, { type, payload = {} }) => {
  switch (type) {
    case AUTH.LOGIN.START:
    case AUTH.LOGOUT.START:
    case AUTH.REFRESH_TOKEN.START:
      return {
        ...state,
        pending: true,
        error: null,
      }
    case AUTH.LOGIN.SUCCESS:
    case REGISTER.CREATE_USER.SUCCESS:
    case AUTH.REFRESH_TOKEN.SUCCESS:
      return payload.access_token && payload.refresh_token
        ? {
            ...state,
            pending: false,
            accessToken: payload.access_token,
            refreshToken: payload.refresh_token,
            expiresAt: timestampAfter(payload.expires_in),
          }
        : state

    case AUTH.LOGOUT.SUCCESS:
    case AUTH.REFRESH_TOKEN.FAILURE:
      return initialState

    case AUTH.LOGIN.FAILURE:
    case AUTH.LOGOUT.FAILURE:
      return {
        ...state,
        pending: false,
        error: payload.error,
      }

    default:
      return state
  }
}

/** @typedef {import('../index').Action} Action */
