import { createSelector } from 'reselect'

export const rawSaved = state => state.saved

export const byId = createSelector(
  rawSaved,
  ({ byId }) => byId || null,
)

export const isIdSaved = (state, { uuid } = {}) => !!(state.saved.byId && state.saved.byId[uuid])

export const savedOfferingIds = state => (state.saved.byId && Object.keys(state.saved.byId)) || []
