import ReactPlayer from 'react-player'
import React from 'react'
const Video = ({ className, videoClass, url, config, controls = true }) => {
  return (
    <div className={className}>
      <ReactPlayer
        width="100%"
        height="100%"
        className={videoClass}
        controls={controls}
        config={config}
        url={url}
      />
    </div>
  )
}

export default Video
