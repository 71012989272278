import { createSelector } from 'reselect'
// import { COUNTRIES_WITH_SSN, COUNTRIES_WITH_STATES } from 'const'

/** @type {function(State):Auth} */
const rawAuth = state => state.auth

/** @type {function(State):boolean} */
export const pending = createSelector(
  rawAuth,
  /** @param {Auth} auth */
  auth => auth.pending,
)

/** @type {function(State):string} */
export const error = createSelector(
  rawAuth,
  /** @param {Auth} auth */
  auth => auth.error,
)

/** @type {function(State):User} */
export const user = createSelector(
  rawAuth,
  /** @param {Auth} auth */
  auth => auth.user,
)

/** @type {function(State):string} */
export const authData = createSelector(
  rawAuth,
  ({ accessToken, refreshToken, expiresAt, pending }) => ({
    accessToken,
    refreshToken,
    expiresAt,
    pending,
  }),
)

/** @typedef {import('.').Auth} Auth */
/** @typedef {import('../index').State} State */
/** @typedef {import('../auth').User} User */
/** @typedef {import('../countries').Country} Country */
