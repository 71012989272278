import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import { updatePassword } from 'store/user'
import { routes } from 'const/routes'
import { Form, SubmitButton, FormControl, Invalid } from 'ui/form'
import { getParamFromUrl } from 'utils/url'
import { PasswordField } from 'ui/field/PasswordField'
import './password.css'
import { ValidationMessage } from 'ui/validation-message'

const PurePasswordReset = ({ redirect, onSubmit }) => {
  const [show, setShow] = useState(false)
  const validation = ({ password, confirm_password }) => {
    if (!password || !confirm_password) return null

    if (password !== confirm_password) {
      setShow(true)
      return 'Your password and confirmation password do not match'
    }
  }

  return (
    <div className="container password-recovery">
      <div className="auth-block">
        <h2 className="text-center">Please set a new password</h2>
        <div className="auth-form">
          <Form
            onSubmit={onSubmit}
            redirect={redirect}
            className="form single-col mb-40"
            validation={validation}
          >
            <FormControl>
              {({ values }) => {
                return (
                  <>
                    <PasswordField
                      required
                      type="password"
                      name="password"
                      className="password-lable"
                      label="Password"
                      autoComplete="new-password"
                    />
                    <PasswordField
                      required
                      type="password"
                      name="confirm_password"
                      className="password-lable"
                      label="Confirmation password"
                      autoComplete="new-password"
                      validation={() => validation(values)}
                      noMessage
                    />

                    {show && (
                      <Invalid>
                        {' '}
                        <ValidationMessage error>
                          {'Your password and confirmation password do not match'}
                        </ValidationMessage>
                      </Invalid>
                    )}
                  </>
                )
              }}
            </FormControl>
            <SubmitButton className="btn w-100">Update Password</SubmitButton>
          </Form>
        </div>
      </div>
    </div>
  )
}

PurePasswordReset.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  redirect: PropTypes.func.isRequired,
}

export const PasswordReset = withRouter(
  connect(
    null,
    (dispatch, { location, history }) => ({
      onSubmit: data => {
        const confirmationToken = getParamFromUrl(location.search, 'confirmationToken')
        return dispatch(updatePassword({ ...data, confirmationToken }))
      },
      redirect: () => {
        history.push(routes.landing)
      },
    }),
  )(PurePasswordReset),
)
