import { createSelector } from 'reselect'
export const BLANK_BODY = { time: 0, blocks: [], version: '2.11.10' }
export const mapByKey = state => state.contents.byKey

const contentKeyFromProps = (_, { contentKey }) => contentKey

export const contentByKey = createSelector(
  contentKeyFromProps,
  mapByKey,
  (key, byKey) => (key && byKey && byKey[key] ? byKey[key] : null),
)

export const contentBlocksByKey = createSelector(
  contentByKey,
  content =>
    content && content.blocks
      ? content.blocks.map((block, index) => ({ ...block, key: block.type + '-' + index }))
      : null,
)

export const landingHeroContent = createSelector(
  mapByKey,
  byKey => (byKey && byKey['landing-hero']) || null,
)
