import React from 'react'
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  EmailShareButton,
  EmailIcon,
  LinkedinShareButton,
  LinkedinIcon,
} from 'react-share'
import PropTypes from 'prop-types'

export const MAIN_PATH = window.location.origin
const ShareLinks = ({ className, text, path }) => {
  const url = MAIN_PATH + path

  return (
    <div className={className}>
      <FacebookShareButton url={url} quote={text}>
        <FacebookIcon size={32} round={true} />
      </FacebookShareButton>
      <TwitterShareButton url={url} title={text}>
        <TwitterIcon size={32} round={true} />
      </TwitterShareButton>
      <EmailShareButton url={url} subject={text}>
        <EmailIcon size={32} round={true} />
      </EmailShareButton>
      <LinkedinShareButton url={url} title={text}>
        <LinkedinIcon size={32} round={true} />
      </LinkedinShareButton>
    </div>
  )
}

export default ShareLinks

ShareLinks.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
}
