import './field.css'
import React from 'react'
import PropTypes from 'prop-types'

import { ValidationMessage } from '../validation-message'
import { cn } from 'utils'
import { Label } from './Label'

export class BaseField extends React.PureComponent {
  static propTypes = {
    hideValidationMessage: PropTypes.bool,
    label: PropTypes.node,
    tooltip: PropTypes.node,
  }
  static defaultProps = {
    hideValidationMessage: false,
  }
  state = {
    touched: false,
    validationMessage: null,
    enabled: true,
  }

  element = React.createRef()

  onValidate = ({ message: validationMessage, enabled, touched = true }) => {
    this.updateState({ validationMessage, enabled, touched })
  }
  handleReset = () => {
    this.reset()
  }

  reset() {
    this.updateState({ touched: false, validationMessage: null, enabled: true })
  }

  set value(value) {
    if (this.element.current) this.element.current.value = value
  }
  get value() {
    return this.element.current ? this.element.current.value : undefined
  }

  updateState({ validationMessage, enabled, touched }) {
    this.setState(state => {
      if (
        validationMessage === state.validationMessage &&
        enabled === state.enabled &&
        touched === state.touched
      ) {
        return null
      }
      return { validationMessage, enabled, touched }
    })
  }

  renderField() {
    throw 'override renderField(props)'
  }

  getClassName(className) {
    return cn('field', className)
  }

  render() {
    const { label, hideValidationMessage, className, children, ...props } = this.props
    const { validationMessage, enabled } = this.state
    const showValidity = !hideValidationMessage && !!validationMessage && enabled
    return (
      <Label label={label} name={props.name} className={this.getClassName(className)}>
        {this.renderField({
          ...props,
          ref: this.element,
          className: cn('input', this.state.touched && 'touched'),
          onValidate: this.onValidate,
          onReset: this.handleReset,
        })}
        {children}
        {showValidity && <ValidationMessage error>{validationMessage}</ValidationMessage>}
      </Label>
    )
  }
}
