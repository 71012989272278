import './validation-message.css'
import React from 'react'
import T from 'prop-types'

import { cn } from 'utils'
// import IconValid from 'icons/validation-ok.svg'
// import IconInvalid from 'icons/exclamation-triangle.svg'

export const ValidationMessage = ({ className, error, success, children }) => {
  if (error && success) {
    throw new Error('ValidationMessage can not be "error" and "success" at the same time')
  }
  return (
    <div className={cn(className, 'validation-message', error && 'error', success && 'success')}>
      {/* {error && <IconInvalid className="icon" />}
      {success && <IconValid className="icon" />} */}
      <span>{children}</span>
    </div>
  )
}

ValidationMessage.propTypes = {
  className: T.string,
  error: T.bool,
  success: T.bool,
}
