import './image.css'
import React from 'react'
import PropTypes from 'prop-types'
import { BaseField } from './base-field'
import { cn } from 'utils'

export class ImageInput extends BaseField {
  static propTypes = {
    ...BaseField.propTypes,
    disabled: PropTypes.bool,
    getUrlFromUploadResult: PropTypes.func.isRequired,
    getValueFromUploadResult: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    onSrcChange: PropTypes.func.isRequired,
    onUpload: PropTypes.func.isRequired,
    onValidate: PropTypes.func,
    validation: PropTypes.func,
  }
  static defaultProps = {
    getValueFromUploadResult: ({ uuid } = {}) => uuid,
    getUrlFromUploadResult: ({ imageUrl } = {}) => imageUrl,
  }

  /** @type {HTMLInputElement} */
  element = null

  setElement = element => {
    this.element = element
  }

  handleChange = async event => {
    const file = event.target.files[0]
    const { onUpload, getValueFromUploadResult, onSrcChange, getUrlFromUploadResult } = this.props

    this.previewFile(file)

    if (file && onUpload) {
      const uploadResult = await onUpload(file)
      this.setValue(getValueFromUploadResult(uploadResult))
      if (onSrcChange) {
        onSrcChange(getUrlFromUploadResult(uploadResult))
      }
    }
  }

  previewFile(file) {
    const { onSrcChange } = this.props
    if (!onSrcChange) return
    if (file) {
      const reader = new FileReader()
      reader.onloadend = () => onSrcChange(reader.result)
      reader.readAsDataURL(file)
    } else {
      onSrcChange(null)
    }
  }

  /** @override */
  getValidationMessage() {
    return this.element
      ? getInputValidationMessage(this.element, this.props.validationMessages)
      : super.getValidationMessage()
  }

  /**
   * @public
   * @override
   */
  validate() {
    this.props.validation && this.resetCustomValidity()
    this.setState({ touched: true }, () => {
      this.broadcastValidation({
        message: getInputValidationMessage(this.element, this.props.validationMessages),
      })
    })
  }

  /**
   * @override
   * @returns {boolean}
   */
  isValid(value) {
    if (!this.element) {
      return false
    }
    if (!this.element.validity.valid) {
      return false
    }
    if (this.checkCustomValidity(value)) {
      return false
    }
    return true
  }

  clear() {
    const { onSrcChange } = this.props
    this.broadcastUpdates({ value: null })
    if (onSrcChange) {
      onSrcChange(null)
    }
  }

  reset() {
    const { onSrcChange, defaultSrc, defaultValue } = this.props
    this.broadcastUpdates({ value: defaultValue || null, touched: false })
    if (onSrcChange) {
      onSrcChange(defaultSrc || null)
    }
  }

  /**
   * @override
   */
  setValue(value) {
    // this.
    const { valid } = this.element.validity
    const validationMessage = valid ? null : getInputValidationMessage(this.element)
    this.broadcastUpdates({ value: value || null, valid, validationMessage })
  }
  /**
   * @override
   */
  getValue() {
    if (!this.element) return undefined
  }

  resetCustomValidity({ value = this.getValue() } = {}) {
    if (!this.willValidate()) {
      return
    }
    if (!this.element) {
      throw new Error('this.element is undefined')
    }

    this.props.validation && this.element.setCustomValidity(this.checkCustomValidity(value))
  }

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState) {
    const defaultValue = this.props.defaultValue
    if (prevProps.defaultValue !== defaultValue) {
      this.broadcastUpdates({ value: defaultValue })
    }
    // TODO:
    // super.componentDidUpdate(prevProps, prevState)
    // if (prevProps.validation !== this.props.validation) {
    //   const value = this.getValue()
    //   this.resetCustomValidity({ value })
    //   this.broadcastUpdates({ value })
    //   this.state.touched && this.validate()
    // }
  }

  render() {
    const {
      disabled,
      validation,
      onValidate,
      validationMessages,
      className,
      defaultValue,
      getValueFromUploadResult,
      getUrlFromUploadResult,
      onSrcChange,
      onUpload,
      label,
      children,
      defaultSrc,
      ...props
    } = this.props
    const { pending } = this.context
    return (
      <label className={cn('image-input', className)}>
        <input
          {...props}
          className="file-input"
          type="file"
          ref={this.setElement}
          id={props.id || props.name}
          onChange={this.handleChange}
          disabled={pending || disabled}
        />
        {children}
      </label>
    )
  }
}

/**
 * @param {HTMLInputElement} input
 */
function getInputValidationMessage(input) {
  if (!input) return undefined
  const { validity } = input
  if (validity.valid) return null
  return input.validationMessage
}

/**
 * @typedef {Object} ValidityStateMessages
 * @prop {String} [badInput]
 * @prop {String} [patternMismatch]
 * @prop {String} [rangeOverflow]
 * @prop {String} [rangeUnderflow]
 * @prop {String} [stepMismatch]
 * @prop {String} [tooLong]
 * @prop {String} [tooShort]
 * @prop {String} [typeMismatch]
 * @prop {String} [valueMissing]
 */
