import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { SearchComponent } from 'components/search-component'
import { FilterDropdown } from 'components/filter-dropdown'
import { Waitress } from 'components/waitress'
import { getStates, selectors as statesSelectors } from 'store/states'
import { Form } from 'ui/form'

const countryOptions = {
  usa: 'USA',
  canada: 'Canada',
}

const followersOpt = {
  first: '0-24,999',
  second: '25,000-99,999',
  third: '100,000-249,999',
  fourth: '250,000-999,999',
  fifth: '1,000,000+',
}

const platformOptions = {
  youtube: 'Youtube',
  inst: 'Instagram',
  tiktok: 'TikTok',
  twitch: 'Twitch',
  podcast: 'Podcast',
  subsctack: 'Subsctack',
  other: 'other',
}

export const PureSearchOptions = ({ getStates }) => (
  <Waitress fn={[getStates]}>
    <Form className="search-options mb-20" onSubmit={() => {}}>
      <div className="row">
        <div className="col-12 col-sm-8 offset-sm-2 col-md-6 offset-md-3 offset-lg-0 col-lg-3 mb-20">
          <SearchComponent placeholder="Search by name" name="name" />
        </div>

        <div className="col-12 col-sm-8 offset-sm-2 col-md-6 offset-md-3 offset-lg-0 col-lg-3 mb-20 filter-dropdown">
          <FilterDropdown
            name="platform"
            searchParam="platform"
            label=""
            options={platformOptions}
            placeholder="Main Platform"
          />
        </div>

        <div className="col-12 col-sm-8 offset-sm-2 col-md-6 offset-md-3 offset-lg-0 col-lg-3 mb-20 filter-dropdown">
          <FilterDropdown
            name="followers"
            searchParam="followers"
            options={followersOpt}
            placeholder="Followers/subscribers"
          />
        </div>
        <div className="col-12 col-sm-8 offset-sm-2 col-md-6 offset-md-3 offset-lg-0 col-lg-3 mb-20 filter-dropdown">
          <FilterDropdown
            name="country"
            searchParam="country"
            options={countryOptions}
            placeholder="Country"
          />
        </div>
      </div>
    </Form>
  </Waitress>
)

PureSearchOptions.propTypes = {
  getStates: PropTypes.func.isRequired,
}

export const SearchOptions = connect(
  createStructuredSelector({
    regionOptions: statesSelectors.stateOptions,
  }),
  {
    getStates,
  },
)(PureSearchOptions)
