import './progress.css'
import React from 'react'
import PropTypes from 'prop-types'

import { cn } from 'utils'

export const Progress = ({ value: startedValue, max, minAmount: finalGoal, className, raised }) => {
  const width = Math.min(Math.round((value / max) * 100), 100)

  const value = startedValue > max ? max : startedValue
  const isReachFinalGoal = value > finalGoal
  const finalGoalWidth = 70
  const maxAmountWidth = Math.min(Math.round(((value - finalGoal) / (max - finalGoal)) * 30), 100)
  const progress = isReachFinalGoal
    ? finalGoalWidth
    : Math.min(Math.round((value / finalGoal) * finalGoalWidth), 100)
  return (
    <div className={cn('progress landing-progress', className)}>
      <div
        className={`progress-value ${isReachFinalGoal && 'reached'}`}
        style={{
          width: `${progress > 100 ? 100 : progress}%`,
        }}
      />
      {isReachFinalGoal && (
        <div
          className="progress-value-reached "
          style={{
            width: `${maxAmountWidth}%`,
          }}
        />
      )}
      <div
        className="goal-point-value"
        style={{
          left: `${finalGoalWidth}%`,
        }}
      />
      <div
        className="goal-point-value blue"
        style={{
          left: `100%`,
        }}
      />
      {raised && (
        <span className={width === 100 ? 'raised raised100' : 'raised'}>{width}% raised</span>
      )}
    </div>
  )
}
Progress.propTypes = {
  max: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
  raised: PropTypes.bool,
  minAmount: PropTypes.number.isRequired,
}
Progress.defaultProps = {
  value: 0,
  max: 1,
  minAmount: 200,
  raised: false,
}
