import { all, put, select } from 'redux-saga/effects'
import { attachSideEffect } from '../utils'
import { SAVED, saved } from '.'
import * as api from 'api'
import { callWithAuth } from '../auth/saga'

export default function*() {
  yield all([
    attachSideEffect(SAVED.GET_OFFERING_LIST, onGetSavedOfferingList),
    attachSideEffect(SAVED.ADD_OFFERING, onAddGolfer),
    attachSideEffect(SAVED.DELETE_OFFERING, onDeleteGolfer),
    attachSideEffect(SAVED.TOGGLE_OFFERING, onToggleGolfer),
  ])
}

function* onGetSavedOfferingList() {
  const result = yield* callWithAuth(api.saved.getList)
  return (result && Object.fromEntries(result.items.map(item => [item.uuid, item]))) || null
}

function* onAddGolfer(uuid) {
  const result = yield* callWithAuth(api.saved.addGolfer(uuid))
  // eslint-disable-next-line no-console
  console.assert(result && result.uuidGolfer === uuid, 'Must be equal')
  return result
}

function* onDeleteGolfer(uuid) {
  yield* callWithAuth(api.saved.deleteGolfer, uuid)
  // console.assert(result && result.uuidGolfer === uuid, 'Must be equal')
  return { uuid }
}

function* onToggleGolfer(uuid) {
  const isSaved = yield select(saved.isIdSaved, { uuid })
  if (isSaved) {
    yield* onDeleteGolfer(uuid)
    yield put({ type: SAVED.DELETE_OFFERING.SUCCESS, payload: uuid })
  } else {
    const savedItem = yield* onAddGolfer(uuid)
    yield put({ type: SAVED.ADD_OFFERING.SUCCESS, payload: savedItem })
  }
}
