import './menu.css'
import React from 'react'
import { NavLink } from 'react-router-dom'
import T from 'prop-types'
import { cn } from 'utils'
import { blurEventTarget } from 'utils/dom'

const getClickHandler = callback =>
  callback
    ? event => {
        blurEventTarget(event)
        callback(event)
      }
    : blurEventTarget

export const Menu = ({ className, ...props }) => {
  return <div {...props} className={cn('menu', className)} role="menu" />
}
Menu.propTypes = {}

const MenuLinkComponent = ({ className, onClick, disabled, ...props }) => {
  if (disabled) {
    return <span {...props} className={cn('menu-item disabled', className)} />
  }
  return (
    <NavLink
      {...props}
      activeClassName="active"
      className={cn('menu-item', className)}
      role="menuitem"
      onClick={getClickHandler(onClick)}
    />
  )
}
MenuLinkComponent.propTypes = {
  disabled: T.bool,
  onClick: T.func,
}
export const MenuLink = React.memo(MenuLinkComponent)

const MenuButtonComponent = ({ className, onClick, ...props }) => (
  <button
    {...props}
    type="button"
    className={cn('menu-item', className)}
    role="menuitem"
    onClick={getClickHandler(onClick)}
  />
)
MenuButtonComponent.propTypes = {
  onClick: T.func,
}
export const MenuButton = React.memo(MenuButtonComponent)
