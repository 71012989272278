import React from 'react'
import './study-details.css'
export const ExampleCard = ({ text, iconSrc, title }) => {
  return (
    <div className="col-12 col-lg-3 rounded-2 background-white p-4">
      <div className="fs-2 fw-bold pb-3">{title}</div>
      <div className="picture-con">
        <img src={iconSrc} />
      </div>
      <p className="font-20 pt-4">{text}</p>
    </div>
  )
}
