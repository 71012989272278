import React from 'react'

export const contextDefaultValue = {
  invalid: false,
  pending: false,
  values: {},
  error: null,
  errors: {},
  statusListener: ({ name }) => {
    throw `The "${name}" field must be a child of <Form/>`
  },
}

export const FormContext = React.createContext(contextDefaultValue)

export const { Provider, Consumer } = FormContext
