import { all, select, debounce } from 'redux-saga/effects'
import { ACTIONS, selectors } from '.'
import * as api from 'api'
import { attachSideEffect } from '../utils'
import { callWithAuth } from 'store/auth/saga'

export default function*() {
  yield all([
    attachSideEffect(ACTIONS.GET_BANK_INFO, onGetBankInfo, (...args) => debounce(500, ...args)),
  ])
}

/** @returns {BankAccount} */
export function* onGetBankInfo(routing_number) {
  const accounts = yield select(selectors.accountsMap)
  if (accounts[routing_number]) {
    return accounts[routing_number]
  }

  const result = yield* callWithAuth(api.banks.getInfo(routing_number))
  return result
}

/** @typedef {import(".").BankAccount} BankAccount */
