import './header-navhar-menu.css'
import React from 'react'
import { DropdownToggle } from 'ui/dropdown-toggle'
import { HeaderNav } from 'pages/Header'

export const HeaderNavbarMenu = () => {
  const label = (
    <div className="styled-burger">
      <span className="line"></span>
      <span className="line"></span>
      <span className="line"></span>
    </div>
  )

  return (
    <>
      <DropdownToggle label={label} icon={null} className="header-nav-menu account-menu mobile">
        <hr />
        <HeaderNav />
        <hr />
      </DropdownToggle>
      <HeaderNav className="desktop" />
    </>
  )
}
