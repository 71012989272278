const getHashDefault = (...args) => args[0]

export const singletone = (fn, getHash = getHashDefault) => {
  let currentHash
  let currentRequest
  let currentResolved
  let currentRejected
  return (...args) => {
    const hash = getHash(...args)
    if (hash === currentHash) {
      if (currentResolved) return Promise.resolve(currentResolved)
      if (currentRejected) return Promise.reject(currentRejected)
      return currentRequest
    }
    currentHash = hash
    currentResolved = undefined
    currentRejected = undefined
    currentRequest = fn(...args)
      .then(result => {
        currentResolved = result
        return result
      })
      .catch(error => {
        currentRejected = error
        throw error
      })
      .finally(() => {
        currentRequest = undefined
      })
    return currentRequest
  }
}
