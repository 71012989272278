import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { Link } from 'ui/link'
import { routes } from 'const/routes'
import { investor, getActiveInvestorsAmount } from 'store/investor'
import { Waitress } from 'components/waitress'

const PureSignUpBlock = ({ totalActiveInvestors, getActiveInvestorsAmount }) => (
  <Waitress fn={[getActiveInvestorsAmount]}>
    <div className="join-block text-center">
      {/* {totalActiveInvestors ? (
        <h3 className="mb-20">
          Join the community of {totalActiveInvestors} investor
          {totalActiveInvestors > 1 ? 's.' : '.'}
        </h3>
      ) : (
        false
      )} */}
      <h3 className="mb-20">Join the community of fans investing in their favorite creators</h3>
      <Link to={routes.signUp} theme="primary">
        Sign up as an investor
      </Link>
      {/* <div className="container">
        <hr />
        <h3 className="mb-10">Are you a student?</h3>
        <p className="mb-20">
          Are you a student? We understand there is a lot involved in raising funding. We’re here to
          help.
        </p>
        <Link to={routes.whyWork} theme="primary">
          Learn more & get funding
        </Link>
      </div> */}
    </div>
  </Waitress>
)

PureSignUpBlock.propTypes = {
  getActiveInvestorsAmount: PropTypes.func.isRequired,
  totalActiveInvestors: PropTypes.number,
}

export const SignUpBlock = connect(
  createStructuredSelector({
    totalActiveInvestors: investor.totalActiveInvestors,
  }),
  {
    getActiveInvestorsAmount,
  },
)(PureSignUpBlock)
