import {all, call, select} from 'redux-saga/effects'

import * as api from 'api'
import { attachSideEffect } from '../utils'
import { REGISTER_ISSUER } from '.'
import {user} from "../user";
import { callWithAuth } from 'store/auth/saga'

export default function*() {
  yield all([
    attachSideEffect(REGISTER_ISSUER.HS_CONFIRM, hsConfirm),
    attachSideEffect(REGISTER_ISSUER.GET_CONTACT, getContact),
    attachSideEffect(REGISTER_ISSUER.POST_CONTACT, postContact),
    attachSideEffect(REGISTER_ISSUER.PATCH_CONTACT, patchContact),
  ])
}

function* hsConfirm(data) {
  const result = yield call(api.hellosign.hsConfirm, data)
  return result
}

function* getContact() {
  const account = yield select(user.account)
  return account && account.email ? yield call(api.hubspot.getContact, account.email) : null
}

function* postContact(data) {
  const result = yield call(api.hubspot.postContact, data)
  return result
}

function* patchContact(data) {
  const {id} = data
  const result = yield* callWithAuth(api.hubspot.patchContact(id), data)
  return result
}
