import { compoundActionType, compoundErrorActionCreator } from '../utils'
// import { routes } from 'const/routes'
import * as contents from './selectors'

export { contents }

export const CONTENTS = {
  GET_BY_KEY: compoundActionType('contents.get-by-key'),
  UPDATE_BY_KEY: compoundActionType('contents.update-by-key'),
  UPDATE_LANDING_HERO: compoundActionType('contents.update-landing-hero'),
  UPLOAD_IMAGE: compoundActionType('contents.upload-image'),
}

export const getContentByKey = compoundErrorActionCreator(CONTENTS.GET_BY_KEY)
export const updateContentByKey = compoundErrorActionCreator(CONTENTS.UPDATE_BY_KEY)
export const getLandingHeroContent = () => getContentByKey('landing-hero')
export const updateLandingHeroContent = content =>
  updateContentByKey({ key: 'landing-hero', content })
export const uploadImage = compoundErrorActionCreator(CONTENTS.UPLOAD_IMAGE)

export const initialState = {
  byKey: {},
  pending: false,
  error: null,
}

/**
 * @param {Countries} state
 * @param {Action} param1
 * @returns {Countries}
 */
export default function(state = initialState, { type, payload }) {
  switch (type) {
    case CONTENTS.GET_BY_KEY.START:
    case CONTENTS.UPDATE_BY_KEY.START:
      return {
        ...state,
        pending: true,
        error: null,
      }
    case CONTENTS.GET_BY_KEY.SUCCESS:
    case CONTENTS.UPDATE_BY_KEY.SUCCESS:
      return {
        ...state,
        pending: false,
        byKey:
          payload && payload.key && payload.body
            ? { ...state.byKey, [payload.key]: payload.body }
            : state.byKey,
      }
    case CONTENTS.GET_BY_KEY.FAILURE:
    case CONTENTS.UPDATE_BY_KEY.FAILURE:
      return {
        ...state,
        pending: false,
        error: payload.error,
      }
    default:
      return state
  }
}

/** @typedef {import('..').Action} Action */
