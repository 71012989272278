import React from 'react'

export const accordionStudyText = [
  {
    id: 1,
    title: 'Isn’t this just a regular student loan?',
    text: (
      <>
        {' '}
        <p>
          No, a traditional student loan requires you to pay back a fixed amount of principal plus
          interest every month, regardless of your employment status and life circumstances.
        </p>
        <p>
          With our Income-based Financing, your payment amount will flex with your earnings but
          always remain a fixed percentage of those earnings, and you will not be required to pay
          anything if your annualized earnings are below $45,000 in any month.
        </p>
      </>
    ),
  },
  {
    id: 2,
    title: 'How much can I finance with IBF?',
    text: (
      <p>
        Our IBF program will currently finance 100% of your $17,500 tuition for a full 26-week
        program with Launch Academy.
      </p>
    ),
  },
  {
    id: 3,
    title: 'Would I need to start paying this while I’m in school?',
    text: (
      <p>
        No, your payments would not begin until after you graduate, and your first payment would be
        30 days after you start earning $45,000 per year or more, post-graduation.
      </p>
    ),
  },
  {
    id: 4,
    title: 'Who is eligible to apply for Income-based Financing?',
    text: (
      <>
        <p>Our Income-based Financing application is currently available to students who:</p>
        <ul>
          <li>Are already accepted to Launch Academy’s program</li>
          <li>Are US citizens</li>
          <li>Are 18 years or older</li>
          <li>Have not committed a felony in the US or filed for bankruptcy in the last 7 years</li>
        </ul>
        <p></p>
      </>
    ),
  },
  {
    id: 5,
    title: 'What is the process to apply for Income-based Financing?',
    text: (
      <p>
        If you meet the eligibility requirements for IBF (see above), just submit your email address
        here on this website. We will confirm your acceptance status with the school and then send
        you an email with next steps, including instructions for how to sign the agreement and for
        setting up a consultation on Zoom with our IBF specialists.
      </p>
    ),
  },
  {
    id: 6,
    title: 'Can I pay off the IBF early?',
    text: (
      <p>
        Yes, the exact amount to prepay your IBF will vary depending on how much time has passed and
        how much you made in earlier payments. However, the total prepay amount will always be below
        the 1.5x payment cap.
      </p>
    ),
  },
  {
    id: 7,
    title: 'Is this any different from an Income Share Agreement?',
    text: (
      <p>
        Our Income-based Financing shares the same structure and format as the ISAs offered by some
        other bootcamps, though our terms may be slightly better.
      </p>
    ),
  },
  {
    id: 8,
    title: 'What happens if I withdraw early from Launch Academy?',
    text: (
      <p>
        Depending on the timing of your withdrawal, you may still be required to adhere to the terms
        of the IBF. If your withdrawal includes a tuition refund, your terms will be adjusted to
        reflect the remaining financing amount.
      </p>
    ),
  },
]
