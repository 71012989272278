import 'pages/landing/styles.css'
import './styles.css'

import React from 'react'

import { AdminHeroBanner } from './AdminHeroBanner'
import { AdminInvestingInfoBlock } from './AdminInvestingInfoBlock'
import { ArticlesList } from 'pages/landing/ArticlesList'
import { Search } from 'pages/landing/Search'
import { ContentEditor } from 'components/content-editor'

export class AdminLandingPage extends React.PureComponent {
  static propTypes = {}

  render() {
    return (
      <div className="home">
        <AdminHeroBanner />
        <div className="container">
          <div className="risk-info">
            <ContentEditor contentKey="landing/risk-info" defaultContent={defaultRiskText[0]} />
          </div>
          <ArticlesList title="Ending soon" />
          <Search />
          <AdminInvestingInfoBlock />
        </div>
      </div>
    )
  }
}

export const defaultRiskText = [
  ` Investments on the OverSubscribe platform can carry a high degree of risk, result in
  a complete loss, and be difficult or impossible to sell. <a href="/learn-more">Read more here</a>`,
].map(text => ({ blocks: [{ type: 'paragraph', data: { text } }] }))
