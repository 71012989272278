import { compoundActionType, compoundErrorActionCreator } from '../utils'

import * as selectors from './selectors'

export { selectors }

export const GET_LIST = compoundActionType('states.get-list')

export const getStates = compoundErrorActionCreator(GET_LIST)

/**
 * @typedef {Object} States
 * @prop {Object.<string,Country>} byId
 * @prop {boolean} pending
 * @prop {string|null} error
 */

/** @type {States} */
export const initialState = {
  byCode: {},
  pending: false,
  error: null,
}

/**
 * @param {States} state
 * @param {Action} param1
 * @returns {States}
 */
export default function(state = initialState, { type, payload }) {
  switch (type) {
    case GET_LIST.START:
      return {
        ...state,
        pending: true,
        error: null,
      }
    case GET_LIST.SUCCESS:
      return {
        ...state,
        pending: false,
        byCode: payload,
      }
    case GET_LIST.FAILURE:
      return {
        ...state,
        pending: false,
        error: payload.error,
      }
    default:
      return state
  }
}

/** @typedef {import('../.').Action} Action */
