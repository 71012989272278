import React from 'react'
import { Dialog } from 'ui/dialog'
import { Button } from 'ui/button'
import PropTypes from 'prop-types'
import { Form, SubmitButton } from 'ui/form'
import './material-change-styles.css'
export const MaterialConfirmDialog = ({ onClose, onSubmit, changedTitle = 'confirm' }) => {
  return (
    <Dialog className="material-change-dialog" title="Material Change" onClose={onClose}>
      <Form onSubmit={onSubmit} className="material-change">
        <h3 className="material-change-title">
          You are selecting to {changedTitle.toUpperCase()} your investment
        </h3>
        <div className="dialog-btns">
          <SubmitButton className="btn btn-primary">Continue</SubmitButton>
          <Button onClick={onClose} className="btn btn-primary canc">
            Cancel
          </Button>
        </div>
      </Form>
    </Dialog>
  )
}

MaterialConfirmDialog.propTypes = {
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  changedTitle: PropTypes.string,
}
