import { select, all } from 'redux-saga/effects'
import { WIRE_INSTRUCTIONS, wire } from '.'
import { attachSideEffect } from '../utils'
import * as api from 'api'
import { callWithAuth } from '../auth/saga'

export default function*() {
  yield all([attachSideEffect(WIRE_INSTRUCTIONS.GET_WIRE_INSTRUCTIONS, onGetWireInstructions)])
}

function* onGetWireInstructions(uuid) {
  const state = yield select()
  const hasWire = yield wire.wireById(state, { uuid })
  if (hasWire) return
  const result = yield callWithAuth(api.wireInstructions.getWire(uuid))
  return { [uuid]: result }
}
