/**
 *
 * @param  {...String|null} names
 * @returns {String}
 */
import * as moment from 'moment'

export const cn = (...names) =>
  names.filter(value => !!value && typeof value === 'string').join(' ')

/**
 * @param {String} value - date in ISO (YYYY-MM-DD) format
 * @returns {String} date in server-side format
 */
export const toServerDate = value => (value ? new Date(value).toLocaleDateString('en-US') : null)

export const toISODate = value => {
  const date = new Date(Date.parse(value))
  if (!date || isNaN(date)) return null
  return [
    date.getFullYear(),
    (date.getMonth() + 1).toString().padStart(2, '0'),
    date
      .getDay()
      .toString()
      .padStart(2, '0'),
  ].join('-')
}

export const toHumanDate = value => {
  if (!value) return value
  // eslint-disable-next-line import/namespace
  const date = moment(value, moment.ISO_8601)
  return date.format('MMM D, YYYY')
}
export const toHumanDateTime = value => {
  if (!value) return value
  // eslint-disable-next-line import/namespace
  const date = moment(value, moment.ISO_8601)
  return date.format('MMM D, YYYY [at] h:mma')
}

export const todayDate = () => new Date().toISOString().replace(/T.*/, '')

export const nowTimestamp = () => new Date().valueOf()
export const timestampAfter = seconds => (seconds ? nowTimestamp() + (seconds - 1) * 1000 : null)

export const isFutureTimestamp = timestamp => nowTimestamp() < timestamp

export const getAge = DOB => {
  const today = new Date()
  const birthDate = new Date(DOB)
  let age = today.getFullYear() - birthDate.getFullYear()
  const m = today.getMonth() - birthDate.getMonth()
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age = age - 1
  }
  return age
}

export const dateOfBirthValidation = () => {
  const date = new Date()
  date.setFullYear(date.getFullYear() - 18)
  return date.toISOString().replace(/T.*/, '')
}

export const isPast = date => {
  if (date) {
    // eslint-disable-next-line import/namespace
    const endDate = moment.utc(date, null, 'en')
    const isEnd = endDate.isBefore(moment().endOf('second'))
    return isEnd
  }
}

export const isIOS = () => navigator.userAgent.match(/ipad|iphone/i)

export const iosCopy = input => {
  const range = document.createRange()
  range.selectNodeContents(input)
  const selection = window.getSelection()
  selection.removeAllRanges()
  selection.addRange(range)
  input.setSelectionRange(0, 999999)
  document.execCommand('copy')
}
