import React from 'react'
import T from 'prop-types'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import { routes } from 'const/routes'
import { selectors } from 'store/password-recovery'
import { createStructuredSelector } from 'reselect'

import { Link } from 'ui/link'

const PureRecoveryCongrats = ({ mail }) => (
  <>
    <div className="text-center mb-60">
      <h2>CONGRATULATIONS</h2>
    </div>
    <div className="text-center">
      <p className="mb-20">
        We&apos;ve sent an email to <b>{mail}</b> with password reset instructions.
      </p>

      <Link className="bold color-blue" to={routes.landing}>
        Back to main page
      </Link>
    </div>
  </>
)

PureRecoveryCongrats.propTypes = {
  mail: T.string,
}

export const RecoveryCongrats = withRouter(
  connect(createStructuredSelector({ mail: selectors.recoveryMail }))(PureRecoveryCongrats),
)
