import { LIMIT_PARAM, SEARCH_PARAM, OFFSET_PARAM, ORDER_BY_PARAM } from 'const/list'

export const getParamFromUrl = (url, name, defaultValue = null) => {
  const urlSearchParam = new URLSearchParams(url.charAt(0) === '?' ? url.slice(1) : url)
  return urlSearchParam.has(name) ? urlSearchParam.get(name) : defaultValue
}

/**
 * @returns {String}
 */
export const fulfillUrlWithParam = (url, name, value = null) => {
  const urlSearchParam = new URLSearchParams(url.charAt(0) === '?' ? url.slice(1) : url)
  if (!value) {
    urlSearchParam.has(name) && urlSearchParam.delete(name)
  } else {
    urlSearchParam.set(name, value)
  }
  return '?' + urlSearchParam.toString()
}

export const getListParams = (url, { page = 1, sort, search, limit = 15 } = {}) => {
  const [orderBy, order] = (getParamFromUrl(url, ORDER_BY_PARAM, sort) || sort || '').split('=')
  return {
    limit: getParamFromUrl(url, LIMIT_PARAM, limit),
    search: getParamFromUrl(url, SEARCH_PARAM, search),
    page: getParamFromUrl(url, OFFSET_PARAM, page),
    orderBy,
    order,
  }
}

export const withLocationState = (path, location = window.location) =>
  location && location.state ? { pathname: path, state: location.state } : path

export const pathWithFutureRedirect = (path, futurePath) =>
  futurePath ? { pathname: path, state: { redirect: futurePath } } : path
