import PropTypes from 'prop-types'

export const RouteProps = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }),
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    replace: PropTypes.func.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.object,
    url: PropTypes.string.isRequired,
  }).isRequired,
}

export const RoutePath = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.exact({
    pathname: PropTypes.string.isRequired,
    state: PropTypes.exact({
      noScroll: PropTypes.bool,
      redirect: PropTypes.string,
    }),
  }),
])

export const AsyncProps = {
  pending: PropTypes.bool,
  error: PropTypes.string,
  errors: PropTypes.objectOf(PropTypes.string),
}

export const UserAccount = PropTypes.shape({
  email: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  fullName: PropTypes.string,
  phone: PropTypes.string,
  birthday: PropTypes.string,
  uuid: PropTypes.string.isRequired,
  // updatedAt: PropTypes.string.isRequired,
})

export const Address = PropTypes.shape({
  country: PropTypes.string,
  region: PropTypes.string,
  city: PropTypes.string,
  street1: PropTypes.string,
  street2: PropTypes.string,
  postalCode: PropTypes.string,
  createdAt: PropTypes.string,
  updatedAt: PropTypes.string,
  uuid: PropTypes.string, //
})

export const ACH = 'ACH'
export const WIRE = 'WIRE'
export const CREDIT_CARD = 'CREDITCARD'
export const PAYMENT_METHOD_TYPE = {
  [WIRE]: 'Wire',
  [ACH]: 'Ach',
  [CREDIT_CARD]: 'Credit-card',
}
export const PAYMENT_METHODS = Object.keys(PAYMENT_METHOD_TYPE)
export const ACCOUNT_TYPE = {
  checking: 'checking',
  savings: 'savings',
}
export const CHECK_TYPE = {
  business: 'Business',
  personal: 'Personal',
}
export const AchSettings = PropTypes.shape({
  uuid: PropTypes.string, // might be missing
  owner: PropTypes.string,
  routingNumber: PropTypes.string,
  accountNumber: PropTypes.string,
  accountType: PropTypes.oneOf(Object.values(ACCOUNT_TYPE)),
  checkType: PropTypes.oneOf(Object.keys(CHECK_TYPE)),
  nameOnAccount: PropTypes.string,
  createdAt: PropTypes.string,
  updatedAt: PropTypes.string,
})

export const Investor = PropTypes.shape({
  accredited: PropTypes.bool, // false
  annualIncome: PropTypes.oneOfType([PropTypes.number, PropTypes.string]), // 2000
  exemptFromWithholding: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]), // true
  faInvestorCreatedAt: PropTypes.string, // "2019-07-22T18:25:30+00:00"
  fullName: PropTypes.string, // "Eugene Syrota"
  investmentLimit: PropTypes.number, // 2000
  investmentsAllTime: PropTypes.oneOfType([PropTypes.number, PropTypes.string]), // 0
  investmentsThisYear: PropTypes.oneOfType([PropTypes.number, PropTypes.string]), // 200
  netWorth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]), // 4000
  otherPlatformInvestments: PropTypes.oneOfType([PropTypes.number, PropTypes.string]), // 0
  uuid: PropTypes.string, // "8881f239-0b61-4413-8f19-84dc60884a3c"
})

export const Golfer = PropTypes.shape({
  uuid: PropTypes.string.isRequired,
  createdAt: PropTypes.string,
  updatedAt: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  gender: PropTypes.oneOf(['male', 'female', 'Male', 'Female']),
  birthday: PropTypes.string,
  level: PropTypes.string,
  numberOfWins: PropTypes.number,
  region: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,

  /* Offer closing time */
  closedAt: PropTypes.string,
  /* Offer max goal */
  maxAmount: PropTypes.number,
  /* Offer min goal */
  minAmount: PropTypes.number,
  /* Total amount of invested funds */
  totalInvestmentAmount: PropTypes.number,
  /* Total number of applied investors */
  investorNumber: PropTypes.number,
  /* Investment min amount */
  minInvestmentAmount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /* Investment max amount */
  maxInvestmentAmount: PropTypes.number,
})

export const SavedOffering = PropTypes.exact({
  createdAt: PropTypes.string.isRequired,
  uuidGolfer: PropTypes.string.isRequired,
})

export const PaymentMethod = PropTypes.oneOf(Object.keys(PAYMENT_METHOD_TYPE))

export const Notification = PropTypes.shape({
  body: Golfer,
  updatedAt: PropTypes.string.isRequired,
  isRead: PropTypes.bool,
  title: PropTypes.string,
  type: PropTypes.string,
  uuid: PropTypes.string.isRequired,
})

export const Investment = PropTypes.shape({
  uuid: PropTypes.string.isRequired,
  amount: PropTypes.string,
  offeringUuid: PropTypes.string.isRequired,
  updatedAt: PropTypes.string,
  status: PropTypes.oneOf(['draft', 'not_received', 'cancelled']),
  paymentMethod: PropTypes.oneOf(Object.keys(PAYMENT_METHOD_TYPE)),
})

export const SubscriptionAgreement = PropTypes.shape({
  uuid: PropTypes.string.isRequired,
  url: PropTypes.string,
})

export const ContentBody = PropTypes.exact({
  blocks: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.oneOf(['paragraph', 'header', 'list', 'table', 'hyperlink']),
      data: PropTypes.any,
      key: PropTypes.string,
    }),
  ),
  time: PropTypes.number,
  version: PropTypes.string,
})

export const Content = PropTypes.shape({
  key: PropTypes.string.isRequired,
  body: ContentBody,
})

export const Messages = PropTypes.arrayOf(
  PropTypes.shape({
    body: PropTypes.string.isRequired,
    updatedAt: PropTypes.string,
    sender: PropTypes.objectOf(PropTypes.string),
  }),
)

export const Document = PropTypes.shape({
  documentUrl: PropTypes.string,
  owner: PropTypes.string,
  uuid: PropTypes.string.isRequired,
  createdAt: PropTypes.string,
  updatedAt: PropTypes.string,
})

export const SUPER_ADMIN = 'ROLE_SUPER_ADMIN'
export const ADMIN = 'ROLE_ADMIN'
export const USER = 'ROLE_USER'
export const INVESTOR = 'ROLE_INVESTOR'
export const ISSUER = 'ROLE_ISSUER'
export const ROLES = {
  [ISSUER]: 'Issuer',
  [INVESTOR]: 'Investor',
  [USER]: 'User',
  [ADMIN]: 'Admin',
  [SUPER_ADMIN]: 'Super Admin',
}
export const UserRole = PropTypes.oneOf(Object.keys(ROLES))

export const ISSUER_PENDING_RESERVED = 'Pending-Reserved'
export const ISSUER_ACCEPTED_RESERVED = 'Accepted-Reserved'
export const ISSUER_REJECTED_RESERVED = 'Rejected-Reserved'
export const ISSUER_PENDING_REVIEW = 'Pending-Review'
export const ISSUER_ACCEPTED_REVIEW = 'Accepted-Review'
export const ISSUER_REJECTED_REVIEW = 'Rejected-Review'
export const ATHLETE_STATUS_ISSUER = 'Issuer'
export const ATHLETE_STATUS_SUCCESSFUL_OFFERING = 'Successful Offering'
export const ATHLETE_STATUS_UNSUCCESSFUL_OFFERING = 'Unsuccessful Offering'
