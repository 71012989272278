import React from 'react'
import T from 'prop-types'
import { Valid, Invalid } from 'ui/form'
import { ValidationMessage } from 'ui/validation-message'
import { Input } from 'ui/form/formatted-input'
import { Field } from './Field'

const validation = [
  {
    test: password => !!password && password.length >= 8 && password.length <= 24,
    message: 'Password must be 8-24 characters',
  },
  {
    test: password => !!password && password.match(/.*[A-Z].*/),
    message: 'Must contain at least 1 upper case',
  },
  {
    test: password => !!password && password.match(/.*\d.*/),
    message: 'Must contain at least 1 number',
  },
  {
    test: password => !!password && password.match(/.*[#?!@$%^&*-].*/),
    message: 'Must contain at least 1 of special characters: #?!@$%^&*-',
  },
]

const validatePassword = password => {
  for (const { test, message } of validation) {
    if (!test(password)) {
      return message
    }
  }
  return ''
}

export class PasswordField extends Field {
  static propTypes = {
    defaultValue: T.string,
    onBlur: T.func,
    onChange: T.func,
  }

  state = {
    password: this.props.defaultValue || null,
    showErrors: false,
  }

  toggleErrorsVisibility = event => {
    this.setState({ showErrors: true }, () => {
      this.props.onBlur && this.props.onBlur(event)
    })
  }

  onPasswordChange = event => {
    this.setState({ password: event.target.value }, () => {
      this.props.onChange && this.props.onChange(event)
    })
  }

  renderRequirements() {
    const { showErrors, password } = this.state
    return this.props.noMessage ? null : (
      <div className="validation password-requirements">
        <div className="password-requirements-title">PASSWORD REQUIREMENTS</div>
        <div className="password-requirements-list">
          {validation.map(({ test, message }) => {
            const valid = !!test(password)
            const error = showErrors ? !valid : false
            return (
              <ValidationMessage key={message} success={valid} error={error}>
                {message}
              </ValidationMessage>
            )
          })}
        </div>
      </div>
    )
  }
  renderField() {
    const { noMessage = false, ...props } = this.props
    return (
      <>
        <Field
          {...props}
          onChange={this.onPasswordChange}
          onBlur={this.toggleErrorsVisibility}
          validation={validatePassword}
          options={{ numeral: false }}
          placeholder="Password"
        />
        <Valid>
          <div className="validation">
            <ValidationMessage success>All password requirements met</ValidationMessage>
          </div>
        </Valid>
        <Invalid>{this.renderRequirements()}</Invalid>
      </>
    )
  }
}
