import React from 'react'
import { Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { user } from 'store/user'
import { UserAccount, SUPER_ADMIN, ADMIN, USER, UserRole, RoutePath } from 'const/types'

const SignedInComponent = ({ account, children, userRole, redirectTo }) => {
  if (account && !userRole) return children

  if (account && userRole) {
    const currentRoles = new Set(account.roles)
    if (currentRoles.has(SUPER_ADMIN)) {
      currentRoles.add(ADMIN)
    }
    if (currentRoles.has(ADMIN)) {
      currentRoles.add(USER)
    }
    const requiredRoles = new Set(Array.isArray(userRole) ? userRole : [userRole])

    if (Array.from(currentRoles).some(role => requiredRoles.has(role))) {
      return children
    }
  }
  return redirectTo ? <Redirect to={redirectTo} /> : false
}
SignedInComponent.propTypes = {
  account: UserAccount,
  redirectTo: RoutePath,
  userRole: PropTypes.oneOfType([UserRole, PropTypes.arrayOf(UserRole)]),
}

const SignedOutComponent = ({ account, children }) => (account ? false : children)
SignedOutComponent.propTypes = { account: UserAccount }

export const SignedIn = connect(
  createStructuredSelector({
    account: user.account,
  }),
)(SignedInComponent)

export const SignedOut = connect(
  createStructuredSelector({
    account: user.account,
  }),
)(SignedOutComponent)
