import { createSelector } from 'reselect'

/**
 * @param {State} state
 * @returns {Banks}
 */
export const rawBanks = state => state.banks

/**
 * @function
 * @param {State} state
 * @returns {Object.<BankAccount>}
 */
export const accountsMap = createSelector(
  rawBanks,
  /** @param {Banks} banks */
  ({ byRoutingNumber }) => byRoutingNumber,
)

/** @typedef {import('.').BankAccount} BankAccount */
/** @typedef {import('.').Banks} Banks */
/** @typedef {import('.').State} State */
