import { createSelector } from 'reselect'

/** @type {function(State):PasswordRecovery} */
const rawAuth = state => {
  return state.passwordRecovery
}

/** @type {function(State):Object.<String>} */
export const recoveryMail = createSelector(
  rawAuth,
  /** @param {PasswordRecovery} passwordRecovery */
  passwordRecovery => passwordRecovery.recoveryMail,
)
