import './styles.css'
import React from 'react'

import { routes } from 'const/routes'

import { Link } from 'ui/link'

const process = [
  {
    id: 1,
    title: '1. Fill out our simple ISA application form after you`ve been accepted to our program',
    text: '',
  },
  {
    id: 2,
    title: '2. You`ll find out if you`re qualified for our ISA financing in 1-2 weeks',
    text: '',
  },
  {
    id: 3,
    title: '3. If approved, return to this site using the link provided in the email we send you to complete our ISA financing forms',
    text: '',
  },
  {
    id: 4,
    title: 'That`s it! Get started below if you`ve already been accepted to our program',
    text: '',
  },
]

export const WhyWork = () => {
  return (
    <div className="container static-page why-work">
      <h2 className="text-center mb-40">Use our ISA to learn new skills with us now and pay only when you`re working</h2>
      <div className="row">
        <div className="col-12 col-lg-6 mb-30">
          <div className="video-wrapper">
            <iframe
              width="100%"
              height="349"
              title="How Income Share Agreements (ISA's) work"
              src="https://www.youtube.com/embed/4IsG-DBC3x8"
              frameBorder="0"
              allowFullScreen
            />
          </div>
        </div>
        <div className="col-12 col-lg-6 mb-30">
          <div className="benefits">
            <h6 className="mb-30">Benefits of our Income Share Agreement</h6>
            <div className="benefits-item">
              <div className="icon-trophy" />
              <p>Learn new skills at our industry-leading program and only start paying once you`ve found a job with a salary above $45,000</p>
            </div>
            <div className="benefits-item">
              <div className="icon-trophy" />
              <p>Once your income is above $45,000, your payments will always remain budgetable and affordable at a fixed 10% of your income</p>
            </div>
            <div className="benefits-item">
              <div className="icon-trophy" />
              <p>Our industry leading terms: payments at 10% of income; only pay if making above $45,000; payments end when you`ve reached 48 monthly payments, you`ve paid back 1.5X your $17,500 tuition, or at the end of 7 years</p>
            </div>
          </div>
        </div>
      </div>
      <hr className="mb-60" />
      <h2 className="text-center mb-40">Success stories</h2>
      <div className="row success-list">
        <div className="col-12 col-md-6 success-item">
          <Link to="">
            <img src="/assets/img/mock_data/student-3-1.jpeg" alt="" srcSet="" className="img-responsive" />
          </Link>
          <p className="success-item-desc">
            “The ISA allowed me to learn new skills at a program I couldn`t have attended otherwise. I`m now making twice what I made before using the skills I learned in this program!”&nbsp;
            <Link to="" className="blue">
              <small></small>
            </Link>
          </p>
        </div>
        <div className="col-12 col-md-6 success-item">
          <Link to="">
            <img src="/assets/img/mock_data/student-6-1.jpeg" alt="" srcSet="" className="img-responsive" />
          </Link>
          <p className="success-item-desc">
            “Financing my education using the ISA was the best investment I ever made. The payments are always an affordable part of my new income as a data scientist.”&nbsp;
            <Link to="" className="blue">
              <small></small>
            </Link>
          </p>
        </div>
      </div>
      <hr className="mb-60" />
      <h2 className="text-center mb-20">Our Simple 3-Step Process</h2>
      <div className="row process mb-40">
        <div className="col-12 offset-md-2 col-md-8">
          {process.map(item => (
            <div className="process-item" key={item.id}>
              <h5 className="bold">{item.title}</h5>
              <p>{item.text}</p>
            </div>
          ))}
        </div>
      </div>

      <div className="w-300">
        <Link to={routes.issuerLogin} className="btn btn-primary w-100">
          Get started now
        </Link>
      </div>
    </div>
  )
}
