import './form.css'
import React from 'react'
import T from 'prop-types'
import { connect } from 'react-redux'

import { Provider } from './context'
import { showFailureAlert } from 'store/alerts'
import { ValidationMessage } from 'ui/validation-message'

export class FormComponent extends React.PureComponent {
  static propTypes = {
    dispatch: T.func,
    onChange: T.func,
    onError: T.func,
    onSubmit: T.func.isRequired,
    pending: T.bool,
    redirect: T.func,
    validation: T.func,
  }

  static defaultProps = {}

  element = React.createRef()

  fieldRefs = {}
  replaceState = value => {
    this.setState(value)
  }

  /** @private */
  setFieldStatus = ({
    value = null,
    valid = false,
    name,
    enable = true,
    defaultValue = null,
    ref,
  } = {}) => {
    if (!name) throw 'field name is undefined'

    if (ref) {
      this.fieldRefs[name] = ref
    }

    this.setState(
      state => {
        const { [name]: prevValidity, ...validity } = state.validity
        const { [name]: prevValue, ...values } = state.values
        const { [name]: prevDefaultValue, ...defaultValues } = state.defaultValues

        if (
          prevValidity === valid &&
          prevValue === value &&
          state.values.hasOwnProperty(name) === enable &&
          prevDefaultValue === defaultValue
        ) {
          // nothing has changed
          return null
        }
        if (enable) {
          values[name] = value
          validity[name] = valid
          defaultValues[name] = defaultValue
        }
        // console.log(
        //   name,
        //   '=',
        //   value,
        //   typeof value,
        //   valid ? 'ok' : 'invalid',
        //   'default:',
        //   defaultValue,
        // )
        const invalid = !this.isValid(values, validity)
        return { invalid, values, validity, defaultValues }
      },
      () => {
        this.props.onChange && this.props.onChange(this.state.values)
      },
    )
  }

  state = {
    invalid: false,
    pending: false,
    values: {},
    validity: {},
    defaultValues: {},
    error: null,
    errors: {},
    // eslint-disable-next-line react/no-unused-state
    statusListener: this.setFieldStatus,
  }

  /**
   * @param {Event} event
   */
  onSubmit = async event => {
    event.preventDefault()
    if (!this.validate()) return

    try {
      await this.setStateAsync({ pending: true })
      await this.props.onSubmit(this.state.values)
      await this.setStateAsync({ pending: false })
      this.props.redirect && this.props.redirect()
    } catch ({ error, errors }) {
      this.setState({ error, errors, pending: false }, () => {
        const el = this.element.current
        el &&
          window.scrollTo({
            top: el.scrollTop,
            behavior: 'smooth',
          })
      })
    }
  }

  handleReset = () => {
    // console.log('RESET!')
    Object.values(this.fieldRefs).forEach(element => {
      // console.log('reset', element)
      element && element.reset && element.reset()
    })
  }

  getCustomValidationError(values = this.state.values) {
    const { validation } = this.props
    return validation ? validation(values) : null
  }

  /**
   * @returns {boolean}
   */
  isValid(values = this.state.values, validity = this.state.validity) {
    if (!Object.values(validity).every(valid => !!valid)) return false
    return !this.getCustomValidationError(values)
  }

  /**
   * @returns {boolean}
   */
  validate() {
    for (const valid of Object.values(this.state.validity)) {
      if (!valid) return false
    }
    const error = this.getCustomValidationError()
    if (error) {
      this.setState({ error })
      return false
    }
    return true
  }

  /** @public */
  setValues(values = {}) {
    Object.entries(values).map(([name, value]) => this.setFieldStatus({ value, name }))
  }

  /** @public */
  getValues() {
    return this.state.values
  }
  setStateAsync(state) {
    return new Promise(resolve => this.setState(state, resolve))
  }

  isModified() {
    const { defaultValues, values } = this.state
    const defaultValuesEntries = Object.entries(defaultValues)

    if (defaultValuesEntries.length !== Object.keys(values).length) {
      // console.log('different length', Object.keys(defaultValues), Object.keys(values))
      return true
    }

    for (const [name, defaultValue] of defaultValuesEntries) {
      if (values[name] !== defaultValue) {
        // console.log(name, 'modified', defaultValue, values[name])
        return true
      }
    }

    return false
  }

  render() {
    const {
      onSubmit,
      children,
      pending,
      onChange,
      redirect,
      dispatch,
      validation,
      ...props
    } = this.props
    const { error } = this.state
    return (
      <form {...props} onSubmit={this.onSubmit} ref={this.element} onReset={this.handleReset}>
        {error && <ValidationMessage error>{error}</ValidationMessage>}
        <Provider value={this.state}>{children}</Provider>
      </form>
    )
  }
}

export const Form = connect(
  null,
  { onError: showFailureAlert },
  null,
  { forwardRef: true },
)(FormComponent)
