import React, { useEffect, useState } from 'react'

import ArrowIcon from 'icons/arrow-down-bold.svg'
import { cn } from 'utils'

import './styles.css'

export const Accordion = ({ id, title, children, isOpen, className, activeStyles, onClick }) => {
  const [openStatus, setOpenStatus] = useState(false)

  const toggleOpenStatus = () => {
    setOpenStatus(prevStatus => !prevStatus)
    onClick(id)
  }

  useEffect(() => {
    if (isOpen !== undefined) {
      setOpenStatus(isOpen)
    }
  }, [isOpen])

  return (
    <div className={cn('wrapper', !openStatus && 'collapsed', className)}>
      <div onClick={toggleOpenStatus} className={cn('titleContainer', openStatus && activeStyles)}>
        <h4 className="title">{title}</h4>
        <ArrowIcon className="icon" />
      </div>
      <div className="accordion-content">{children}</div>
    </div>
  )
}
