import { compoundActionType, compoundErrorActionCreator } from '../utils'
import * as qa from './selectors'

export { qa }

export const QA = {
  GET_QUESTIONS_LIST: compoundActionType('qa.get-questions-list'),
  ASK_QUESTION: compoundActionType('qa.ask-question'),
  ANSWER_QUESTION: compoundActionType('qa.answer-question'),
}

export const getQuestionsList = compoundErrorActionCreator(QA.GET_QUESTIONS_LIST)
export const askQuestion = compoundErrorActionCreator(QA.ASK_QUESTION)
export const answerQuestion = compoundErrorActionCreator(QA.ANSWER_QUESTION)

export const initialState = {
  byOfferingId: {},
}

export default function(state = initialState, { type, payload }) {
  switch (type) {
    case QA.GET_QUESTIONS_LIST.START:
    case QA.ASK_QUESTION.START:
    case QA.ANSWER_QUESTION.START:
      return {
        ...state,
        pending: true,
        error: null,
      }
    case QA.GET_QUESTIONS_LIST.SUCCESS:
      return {
        ...state,
        byOfferingId: { ...state.byOfferingId, ...payload },
      }
    case QA.ASK_QUESTION.SUCCESS:
      return {
        ...state,
        byOfferingId: {
          ...state.byOfferingId,
          [payload.id]: [...state.byOfferingId[payload.id], payload.result],
        },
        pending: false,
      }
    case QA.ANSWER_QUESTION.SUCCESS:
      return {
        ...state,
        byOfferingId: {
          ...state.byOfferingId,
          [payload.id]: state.byOfferingId[payload.id].map(message =>
            message.uuid === payload.result.uuid ? payload.result : message,
          ),
        },
        pending: false,
      }
    case QA.GET_QUESTIONS_LIST.FAILURE:
    case QA.ASK_QUESTION.FAILURE:
    case QA.ANSWER_QUESTION.FAILURE:
      return {
        ...state,
        pending: false,
        error: payload.error,
      }

    default:
      return state
  }
}
