import React from 'react'
import PropTypes from 'prop-types'
import { Dialog } from 'ui/dialog'
import { Form, SubmitButton } from 'ui/form'
import { Field } from 'ui/field'
import { Button } from 'ui/button'
import { Golfer } from 'const/types'
import { SignedIn } from 'components/signed'
import { GolferInfo } from 'components/golfer-info'

export const AskQuestionDialog = ({ onClose, offering, onSubmit }) => (
  <>
    <SignedIn>
      <Dialog onClose={onClose} title="Ask a Question">
        <Form onSubmit={onSubmit}>
          <GolferInfo golfer={offering} />
          <Field required name="question" label="Start typing..." className="mb-30" />
          <div className="question-footer">
            <div />
            <div className="button-wrapper">
              <Button className="btn" type="reset" onClick={onClose}>
                Cancel
              </Button>
              <SubmitButton>Post</SubmitButton>
            </div>
          </div>
        </Form>
      </Dialog>
    </SignedIn>
  </>
)

AskQuestionDialog.propTypes = {
  offering: Golfer.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
}
