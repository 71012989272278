import React from 'react'
import './styles.css'
import { getAge } from 'utils'
import { Golfer } from 'const/types'

export const GolferInfo = ({ golfer: { studentImage, name, state, birthday, gender } = {} }) => (
  <div className="offer-question">
    <div className="offer-question-img">
      <img src={studentImage} alt="" srcSet="" className="img-responsive" />
    </div>
    <div className="offer-question-info">
      <span className="golfer-name">{name}</span>
      <div className="offer-question-data clearfix">
        {/* <span className="offer-question-male">
          {gender && gender.charAt(0).toUpperCase()} {getAge(birthday)}
        </span> */}
        <span className="offer-question-based">{state}</span>
        {/*<span className="offer-question-level">{level}</span>*/}
        {/*<span className="offer-question-wins">*/}
        {/*  {numberOfWins > 1 ? numberOfWins + ' wins' : numberOfWins + ' win'}*/}
        {/*</span>*/}
      </div>
    </div>
  </div>
)

GolferInfo.propTypes = {
  golfer: Golfer.isRequired,
}
