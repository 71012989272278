import { compoundErrorActionCreator, compoundActionType } from '../utils'
import * as selectors from './selectors'

export { selectors }

/** @type {Object.<String,String>|import('../utils').CompoundActionType} */
export const ACTIONS = {
  RECOVER_PASSWORD: compoundActionType('recoveryPassword.recover-password'),
}

/** @returns {Action} */
export const recoverPassword = compoundErrorActionCreator(ACTIONS.RECOVER_PASSWORD)

/**
 * @typedef {Object} States
 * @prop {Object.<string,Country>} byId
 * @prop {boolean} pending
 * @prop {string|null} error
 */

/** @type {States} */
export const initialState = {
  recoveryMail: '',
  pending: false,
  error: null,
}

/**
 * @param {States} state
 * @param {Action} param1
 * @returns {States}
 */
export default function(state = initialState, { type, payload }) {
  switch (type) {
    case ACTIONS.RECOVER_PASSWORD.START:
      return {
        ...state,
        pending: true,
        error: null,
      }
    case ACTIONS.RECOVER_PASSWORD.SUCCESS:
      return {
        ...state,
        pending: false,
        recoveryMail: payload,
      }
    case ACTIONS.RECOVER_PASSWORD.FAILURE:
      return {
        ...state,
        pending: false,
        error: payload.error,
      }
    default:
      return state
  }
}

/** @typedef {import('../.').Action} Action */
